import { createAction, props } from "@ngrx/store";
import { ListTemplate } from "src/app/_core/ListTemplate";

export const ListTemplateActions = {
  getAll: createAction("[GetReq ListTemplates] Get all list templates (start)"),

  getAllSuccess: createAction(
    "[GetRes ListTemplates] Get all list templates (success)",
    props<{ listTemplates: ListTemplate[] }>()
  ),
};
