import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomEntryField } from "src/app/_core/CustomEntryField";
import { Entry, TEntryStatus } from "src/app/_core/Entry";
import { EntryTemplate } from "src/app/_core/EntryTemplate";
import UserData from "src/app/_core/UserData";
import { isEmptyString } from "src/app/_helper/Helpers";
import { FileService } from "src/app/_services/file.service";
import { UserService } from "src/app/_services/user.service";
import { WSMethods } from "src/app/_services/web-socket.service";
import { EntriesMoveService } from "../services/entries-move.service";

@Component({
  selector: "entry-edit-create",
  templateUrl: "./entry-edit-create.component.html",
  styleUrls: ["./entry-edit-create.component.scss"],
})
export class EntryEditCreateComponent implements OnInit {
  @Input() entry: Entry;
  @Input() responsibles: UserData[];
  @Input() defaultEntryTemplate: EntryTemplate;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() isDownloading: boolean = false;
  @Input() isSelectedForMoving: boolean;
  @Input() isMoveable: boolean;
  @Input() isDeleteable: boolean = true;
  @Input() entryTemplates: EntryTemplate[];
  @Input() hideControls: boolean = false;
  @Input() isSingleEditMode: boolean = false;

  @Output() onChange: EventEmitter<Entry> = new EventEmitter<Entry>();
  @Output() onSave: EventEmitter<Entry> = new EventEmitter<Entry>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() onMoveUp = new EventEmitter<Entry>();
  @Output() onMoveDown = new EventEmitter<Entry>();
  @Output() onFileAction = new EventEmitter<{
    formData: FormData;
    action: WSMethods;
    callbackFn: (arg0: any) => void;
  }>();

  @ViewChild("rootElement", { static: true }) rootElement: ElementRef;

  fileStates: {} = {};
  TEntryStatusFile = TEntryStatus;
  TEntryStatus = TEntryStatus;
  statusDefinitions: any[];
  startDate: Date;
  endDate: Date;
  isFileUploadVisible: boolean = false;
  username: string;

  isAnimMove = false;

  stringify = JSON.stringify;
  log = console.log;

  constructor(
    private translator: TranslateService,
    public moveEntries: EntriesMoveService,
    public fileService: FileService,
    private userService: UserService
  ) {
    this.statusDefinitions = [
      {
        bgColor: "var(--color-open)",
        fgColor: "var(--color-text-open)",
        text: this.translator.instant("STATES.OPENED"),
        name: TEntryStatus.opened,
      },
      {
        bgColor: "var(--color-closed)",
        fgColor: "var(--color-text-closed)",
        text: this.translator.instant("STATES.CLOSED"),
        name: TEntryStatus.closed,
      },
      {
        bgColor: "var(--color-not-needed)",
        fgColor: "var(--color-text-not-needed)",
        text: this.translator.instant("STATES.NOT_NEEDED"),
        name: TEntryStatus.not_needed,
      },
      {
        bgColor: "var(--color-accepted)",
        fgColor: "var(--color-text-accepted)",
        text: this.translator.instant("STATES.FOR_DOWNLOAD"),
        name: TEntryStatus.for_download,
      },
      {
        bgColor: "var(--color-feedback)",
        fgColor: "var(--color-text-feedback)",
        text: this.translator.instant("STATES.FEEDBACK"),
        name: TEntryStatus.feedback,
      },
      {
        bgColor: "var(--color-accepted)",
        fgColor: "var(--color-text-accepted)",
        text: this.translator.instant("STATES.ACCEPTED"),
        name: TEntryStatus.accepted,
      },
    ];
  }

  ngOnInit() {
    this.copyDates();
    this.setupEntryTemplates();
  }

  endDateChange(newValue: Date) {
    if (newValue == undefined || newValue === null)
      this.entry.endDate = undefined;
    else this.entry.endDate = new Date(newValue);

    this.onChange.emit(this.entry);
  }

  startDateChange(newValue: Date) {
    if (newValue == undefined || newValue === null)
      this.entry.startDate = undefined;
    else this.entry.startDate = new Date(newValue);

    this.onChange.emit(this.entry);
  }

  responsibleChanged(
    responsible: string | { id: string; displayName: string },
    state: boolean
  ) {
    if (this.entry.responsibles == undefined) this.entry.responsibles = [];

    if (state) {
      this.entry.responsibles.push(responsible);
    } else {
      this.entry.responsibles = this.entry.responsibles.filter(
        (v) => v != responsible
      );
    }

    this.onChange.emit(this.entry);
  }

  isResponsible(checkResponsible: string): boolean {
    return (
      this.entry.responsibles != undefined &&
      this.entry.responsibles.find((v) => v == checkResponsible) != undefined
    );
  }

  customFieldChanged(customField: CustomEntryField, fieldPos: number) {
    this.entry.fields[fieldPos] = customField;
    this.onChange.emit(this.entry);
  }

  commentChanged(text: string) {
    this.entry.comment = text;
    this.onChange.emit(this.entry);
  }

  setupEntryTemplates() {
    if (isEmptyString(this.entry.type)) this.entry.type = "PbC Standard";

    const newEntryTemplate = this.entryTemplates.find(
      (e) => e.type == this.entry.type
    );

    if (newEntryTemplate !== undefined)
      this.defaultEntryTemplate = newEntryTemplate;
  }

  templateTypeChanged(type: string) {
    this.entry = this.entry.applyTemplate(
      this.entryTemplates.find((e) => e.type === type)
    );

    this.onChange.emit(this.entry);
  }

  copyDates() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate = this.entry.startDate;
    this.endDate = this.entry.endDate;
  }

  moveSelection(isSelected: boolean) {
    if (isSelected) this.moveEntries.select(this.entry);
    else this.moveEntries.deselect(this.entry);
  }

  fileAction(formData: FormData, action: String, callbackFn: (any) => void) {
    formData.append("entry_id", this.entry.internalID);
    if (action == WSMethods.POST) {
      this.onFileAction.emit({
        formData: formData,
        action: WSMethods.POST,
        callbackFn: callbackFn,
      });
    } else if (action == WSMethods.DELETE) {
      this.onFileAction.emit({
        formData: formData,
        action: WSMethods.DELETE,
        callbackFn: callbackFn,
      });
    } else if (action == WSMethods.GET) {
      this.onFileAction.emit({
        formData: formData,
        action: WSMethods.GET,
        callbackFn: callbackFn,
      });
    }
  }

  animate(className: string, delayedCallback: () => void = () => {}, delay: number = 0) {
    this.rootElement.nativeElement.classList.toggle(className);
    setTimeout(() => this.rootElement.nativeElement.classList.toggle(className), 1000);
    setTimeout(() => delayedCallback(), delay);
  }

  animateDelete() {
    this.animate('animate__fadeOut', () => this.onDelete.emit(), 2000);
  }
}
