import { EventEmitter, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/_services/user.service";
import {
  WebSocketService,
  WSMethods,
} from "src/app/_services/web-socket.service";

@Injectable({
  providedIn: "root",
})
export class NotifierService {
  messages: NotifierMessage[] = [];
  messageInfo: { [index: string]: string } = {};
  socketMessage = true;

  constructor(
    private socket: WebSocketService,
    private userService: UserService,
    private translator: TranslateService
  ) {
    this.registerMesageGenerator();
    //this.testMessages();
  }

  registerMesageGenerator() {
    // outgoing messages
    this.socket.sent((e: any) => {
      if (this.socketMessage) {
        if (e.__method != WSMethods.GET)
          this.info(
            this.makeId(e),
            this.translator.instant("G.SAVING"),
            NotifierIcons.PROCESS
          );
      }
    });

    // icoming messages
    this.socket.message((e) => {
      if (this.socketMessage) {
        const { info, __sender__ } = JSON.parse(e.data);
        if (__sender__ == this.userService.getUserName()) {
          this.remove(this.makeId(info));

          if (
            info.__status != 200 &&
            info.__status != 401 &&
            info.__method != WSMethods.GET
          )
            this.error(
              this.makeId(info),
              this.translator.instant("G.NOT_SAVED"),
              NotifierIcons.EXCALAMATION,
              3000
            );
          else if (info.__status == 401)
            this.error(
              this.makeId(info),
              this.translator.instant("G.NOT_AUTHORIZED"),
              NotifierIcons.EXCALAMATION,
              3000
            );
          else if (info.__method != WSMethods.GET)
            this.success(
              this.makeId(info),
              this.translator.instant("G.SAVED"),
              NotifierIcons.OK,
              3000
            );
        }
      }
    });
  }

  makeId({ __method, __type }): string {
    return __method + "-" + __type;
  }

  info(
    id: string,
    message: string,
    iconType: NotifierIcons,
    milliseconds?: number
  ) {
    this.add(id, message, iconType, NotifierType.INFO, milliseconds);
  }

  success(
    id: string,
    message: string,
    iconType: NotifierIcons,
    milliseconds?: number
  ) {
    this.add(id, message, iconType, NotifierType.SUCCESS, milliseconds);
  }

  error(
    id: string,
    message: string,
    iconType: NotifierIcons,
    milliseconds?: number
  ) {
    this.add(id, message, iconType, NotifierType.ERROR, milliseconds);
  }

  warn(
    id: string,
    message: string,
    iconType: NotifierIcons,
    milliseconds?: number
  ) {
    this.add(id, message, iconType, NotifierType.WARN, milliseconds);
  }

  add(
    id: string,
    message: string,
    iconType: NotifierIcons,
    type: NotifierType,
    milliseconds: number
  ) {
    if (milliseconds) {
      setTimeout(() => this.remove(id), milliseconds);
    }

    if (
      (type === NotifierType.INFO &&
        this.messages.find((v) => v.type === NotifierType.INFO) == undefined) ||
      type != NotifierType.INFO
    )
      this.messages.push(new NotifierMessage(id, message, iconType, type));

    this.messageInfo[id] = message;
  }

  testMessages() {
    this.info("", "Information", NotifierIcons.PROCESS);
    this.success("", "Success", NotifierIcons.OK);
    this.error("", "Error", NotifierIcons.EXCALAMATION);
    this.warn("", "Warn", NotifierIcons.QUESTIONMARK);
  }

  remove(id: string) {
    const removeIdx = this.messages.findIndex((v) => v.id == id);
    this.messages.splice(removeIdx, 1);
    delete this.messageInfo[id];
  }

  removeAll() {
    let idList = this.messages.map((m) => m.id);
    idList.forEach((id) => this.remove(id));
  }
}

export class NotifierMessage {
  constructor(
    public id: string,
    public text: string,
    public icon: NotifierIcons,
    public type: NotifierType
  ) {}
}

export enum NotifierIcons {
  PROCESS = "autorenew",
  OK = "check_circle_outline",
  EXCALAMATION = "error_outline",
  QUESTIONMARK = "help_outline",
}

export enum NotifierType {
  INFO,
  WARN,
  ERROR,
  SUCCESS,
}
