import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cube-loading-animation',
  templateUrl: './cube-loading-animation.component.html',
  styleUrls: ['./cube-loading-animation.component.scss']
})
export class CubeLoadingAnimationComponent implements OnInit {
  @Input() message: string = undefined;
  constructor() { }

  ngOnInit() {
  }

}
