import { HostListener, Injectable } from "@angular/core";
import { ModalDialog } from "./modal-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  activeDialog: ModalDialog;

  constructor(){}

  open(dialog: ModalDialog) {
    if(this.activeDialog !== undefined) this.activeDialog.hide();
    this.activeDialog = dialog;
  }
}
