import { stripSummaryForJitNameSuffix } from '@angular/compiler/src/aot/util';
import { Injectable, OnInit } from '@angular/core';
import { resolve } from 'url';
import { TStatus, UserService } from './user.service';
import { WSMethods } from './web-socket.service';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthorizationService {
  private authorizations: Array<any> = [];
  private memoized:any = {};

  constructor(private userService:UserService) {
    this.userService.onStatusChange.subscribe(status => {
      if(status === TStatus.Login) this.init();
      else {
        this.memoized = {};
        this.authorizations = [];
      }
    });
  }

  async init(): Promise<Array<any>> {
    let obsAuth = this.userService.getAuthorizations();
    if(obsAuth != undefined){
      this.authorizations = await obsAuth.toPromise();
      this.memoized = this.memoize(this.authorizations);
    }
    return new Promise(resolve => resolve(this.authorizations));
  }

  memoize(authorizations: Array<any>) : any {
    const pbcAuth = authorizations
    .filter(auth => auth.application === 'pbc');

    const memo = {};
    for(let auth of pbcAuth) {
      for(let method of auth.methods) {
        for(let value of auth.values) {
          memo[method+auth.object+value] = true;
        }
        memo[method+auth.object] = true;
      }
    }

    return memo;
  }

  canCreate(forObject:string, onValue:string):boolean{
    return this.checkIfCan(WSMethods.POST, forObject, onValue);
  }

  canUpdate(forObject:string, onValue:string):boolean {
    return this.checkIfCan(WSMethods.PUT, forObject, onValue);
  }

  canRead(forObject:string, onValue:string):boolean {
    return this.checkIfCan(WSMethods.GET, forObject, onValue);
  }

  canDelete(forObject:string, onValue:string): boolean {
    return this.checkIfCan(WSMethods.DELETE, forObject, onValue);
  }

  hasRight(forObject:string, onValue:string): boolean {
    return this.checkIfCanAny(forObject, onValue);
  }

  getValue(forObject: string) {
    return this.authorizations.find(right => right.object === forObject);
  }

  private checkIfCan(doAction: string, forObject:string, onValue?:string) {
    if(onValue){
      return this.getOrSetMemo(doAction.toLowerCase()+forObject+onValue, () => this.authorizations.find(
        el => {
          return el.application == "pbc" &&
          (el.methods.includes(doAction.toUpperCase()) || el.methods.includes("ALL")) &&
          (el.object == forObject || el.object == "*") &&
          (el.values.includes(onValue) || el.values.includes("*"))
        }) != undefined
      );
    }
    else {
      return this.getOrSetMemo(doAction.toLowerCase()+forObject, () => this.authorizations.find(
        el => {
          return el.application == "pbc" &&
          (el.methods.includes(doAction.toUpperCase()) || el.methods.includes("ALL")) &&
          (el.object == forObject || el.object == "*");
        }
      ) != undefined);
    }
  }

  private checkIfCanAny(forObject:string, onValue?:string) {
    if(onValue){
      return this.getOrSetMemo(forObject+onValue, () => this.authorizations.find(
        el => {
          return el.application == "pbc" &&
          (el.object == forObject || el.object == "*") &&
          (el.values.includes(onValue) || el.values.includes("*"))
        }) != undefined
      );
    }
    else {
      return this.getOrSetMemo(forObject, () => this.authorizations.find(
        el => {
          return el.application == "pbc" &&
          (el.object == forObject || el.object == "*");
        }
      ) != undefined);
    }
  }

  private getOrSetMemo<T extends () => boolean>(value:string, def: T){
    if(this.memoized[value] != undefined) return this.memoized[value];
    else {
      this.memoized[value] = def();
      return this.memoized[value];
    }
  }
}
