import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entry, TEntryStatus } from 'src/app/_core/Entry';
import { PBCList } from 'src/app/_core/PBCList';
import { IdxCollection } from 'src/app/_helper/Helpers';

@Component({
  selector: 'status-overview',
  templateUrl: './status-overview.component.html',
  styleUrls: ['./status-overview.component.scss']
})
export class StatusOverviewComponent implements OnInit {
  @Input() pbcList: PBCList;
  @Input() direction: 'horizontal' | 'vertical';
  @Output() legendClicked: EventEmitter<string> = new EventEmitter<string>();
  status: TProgressStatus[];
  overallStatus: TProgressStatus;
  exceededStatus: TProgressStatus;
  dueStatus: TProgressStatus;
  isStatusTextShown: {[idx: string] : boolean} = {};
  __filterByName: string = undefined;
  __statusChart: TProgressStatus[];;

  Math = Math;
  TEntryStatus = TEntryStatus;

  constructor() { }

  ngOnInit() {
    this.refresh();
  }

  @Input() set filterByName(filterByName: string) {
    this.__filterByName = filterByName;
    this.refresh();
  }

  get statusChart() : TProgressStatus[] {
    return this.__statusChart;
  }
  set statusChart(statusChart: TProgressStatus[]) {
    this.__statusChart = statusChart;
  }

  refresh(){
    [this.status, this.overallStatus, this.exceededStatus, this.dueStatus] = this.fillStatus();
    this.statusChart = this.fillStatusChart();
  }

  fillStatusChart(){
    const acceptedStates = [
      TEntryStatus.accepted.toString(),
      TEntryStatus.closed.toString(),
      TEntryStatus.opened.toString(),
      TEntryStatus.not_needed.toString(),
      TEntryStatus.feedback.toString()
    ];
    return this.status.filter(s => acceptedStates.includes(s.value));
  }

  fillStatus() : [TProgressStatus[], TProgressStatus, TProgressStatus, TProgressStatus] {
    const statusOrder = {"accepted": 0, "closed": 1, "open": 2, "feedback": 3, "not-needed": 4 };
    const filterByName = this.filterByName;
    const oStatus = this.pbcList.getStatus(this.__filterByName);
    const status: TProgressStatus[] = IdxCollection.toArray(oStatus, (name, obj)=>{
      return { text: name, count: obj[name].count, value: obj[name].value, order: statusOrder[obj[name].value] != undefined ? statusOrder[obj[name].value] : -1 };
    }).sort((a, b) => a.order - b.order);

    const overall = status
      .filter(el => el.value != TEntryStatus.exceeded && el.value != TEntryStatus.due)
      .reduce((prev, el) => prev + el.count, 0);

    const sum_exceeded = status
    .filter(el => el.value == TEntryStatus.exceeded)
    .reduce((prev, el) => prev + el.count, 0);

    const sum_due = status
    .filter(el => el.value == TEntryStatus.due)
    .reduce((prev, el) => prev + el.count, 0);

    const text_exceeded = Entry.statusText(TEntryStatus.exceeded.toString());
    const text_due = Entry.statusText(TEntryStatus.due.toString());
    const overallStatus = {
      text: "STATES.OVERALL",
      count: overall,
      value: "overall"
    };

    const exceededStatus = {
      text: text_exceeded,
      count: sum_exceeded,
      value: TEntryStatus.exceeded.toString()
    }

    const dueStatus = {
      text: text_due,
      count: sum_due,
      value: TEntryStatus.due.toString()
    };

    status.push(
      overallStatus,
      exceededStatus,
      dueStatus
    );

    return [status.map(st => {
      let count = 0;

      if(st.value == TEntryStatus.due || st.value == TEntryStatus.exceeded)
        count = 0;
      else
        count = st.count;

      return {...st, percentage:  (count / overall) * 100 }
    }).filter(st =>
      (st.text == text_exceeded && st.value == TEntryStatus.exceeded || st.value != TEntryStatus.exceeded) &&
      (st.text == text_due && st.value == TEntryStatus.due || st.value != TEntryStatus.due)
    ), overallStatus, exceededStatus, dueStatus];
  }

  chartHovered(label:string) {
    this.chartBlurred();
    this.isStatusTextShown[label] = true;
  }

  chartBlurred(){
    this.isStatusTextShown = IdxCollection.map(this.isStatusTextShown, (name, obj) => obj[name] = false);
  }
}

type TProgressStatus = {text: string, count: number, value: string, percentage?: number};
