import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { RightsEditComponent } from "./administration/rights-edit/rights-edit.component";
import { RightsOverviewComponent } from "./administration/rights-overview/rights-overview.component";
import { UserCreateComponent } from "./administration/user-create/user-create.component";
import { UserEditComponent } from "./administration/user-edit/user-edit.component";
import AppComponent from "./app.component";
import { AppRoutingModule } from "./app.routes";
import { CustomizationModule } from "./customizations/customizations.module";
import { ListEditCreateModule } from "./list-edit-create/list-edit-create.component";
import { CategoryShowComponent } from "./list-show/category-show/category-show.component";
import { EntryShowComponent } from "./list-show/entry-show/entry-show.component";
import { HeaderRowComponent } from './list-show/header-row/header-row.component';
import { ListShowComponent } from "./list-show/list-show.component";
import { TocEntryComponent } from "./list-show/toc-show/toc-entry.component";
import { TocShowComponent } from "./list-show/toc-show/toc-show.component";
import { ListsShowComponent } from "./lists-show/lists-show.component";
import { ChangePasswordComponent } from "./login-screen/change-password/change-password.component";
import { ForgotPasswordComponent } from "./login-screen/forgot-password/forgot-password.component";
import { LoginScreenComponent } from "./login-screen/login-screen.component";
import { OverviewComponent } from "./overview/overview.component";
import { SharedModule } from "./shared/Shared.module";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { OrderBy } from "./_pipes/OrderBy";
import { AuthorizationService } from "./_services/authorization.service";
import { PbcService } from "./_services/pbc.service";
import { WebSocketService } from "./_services/web-socket.service";
import { NgrxStoreModule } from "./_store/app.state";
import { BoxedTextComponent } from "./_uicomponents/boxed-text/boxed-text.component";
import { CommentaryComponent } from "./_uicomponents/commentary/commentary.component";
import { DonutChartComponent } from "./_uicomponents/donut-chart/donut-chart.component";
import { DndDirective } from "./_uicomponents/file-upload/drag-and-drop.directive";
import { HistoryLogComponent } from "./_uicomponents/history-log/history-log.component";
import { IconInputComponent } from "./_uicomponents/icon-input/icon-input.component";
import { ListFilterComponent } from "./_uicomponents/list-filter/list-filter.component";
import { StatusOverviewComponent } from "./_uicomponents/status-overview/status-overview.component";
import { GroupModule } from './administration/group/group.module';
import { GroupShowComponent } from './lists-show/group-show/group-show.component';
import { ModalDialog } from './_uicomponents/modal-dialog/modal-dialog.component';
import { RouterModule } from "@angular/router";
import { LanguageSwitcherService } from "./_uicomponents/language-switcher/language-switcher.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginScreenComponent,
    ListsShowComponent,
    OverviewComponent,
    ListShowComponent,
    EntryShowComponent,
    TocShowComponent,
    TocEntryComponent,
    CategoryShowComponent,
    CommentaryComponent,
    DndDirective,
    OrderBy,
    HistoryLogComponent,
    StatusOverviewComponent,
    UserSettingsComponent,
    DonutChartComponent,
    UserEditComponent,
    RightsEditComponent,
    UserCreateComponent,
    IconInputComponent,
    ForgotPasswordComponent,
    BoxedTextComponent,
    ChangePasswordComponent,
    RightsOverviewComponent,
    ListFilterComponent,
    HeaderRowComponent,
    GroupShowComponent,
    ModalDialog,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    RouterModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: "enabled",
      useHash: true,
      anchorScrolling: "enabled",
      scrollOffset: [0, 64],
      onSameUrlNavigation: "reload",
    }),
    ListEditCreateModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode,
    }),
    NgrxStoreModule,
    CustomizationModule,
    GroupModule
  ],
  providers: [
    WebSocketService,
    AuthorizationService,
    PbcService,
    {
      provide: LOCALE_ID,
      useFactory: () => {
        return LanguageSwitcherService.get().lang;
      },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
