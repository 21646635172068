import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  mailSendMessage: boolean = false;
  mailAddress: string = "";

  states = {
    mailSending: false,
    mailSent: false,
    mailNotFound: false,
    mailInvalid:  false
  };

  setStateTrue(stateName: string) {
    for(let state in this.states){
      this.states[state] = false;
    }

    this.states[stateName] = true;

  }


  constructor(public userService: UserService) { }

  ngOnInit() {
  }

  sendMail(){
    if(this.mailAddress === "" || this.mailAddress === undefined || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.mailAddress)) {
      this.setStateTrue("mailInvalid");
      return;
    }

    this.setStateTrue("mailSending");
    this.userService.forgotPassword(this.mailAddress).subscribe(s => this.setStateTrue("mailSent"), e => {
      if(e.status === 404)
        this.setStateTrue("mailNotFound");
    });
  }

  keydown(event) {
    if (event.key === "Enter") {
      this.sendMail();
    }
  }

}
