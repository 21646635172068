import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {
  @Output() filtered: EventEmitter<string[]> = new EventEmitter<string[]>();
  filterValue: string;
  selected: string[] = [];
  unselected: string[] = [];
  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

  clientClicked(client: string) {
    const lenSelcted = this.selected.length;
    this.selected = this.selected.filter(el => el != client);

    if(this.selected.length === lenSelcted) {
      this.selected.push(client);
      this.unselected = this.unselected.filter(el => el != client);
    }
    else {
      this.unselected.push(client);
    }

    this.filtered.emit(this.selected);
  }

  @Input() set init(v: string[]) {
    this.unselected = v;
    if(this.selected.length > 0) {
      this.selected = this.selected.filter(s => this.unselected.includes(s))
      this.unselected = this.unselected.filter(u => !this.selected.includes(u))
    }
  }

}
