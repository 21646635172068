import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { WSUserType } from "../_core/AuthObjects";
import { User } from "../_core/User";
import UserData from "../_core/UserData";
import { extractFieldFromJWT } from "../_helper/JwtHelper";
import { WebSocketService, WSMethods } from "./web-socket.service";
import { UserConfigService } from "./user-config.service";
import { generateAuthHeader } from "../_helper/HttpHelper";

const routeOnSuccessfulLogin = "/";
const routeOnFailedLogin = "/login";
/**
 * Implements functions in context with the logged-in user (else see user-config.service).
 */
@Injectable({
  providedIn: "root",
})
export class UserService implements CanActivate, CanActivateChild {
  static PH_TOKEN = "TOKEN";
  static PH_USERNAME = "username";

  isLoggedIn: boolean = false;
  user: User = new User("", "");
  redirectUrl: string = routeOnSuccessfulLogin;
  token: string = "";
  observableUser: Subject<User> = new Subject<User>();
  onStatusChange: Subject<TStatus> = new Subject<TStatus>();
  userData: UserData;

  basicHeaders: HttpHeaders;
  authorizations: any = [];

  static BASE_AUTH_URL =
    environment.protocol +
    "://" +
    environment.authHost +
    ":" +
    environment.authPort;
  static TOKEN_REFRESH_OFFSET = 3 * 60 * 1000; // 3 minutes before expiration (in millis)

  constructor(
    private http: HttpClient,
    private router: Router,
    private socket: WebSocketService,
    private userConfService: UserConfigService,
  ) {
    this.basicHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    this.tokenLogin();
  }

  ngOnInit() {}

  tokenLogin() {
    this.token = localStorage.getItem(UserService.PH_TOKEN);
    this.isLoggedIn = false;

    if (this.isTokenDefined()) {
      this.getAuthorizations();
      this.http
        .get(UserService.BASE_AUTH_URL + "/token/validate", {
          headers: generateAuthHeader(this.token),
        })
        .pipe(map(this.extractData))
        .subscribe(
          (success) => {
            this.loadUserData();
            this.emitUser();
            this.isLoggedIn = true;
            this.onStatusChange.next(TStatus.Login);
            setTimeout(
              () => this.refreshToken(),
              this.calculateRefreshTime(this.token)
            ); // TODO:  a more secure approach should be implemented
            this.router.navigate([
              this.redirectUrl !== undefined && this.router.url === this.redirectUrl
                ? this.redirectUrl
                : this.router.url,
            ]);
          },
          (error) => {
            localStorage.setItem(UserService.PH_TOKEN, undefined);
            // TODO: Hier sollte eine Fehlermeldung kommen o.ä -- this.notificationsService.error("Ungültiger Token", error);
            this.onStatusChange.next(TStatus.LoginFailed);
            this.router.navigate([routeOnFailedLogin]);
          }
        );
    }
  }

  register(user: User) {
    return this.http.post<User>(
      UserService.BASE_AUTH_URL + "/user/register/pbc",
      user,
      { headers: generateAuthHeader(this.token), observe: "response" }
    );
  }

  resolveResponsible() {
    if (
      (this.userData.data.surname == undefined ||
        this.userData.data.surname === "") &&
      (this.userData.data.forename == undefined ||
        this.userData.data.forename === "")
    )
      return this.getUserName();
    else return this.userData.data.forename + " " + this.userData.data.surname;
  }

  emitUser() {
    this.observableUser.next(this.user);
  }

  forgotPassword(mailAdress: string) {
    return this.http.post(
      UserService.BASE_AUTH_URL + "/forgot/password/pbc",
      mailAdress
    );
  }

  resetPassword(token: string, password: string) {
    return this.http.put<User>(
      UserService.BASE_AUTH_URL + "/reset/password",
      { password: password },
      { headers: generateAuthHeader(token) }
    );
  }

  getUserName(): string {
    return localStorage.getItem(UserService.PH_USERNAME);
  }

  setUserName(username: string) {
    localStorage.setItem(UserService.PH_USERNAME, username);
    this.user.name = username;
  }

  loadUserData() {
    this.userConfService.getUserData(this.getUserName(), this.token).subscribe((d) => {
      this.userData = d;
    });
  }

  getAuthorizations(): Observable<any> {
    if (this.isTokenDefined()) {
      let authObs = this.http
        .get(UserService.BASE_AUTH_URL + "/authorizations", {
          headers: generateAuthHeader(this.token),
        })
        .pipe(map(this.extractData));
      authObs.subscribe(
        (success) => {
          this.authorizations = success;
        },
        (error) => {
          this.authorizations = undefined;
        }
      );

      return authObs;
    }
    return undefined;
  }

  getUsersByClients(clients: string[]) {
    let object = WebSocketService.setup(
      clients,
      WSMethods.GET,
      WSUserType.USERS,
      this.token
    );
    this.socket.send(object);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    let url: string = state.url;
    let loginOk = this.checkLogin(url);
    if (loginOk) {
      if (
        this.userData == undefined ||
        this.userData.user_id == undefined ||
        this.userData.user_id == ""
      )
        this.loadUserData();
    }
    return of(loginOk);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }

  private extractData(res: Response) {
    let body = res;
    return body || [];
  }

  private extractToken(res: Response): any {
    let body: any = res;
    return body || "";
  }

  checkLogin(url: string): boolean {
    if (this.isLoggedIn || this.isTokenDefined()) return true;

    // Store the attempted URL for redirecting
    this.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(["/login"]);
    return false;
  }

  login(user: User, callback: (boolean, string) => void) {
    // name, password
    let body = JSON.stringify(user);

    this.http
      .post(UserService.BASE_AUTH_URL + "/authenticate/forApp/pbc", body, {
        headers: this.basicHeaders,
      })
      .pipe(map(this.extractToken))
      .subscribe(
        (success) => {
          if (success == "") {
            callback(false, this.redirectUrl);
            this.isLoggedIn = false;
          } else {
            this.user = user;
            this.loadUserData();
            this.emitUser();
            this.token = success.token;
            localStorage.setItem(UserService.PH_TOKEN, this.token);
            this.user.name = JSON.parse(atob(this.token.split(".")[1])).sub;
            localStorage.setItem(UserService.PH_USERNAME, this.user.name);
            this.getAuthorizations();
            this.isLoggedIn = true;
            setTimeout(
              () => this.refreshToken(),
              this.calculateRefreshTime(this.token)
            ); // TODO:  a more secure approach should be implemented
            this.onStatusChange.next(TStatus.Login);
            callback(true, this.redirectUrl);
          }
        },
        (error) => {
          callback(false, this.redirectUrl);
          this.logout();
        }
      );
  }

  logout() {
    this.user = new User("", "");
    this.emitUser();
    this.authorizations = [];
    this.isLoggedIn = false;
    localStorage.setItem(UserService.PH_TOKEN, undefined);
    this.onStatusChange.next(TStatus.Logout);
    this.router.navigate([routeOnFailedLogin]);
  }

  async autoRefreshWithToken(newtoken: string, redirectPath: string = "/") {
    const name = extractFieldFromJWT<string>(newtoken, "sub");
    localStorage.setItem(UserService.PH_TOKEN, newtoken);
    localStorage.setItem(UserService.PH_USERNAME, name);
    await this.router.navigate([redirectPath]);
    location.reload();
  }

  private isTokenDefined() {
    return (
      this.token !== undefined &&
      this.token !== "" &&
      this.token !== null &&
      this.token !== "undefined"
    );
  }

  private refreshToken() {
    this.http
      .get<TLoginSuccess>(UserService.BASE_AUTH_URL + "/token/new", {
        headers: generateAuthHeader(this.token),
      })
      .subscribe(
        (success) => {
          if (success.token === undefined || success.msg != undefined) {
            window.alert(
              "Ihr Login konnte nicht aktualisiert werden. Bitte speichern Sie Ihre Arbeit und melden Sie sich erneut an."
            );
          }
          this.token = success.token;
          localStorage.setItem(UserService.PH_TOKEN, this.token);
          setTimeout(
            () => this.refreshToken(),
            this.calculateRefreshTime(this.token)
          );
        },
        (error) => {
          window.alert(
            "Ihr Login konnte nicht aktualisiert werden. Bitte speichern Sie Ihre Arbeit und melden Sie sich erneut an."
          );
        }
      );
  }

  private calculateRefreshTime(jwt: string): number {
    return (
      extractFieldFromJWT<number>(jwt, "exp") * 1000 -
      new Date().getTime() -
      UserService.TOKEN_REFRESH_OFFSET
    );
  }
}

type TLoginSuccess = { token: string; msg: string };

export enum TStatus {
  Login,
  Logout,
  LoginFailed,
}
