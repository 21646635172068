import { Injectable } from "@angular/core";
import Commentary from "../_core/Commentary";
import { Entry, TEntryStatus, WSEntryType } from "../_core/Entry";
import { UserService } from "./user.service";
import { WebSocketService, WSMethods } from "./web-socket.service";

@Injectable({
  providedIn: 'root'
})
export class EntryService {
  constructor(private socket:WebSocketService, private userService:UserService){}

  public putEntry(pbcListId: string, indexList:number[], entry:Entry){
    let obj = {
      list_id: pbcListId,
      index_list: indexList,
      entry: entry,
      entry_id: entry.internalID
    }
    entry.modificationDate = new Date();
    this.socket.send(WebSocketService.setup(obj, WSMethods.PUT, WSEntryType.ONE, this.userService.token));
  }

  public postEntry(pbcListId: string, categoryIndexList: number[] ,entry:Entry){
    let obj = {
      list_id: pbcListId,
      index_list: categoryIndexList,
      entry: entry
    }
    entry.modificationDate = new Date();
    this.socket.send(WebSocketService.setup(obj, WSMethods.POST, WSEntryType.ONE, this.userService.token));
  }

  public putState(pbcListId: string, entryId: string, newState:TEntryStatus){
    let obj = {
      list_id: pbcListId,
      entry_id: entryId,
      state: newState
    }
    this.socket.send(WebSocketService.setup(obj, WSMethods.PUT, WSEntryType.STATE, this.userService.token));
  }

  public postComment(pbcListId:string, entryId: string, comment:Commentary){
    let obj = {
      list_id: pbcListId,
      entry_id: entryId,
      comment: comment
    }
    this.socket.send(WebSocketService.setup(obj, WSMethods.POST, WSEntryType.COMMENT, this.userService.token));
  }

  deleteComment(pbcListId:string, entryId: string, comment:Commentary){
    let obj = {
      list_id: pbcListId,
      entry_id: entryId,
      comment: comment
    }
    this.socket.send(WebSocketService.setup(obj, WSMethods.DELETE, WSEntryType.COMMENT, this.userService.token));
  }
}
