import { createSelector } from "@ngrx/store";
import { LocalConfig, LocalConfigData } from "src/app/_core/LocalConfig";
import { AppState } from "../app.state";
import { LocalConfigState } from "./local-config.reducers";

const select = (state: AppState) => state.localConfig;

const get = createSelector(
  select,
  (state: LocalConfigState) => state.localConfig
);

const getData = createSelector(
  select,
  get,
  (_: LocalConfigState, localConfig: LocalConfig) : LocalConfigData => localConfig.data
);

export const LocalConfigSelectors = {
  get: get,
  getData: getData,
  getSearchValue: createSelector(
    select,
    getData,
    (_: LocalConfigState , data:LocalConfigData) : string => {
      if(data == undefined) return "";
      else return data.listsShow.searchItem;
    }
  ),
};
