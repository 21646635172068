import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Chart from 'chart.js';
import { cssVarValue } from 'src/app/_helper/Helpers';

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  @Output() hoverItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Input() id: number = 0; // needed for multiple instances
  @Input() cutoutPercentage: number = 60;

  chart:Chart;
  __status: {text: string, count: number, value: string, percentage: number}[];

  constructor() { }

  @Input()
  get status(): {text: string, count: number, value: string, percentage: number}[] {
    return this.__status;
  }
  set status(status: {text: string, count: number, value: string, percentage: number}[]){
    this.__status = status;

    const mappedData = this.prepareData(this.status);
    if(this.chart !== undefined) this.mapToChart(this.chart, mappedData);
    else this.init(mappedData, false);
  }


  ngOnInit() {
    //Chart.register(LinearScale, DoughnutController, ArcElement)
    const mappedData = this.prepareData(this.status);
    if(this.chart != undefined) this.mapToChart(this.chart, mappedData);
    else this.init(mappedData);
  }

  init([labels, values, bgColors], animate: boolean = true) {
    let setup = {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
            label: 'Anzahl',
            data: values,
            backgroundColor: bgColors,
            borderWidth: 0,
            pointHoverBackgroundColor: bgColors
        }]
      },
      options: {
        animations: animate ? {
          duration: 1000,
          easing: 'easeOutExpo',
          from: 1,
          to: 0,
          loop: true
        } : undefined,
        showLines: false,
        cutoutPercentage: this.cutoutPercentage,
        legend: {
          display: false,
        },
        tooltips: {
          enable: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const label: string = data.labels[tooltipItem.index];
              this.hoverItem.emit(label)
              return label + " " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            }
          }
        },
        onBlur: () => this.blur.emit(),
      }
    };

    setTimeout(() => {
      const canvas = document.getElementById("d-chart-"+this.id) as HTMLCanvasElement;
      canvas.getContext('2d').clearRect(0,0,canvas.width, canvas.height);
      this.chart = new Chart(canvas.getContext('2d'), setup);
    }, 1);
  }

  prepareData(status: any): [any[], any[], any[]]{
    let labels = [];
    let values = [];
    let bgColors = [];

    status.forEach(state => {
      labels.push(state.text);
      values.push(state.count);
      bgColors.push(cssVarValue('--color-'+state.value));
    });

    return [labels, values, bgColors];
  }

  mapToChart(chart: any, [labels, values, bgColors]) {
    chart.data.datasets = [{
        label: 'Anzahl',
        data: values,
        backgroundColor: bgColors,
        borderWidth: 0,
        pointHoverBackgroundColor: bgColors
    }];
    chart.data.labels = labels;
    chart.options.animations = undefined;
    chart.update();
  }

}
