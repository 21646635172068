import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { fadeAnimation } from "src/app.animations";
import { CustomEntryField } from "src/app/_core/CustomEntryField";
import UserData from "src/app/_core/UserData";
import Commentary from "../../_core/Commentary";
import { Entry, TEntryStatus, WSEntryType } from "../../_core/Entry";
import { AuthorizationService } from "../../_services/authorization.service";
import { UserService } from "../../_services/user.service";
import { WSMethods } from "../../_services/web-socket.service";
import { Item } from "../../_uicomponents/item-picker/Item";
import { ModalDialog } from "../../_uicomponents/modal-dialog/modal-dialog.component";
import { HistoryLogService } from "src/app/_services/history-log.service";
import { date2YMD } from '../../_helper/ConversionHelper';
import HistoryLogEntry from "src/app/_core/HistoryLogEntry";
import { DatePipe } from "@angular/common";
import { User } from "src/app/_core/User";

@Component({
  selector: "entry-show",
  animations: [fadeAnimation],
  templateUrl: "./entry-show.component.html",
  styleUrls: ["./entry-show.component.scss"],
})
export class EntryShowComponent implements OnInit, AfterViewInit {
  @Input() entry: Entry;
  @Input() listIsArchived: boolean = true;
  @Input() isSingleEditMode: boolean = false;
  @Output() onEntryChanged = new EventEmitter<{
    data: any;
    changeType: WSEntryType;
    method?: WSMethods;
    newEntry: Entry;
  }>();
  @Output() onFileAction = new EventEmitter<{
    formData: FormData;
    action: WSMethods;
    callbackFn: (arg0: any) => void;
  }>();
  @Output() onEdit = new EventEmitter<void>();
  @ViewChildren(ModalDialog) queryRecentStatusDialog: QueryList<ModalDialog>;
  recentStatusDialog: ModalDialog
  __responsibles: UserData[];
  statusDefinitions: Item[];
  fileStates: {} = {};
  username: string = "";
  isCollapsed: boolean = true;
  isAdditionalShown: boolean = false;
  clickFileOpenDialog: Subject<void> = new Subject<void>();
  entryComment: string[];

  TEntryStatus = TEntryStatus;
  locale: string = "";

  mailList: string = "";
  ccMailList: string = "";
  entryLogs: HistoryLogEntry[] = [];

  JSON = JSON;

  @Input() set responsibles(responsibles: UserData[]) {
    this.__responsibles = responsibles;
    const mails = this.getMailList();
    this.mailList = mails[0];
    this.ccMailList = mails[1];
  }
  get responsibles(): UserData[] {
    return this.__responsibles;
  }

  constructor(
    public authService: AuthorizationService,
    private userService: UserService,
    private translator: TranslateService,
    @Inject(LOCALE_ID) locale: string,
    public hlogService: HistoryLogService
  ) {
    this.username = userService.getUserName();
    this.locale = locale;
  }

  ngOnInit() {
    const entryStatus =
      this.entry.status != undefined
        ? this.entry.status.toLowerCase()
        : TEntryStatus.opened;
    this.entryComment =
      this.entry.comment != undefined ? this.entry.comment.split("\n") : [""];
    this.statusDefinitions = [
      new Item(
        "var(--color-open)",
        "var(--color-text-open)",
        this.translator.instant("STATES.OPENED"),
        TEntryStatus.opened.toLowerCase() == entryStatus ? true : false,
        TEntryStatus.opened,
        (activeState) =>
          this.authService.canChangeEntryStateTo(TEntryStatus.opened) &&
          activeState != TEntryStatus.accepted
      ),
      new Item(
        "var(--color-closed)",
        "var(--color-text-closed)",
        this.translator.instant("STATES.CLOSED"),
        TEntryStatus.closed.toLowerCase() == entryStatus ? true : false,
        TEntryStatus.closed,
        (activeState) =>
          this.authService.canChangeEntryStateTo(TEntryStatus.closed) &&
          activeState != TEntryStatus.accepted
      ),
      new Item(
        "var(--color-not-needed)",
        "var(--color-text-not-needed)",
        this.translator.instant("STATES.NOT_NEEDED"),
        TEntryStatus.not_needed.toLowerCase() == entryStatus ? true : false,
        TEntryStatus.not_needed,
        (activeState) =>
          this.authService.canChangeEntryStateTo(TEntryStatus.not_needed) &&
          activeState != TEntryStatus.accepted
      ),
      new Item(
        "var(--color-accepted)",
        "var(--color-text-accepted)",
        this.translator.instant("STATES.FOR_DOWNLOAD"),
        TEntryStatus.for_download.toLowerCase() == entryStatus ? true : false,
        TEntryStatus.for_download,
        (activeState) =>
          this.authService.canChangeEntryStateTo(TEntryStatus.for_download) &&
          activeState != TEntryStatus.for_download
      ),
      new Item(
        "var(--color-feedback)",
        "var(--color-text-feedback)",
        this.translator.instant("STATES.FEEDBACK"),
        TEntryStatus.feedback.toLowerCase() == entryStatus ? true : false,
        TEntryStatus.feedback,
        (activeState) =>
          this.authService.canChangeEntryStateTo(TEntryStatus.feedback)
      ),
      new Item(
        "var(--color-accepted)",
        "var(--color-text-accepted)",
        this.translator.instant("STATES.ACCEPTED"),
        TEntryStatus.accepted.toLowerCase() == entryStatus ? true : false,
        TEntryStatus.accepted,
        (activeState) =>
          this.authService.canChangeEntryStateTo(TEntryStatus.accepted)
      ),
    ];

    if (this.entry.files == undefined) {
      this.entry.files = [];
    } else {
      this.entry = this.sortFiles(this.entry);
    }
  }

  ngAfterViewInit(): void {
    this.queryRecentStatusDialog.changes.subscribe((comp:QueryList<ModalDialog>) => {
      this.recentStatusDialog = this.queryRecentStatusDialog.first;
    });

    this.entryLogs = this.hlogService.filterBy('entry-state', 'PUT', this.entry.internalID);
  }

  sortFiles(entry: Entry): Entry {
    entry.files.sort((a, b) => a.timestamp - b.timestamp);
    return entry;
  }

  checkDateExpired(entry) {
    if (
      entry.endDate != undefined &&
      entry.endDate != "" &&
      entry.endDate != null
    )
      return entry.endDate.getTime() < new Date().getTime();
    else return false;
  }

  statusChanged(selectedItem: Item) {
    this.entry.status = this.determineSelectedStatus(selectedItem.value);
    this.onEntryChanged.emit({
      data: this.entry.internalID,
      changeType: WSEntryType.STATE,
      newEntry: this.entry,
    });
  }

  determineSelectedStatus(status: string): TEntryStatus {
    switch (status) {
      case TEntryStatus.accepted:
        return TEntryStatus.accepted;
      case TEntryStatus.closed:
        return TEntryStatus.closed;
      case TEntryStatus.opened:
        return TEntryStatus.opened;
      case TEntryStatus.for_download:
        return TEntryStatus.for_download;
      case TEntryStatus.not_needed:
        return TEntryStatus.not_needed;
      case TEntryStatus.feedback:
        return TEntryStatus.feedback;
    }
  }

  commentPosted(comment: Commentary) {
    if (
      this.entry.userComment == undefined ||
      typeof this.entry.userComment == "string"
    )
      this.entry.userComment = [];
    this.entry.userComment.push(comment);
    this.onEntryChanged.emit({
      data: this.entry.internalID,
      changeType: WSEntryType.COMMENT,
      method: WSMethods.POST,
      newEntry: this.entry,
    });
  }

  commentDeleted(commentIndex: number) {
    this.onEntryChanged.emit({
      data: { entryId: this.entry.internalID, commentIdx: commentIndex },
      changeType: WSEntryType.COMMENT,
      method: WSMethods.DELETE,
      newEntry: this.entry,
    });
  }

  fileAction(formData: FormData, action: String, callbackFn: (any) => void) {
    formData.append("entry_id", this.entry.internalID);
    if (action == WSMethods.POST) {
      this.onFileAction.emit({
        formData: formData,
        action: WSMethods.POST,
        callbackFn: callbackFn,
      });
    } else if (action == WSMethods.DELETE) {
      this.onFileAction.emit({
        formData: formData,
        action: WSMethods.DELETE,
        callbackFn: callbackFn,
      });
    } else if (action == WSMethods.GET) {
      this.onFileAction.emit({
        formData: formData,
        action: WSMethods.GET,
        callbackFn: callbackFn,
      });
    }
  }

  customFieldChanged(customField: CustomEntryField, fieldPos: number) {
    this.entry.fields[fieldPos] = customField;
    this.onEntryChanged.emit({
      data: this.entry.internalID,
      changeType: WSEntryType.ONE,
      newEntry: this.entry,
    });
  }

  resolveResponsibles(entry: Entry): string {
    if (entry.responsibles == undefined) return "";

    return entry.responsibles
      .map((eresp) => {
        const lel = this.responsibles.find((resp) => resp.user_id === eresp);
        if (lel != undefined) return lel.resolveResponsible();
        else return undefined;
      })
      .filter((el) => el != undefined)
      .join(", ");
  }

  getMailList(): [string, string] {
    let allMails = this.responsibles.filter(
      (responsible) =>
        responsible.data != undefined &&
        responsible.data.mail != undefined &&
        responsible.data.mail != ""
    );
    if (this.entry.responsibles == null || this.entry.responsibles == undefined )
      return [
        allMails.map((responsible) => responsible.data.mail).join(","),
        "",
      ];

    let mailRow = allMails
      .filter((el) => this.entry.responsibles.includes(el.user_id))
      .map((responsible) => responsible.data.mail)
      .join(",");
    let ccRow = allMails
      .filter((el) => !this.entry.responsibles.includes(el.user_id))
      .map((responsible) => responsible.data.mail)
      .join(",");
    return [mailRow, ccRow];
  }

  openRecentStates(event: any) {
    this.recentStatusDialog.show({x: event.layerX, y: event.layerY});
  }

  convertDate = (time: number) => {
    return new DatePipe('de-DE').transform(new Date(time), 'dd.MM.yyyy HH:mm:ss');
  }

  translateState(state: string) {
    return this.translator.instant('STATES.' + state.toUpperCase());
  }

  resolveResponsibleByUsername(name: string) {
    const user = this.responsibles.find(el => el.name == name);
    if(user === undefined) return name;
    return user.data.forename + ' ' + user.data.surname;
  }
}
