import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Group, TRootedGroup } from "../_core/Group";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  static BASE_URL = `${environment.protocol}://${environment.host}:${environment.serverPort}`;
  constructor(private userService: UserService, private http: HttpClient) {}

  getAll() {
    return this.http.get<Group[]>(GroupService.BASE_URL + "/groups/all", {
      params: this.authHeader(),
    });
  }

  addNewGroup(group: Group) {
    return this.http.post<Group>(
      GroupService.BASE_URL + "/group/add",
      { ...group },
      { params: this.authHeader() }
    );
  }

  deleteFromHierarchy(group: Group) {
    const updatedGroup = group;
    updatedGroup.parentName = undefined;
    return this.update(updatedGroup);
  }

  deleteWithHierarchy(groupName: string) {
    return this.http.delete<void>(
      GroupService.BASE_URL + "/group/hierarchy/" + groupName,
      { params: this.authHeader() }
    );
  }

  update(group: Group) {
    group.parentName = group.parentName == null ? undefined : group.parentName;
    return this.http.put<Group>(
      GroupService.BASE_URL + "/group/modify",
      { ...group },
      { params: this.authHeader() }
    );
  }

  delete(groupName: string) {
    return this.http.delete<{ deleted: Group, modified: Group[] }>(
      GroupService.BASE_URL + "/group/" + groupName,
      { params: this.authHeader() }
    );
  }

  getHiearchy(name: string) {
    return this.http.get<TRootedGroup>(
      GroupService.BASE_URL + "/group/children/" + name,
      { params: this.authHeader() }
    );
  }

  authHeader(): HttpParams {
    return new HttpParams().append("auth", "Bearer " + this.userService.token);
  }
}
