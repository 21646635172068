import { ChangeDetectorRef, Component, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Group, TRootedGroup } from "src/app/_core/Group";
import { GroupService } from "src/app/_services/group.service";
import { PbcService } from "src/app/_services/pbc.service";
import { UserService } from "src/app/_services/user.service";


@Component({
  selector: "app-group-edit",
  templateUrl: "./group-edit.component.html",
  styleUrls: ["./group-edit.component.scss"]
})
export class GroupEditComponent implements OnInit {
  groupHierarchy: TRootedGroup = undefined;
  triggerRedraw = new EventEmitter<void>();
  clients: any[] = [];

  constructor(private route: ActivatedRoute, private groupService: GroupService, private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.route.params.subscribe(el => {
      this.groupService.getHiearchy(el.name).subscribe((hierarchy: TRootedGroup) => {
        this.groupHierarchy = hierarchy;
      },
      error => {
        this.navigateToOverview();
      });
    });

    this.userService.getAuthorizations().subscribe((rights) => {
      const clientRights = rights.filter(
        (right) => right.object === "pbc-list"
      );
      this.clients = clientRights.reduce(
        (prev, el) => [...prev, ...el.values],
        []
      );
    });
  }

  deleted() {
    this.groupService.getHiearchy(this.groupHierarchy.group.name).subscribe((hierarchy: TRootedGroup) => {
      this.groupHierarchy = hierarchy;
    },
    error => {
      this.navigateToOverview();
    });
  }

  navigateToOverview() { this.router.navigate(['/groups/overview']); }


}
