import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Entry, TEntryStatus, WSEntryType } from '../_core/Entry';
import { jumpToAnchor } from '../_helper/Helpers';
import { PbcService } from './pbc.service';
import { TStatus, UserService } from './user.service';
import { WebSocketService, WSMethods } from './web-socket.service';

@Injectable({
  providedIn: 'root'
})
export class DesktopNotificationService {

  permissionGranted: boolean = false;

  constructor(private socket:WebSocketService, private userService:UserService, private pbcService: PbcService, private router: Router, private translator:TranslateService) {
  }

  ask(){
    Notification.requestPermission().then((permission) => {
      this.permissionGranted = permission != 'denied';
      if(this.permissionGranted) this.register();
    });
  }

  message(data:any, body: string = "", icon: string = "", attachedData: any = {}){
    new Notification(data, {body: body, icon: icon, data: attachedData}).onclick = (e) => {
      let notif: Notification  = e.target as Notification;
      jumpToAnchor(notif.data.index_list.join("-") + 'e');
      return null;
    };
  }

  register(){
    // icoming messages
    this.socket.message((e) => {
      const data = JSON.parse(e.data);
      this.handleMessages(data);
    });
  }

  handleMessages({info: {__method, __type, __status}, data, __sender__}:{info: {__method: string, __type:string, __status:number}, data:any, __sender__:string}){
    if(__status == 200 /*&& __sender__ !== this.userService.user.name*/){
      if(__method === WSMethods.POST && __type == WSEntryType.COMMENT){
        this.message(`New Comment by  ${data.comment.user}`, data.comment.text, "", data);
      }
      else if(__method == WSMethods.PUT && __type == WSEntryType.STATE){
        this.message(`Status was set to ${this.translator.instant(Entry.statusText(data.state))} by ${__sender__}.`, "", "", data);
      }
    }
  }
}
