import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { replaceUmlauts } from '../_helper/Helpers';
import { MatDialog } from '@angular/material';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { AuthorizationService } from '../_services/authorization.service';

@Component({
  selector: 'top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  @Input() applyBgColor: boolean = true;

  constructor(public userService: UserService, public location:Location, public dialog: MatDialog, public authService: AuthorizationService ) { }

  ngOnInit() {
  }

  replaceAllUmlauts = replaceUmlauts;

  openUserSettingsDialog(){
    let dialogRef = this.dialog.open(UserSettingsComponent, {
      height: '400px',
      width: '600px',
    });
  }
}
