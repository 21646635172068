export class CustomEntryField {
  type: string;
  text: string;
  value: string;
  isForEditMode: boolean;

  static fromJSON(source) : CustomEntryField {
    const c = new CustomEntryField();
    c.type = source.type;
    c.text = source.text;
    c.value = source.value;
    c.isForEditMode = source.isForEditMode;

    return c;
  }

  copy()  : CustomEntryField {
    const c  =new CustomEntryField();
    c.type = this.type;
    c.text = this.text;
    c.value = this.value;
    c.isForEditMode  = this.isForEditMode;
    return c;
  }
}
