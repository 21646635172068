import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../_core/User';
import { UserService } from '../_services/user.service';
import { WebSocketService } from '../_services/web-socket.service';

@Component({
  selector: 'login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {

  user:User;
  loginFailed:boolean = false;

  constructor(private router: Router,  private userService:UserService, private ws:WebSocketService ) {
    this.user = userService.user; // if user is still logged in the credentials are set already
  }

  ngOnInit() {
  }

  login(){
    this.userService.login(this.user, (loggedIn, url) => {
      if(loggedIn){
        this.router.navigate([url]);
      }
      else {
        this.loginFailed = true;
      }
    });
  }

  loginOnKeydown(event) {
    if (event.key === "Enter") {
      this.login();
    }
  }

}
