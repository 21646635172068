import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Category } from 'src/app/_core/Category';
import { Entry } from 'src/app/_core/Entry';
import { EntryTemplate } from 'src/app/_core/EntryTemplate';
import UserData from 'src/app/_core/UserData';
import { EntryService } from 'src/app/_services/entry.service';
import { PbcService } from 'src/app/_services/pbc.service';
import { AppState } from 'src/app/_store/app.state';
import { EntryTemplateActions, EntryTemplateSelector } from 'src/app/_store/entry-template';

@Component({
  selector: 'entry-edit-create-dialog',
  templateUrl: './entry-edit-create-dialog.component.html',
  styleUrls: ['./entry-edit-create-dialog.component.scss']
})
export class EntryEditCreateDialogComponent implements OnInit {
  entry:Entry;
  categoryList: CategoryList[];
  entryTemplates$: Observable<EntryTemplate[]>;

  constructor(private dialogRef: MatDialogRef<EntryEditCreateDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: EntryEditCreateDialogData, private entryService: EntryService, private store: Store<AppState>) {
    if(dialogData.entry != undefined) this.entry = dialogData.entry;
    else this.entry = new Entry();

    if(dialogData.categoryIndexList == undefined) dialogData.categoryIndexList = [0];

    this.categoryList = this.makeCategoryList(dialogData.rootCategories, []);

    this.entry.startDate = new Date();
    this.store.dispatch(EntryTemplateActions.getAll());
    this.entryTemplates$ = this.store.select(EntryTemplateSelector.all);
  }

  ngOnInit() {
  }

  makeCategoryList(categories: Category[], parentIndex: number[]) : CategoryList[] {
    const list = categories.reduce((p, e, i) =>
      p.concat([{
        indexList: parentIndex.concat([i]),
        description: e.description
      }]).concat(this.makeCategoryList(e.categories, parentIndex.concat([i])))
      , []);

    return list;
  }

  onCategorySelected(data: string[]) {
    this.dialogData.categoryIndexList = data.map(e => Number(e));
  }

  onClose(){
    this.entry.modificationDate = new Date();
    this.entryService.postEntry(this.dialogData.pbcListId, this.dialogData.categoryIndexList, this.entry);
    this.dialogRef.close();
  }

  onAbort(){
    this.dialogRef.close();
  }

}

export interface EntryEditCreateDialogData {
  responsibles: UserData[];
  entry?: Entry;
  categoryIndexList: number[];
  pbcListId: string;
  rootCategories: Category[];
  entryTemplateType?: string;
  entryTemplates: EntryTemplate[];
}

interface CategoryList {
  indexList: number[];
  description: string;
}
