import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { EntryTemplateState, entryTemplateReducer, EntryTemplateEffects } from "./entry-template";
import { ListTemplateState, listTemplateReducer, ListTemplateEffects } from "./list-template";
import { LocalConfigEffects, localConfigReducer, LocalConfigState } from "./local-config";

export interface AppState {
    listTemplate: ListTemplateState;
    entryTemplate: EntryTemplateState;
    localConfig: LocalConfigState;
}


@NgModule({
    imports: [
        StoreModule.forFeature('listTemplate', listTemplateReducer),
        StoreModule.forFeature('entryTemplate', entryTemplateReducer),
        StoreModule.forFeature('localConfig', localConfigReducer),
        EffectsModule.forFeature([ListTemplateEffects, EntryTemplateEffects, LocalConfigEffects])
    ]
})
export class NgrxStoreModule {

}
