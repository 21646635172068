import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../_services/authorization.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  isAuthLoading:boolean = true;

  constructor(private authService:AuthorizationService) {
    authService.init().then(a => {
      this.isAuthLoading = false;
    });
  }

  ngOnInit() {
  }

}
