import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'info-bubble',
  templateUrl: './info-bubble.component.html',
  styleUrls: ['./info-bubble.component.scss']
})
export class InfoBubbleComponent implements OnInit {
  @Input() text: string = "";
  @Input() type: 'info' | 'warning' = 'info';

  constructor() { }

  ngOnInit() {
  }

}
