import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { Local } from 'protractor/built/driverProviders';
import { CustomEntryField } from 'src/app/_core/CustomEntryField';
import { isEmptyString } from 'src/app/_helper/Helpers';

@Component({
  selector: 'cust-input',
  templateUrl: './cust-input.component.html',
  styleUrls: ['./cust-input.component.scss']
})
export class CustInputComponent implements OnInit {
  static converters = undefined;
  @Input() mode: 'edit' | 'show';
  @Input() customField: CustomEntryField;
  @Input() showText:boolean = true;
  @Output() customFieldChange: EventEmitter<CustomEntryField> = new EventEmitter();
  value: string;

  inputType:string;
  locale: string;
  useConverter: CustConverter;

  constructor(@Inject(LOCALE_ID) locale: string) {
    if(CustInputComponent.converters == undefined){
      CustInputComponent.converters = {
        "date": new DateConverter(locale),
        "text": new TextConverter(),
        "number": new NumberLocaleConverter(locale)
      };
    }

    this.locale  =locale;
  }

  ngOnInit() {
    this.inputType = this.customField.type.replace("input-", "");
    this.useConverter = CustInputComponent.converters[this.inputType];
    if(this.mode === 'edit') this.value = this.useConverter.toEditView(this.customField.value);
    else this.value = this.useConverter.toView(this.customField.value);
  }

  valueChanges(value) {
    this.customField.value = this.useConverter.toValue(value);
    this.customFieldChange.emit(this.customField);
  }
}

interface CustConverter {
  toView(s:string) : any;
  toEditView(s: any) : any;
  toValue(s:string) : any;
}
class TextConverter implements CustConverter {
  toView(s: string) {
    return s;
  }
  toEditView(s: any) {
    return this.toView(s);
  }
  toValue(s: string) {
    return s;
  }
}
class NumberLocaleConverter implements CustConverter {
  constructor(private locale: string, private numFixedDigits: number = 2){}

  toView(s: string) {
    if(s === null) s = "0";
    if(isNaN(Number(s))) s = this.replaceDigitSplitter(s);

    const num:number = Number(s);
    return num.toLocaleString(this.locale,
      this.numFixedDigits == undefined ? {}: {
        maximumFractionDigits: this.numFixedDigits,
        minimumFractionDigits: this.numFixedDigits
      });
  }

  toEditView(s: any) {
    return this.toView(s);
  }

  toValue(s: string) {
    return s;
  }

  private replaceDigitSplitter(num: string) {
    let rnum = num.replace(",", ".");
    return isNaN(Number(rnum)) ? num : rnum;
  }
}
class DateConverter implements CustConverter {
  constructor(private locale: string){}

  toView(s: string): any {
    if(isEmptyString(s)) return undefined;
    const newDate = this.toDate(s);
    return new Intl.DateTimeFormat(this.locale, { year: '2-digit', day: '2-digit', month: '2-digit' }).format(newDate);
  }

  toDate(s: string) : Date {
    if(isEmptyString(s)) return undefined;
    const dateparts = s.split("-");
    const newDate = new Date();
    newDate.setFullYear(Number(dateparts[0]));
    newDate.setMonth(Number(dateparts[1])-1);
    newDate.setDate(Number(dateparts[2]));
    return newDate;
  }

  toEditView(s: any) {
      return this.toValue(this.toDate(s));
  }

  toValue(s: any): string {
    if(isEmptyString(s)) return '';
    const newDate: Date = s;
    const month  =newDate.getMonth() + 1;
    return newDate.getFullYear() + "-" + (month > 9 ? month : '0'+month) + "-" + (newDate.getDate() > 9 ? newDate.getDate() : '0'+newDate.getDate());
  }
}
