import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LanguageSwitcherService } from "./language-switcher.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "language-switcher",
  templateUrl: "./language-switcher.component.html",
  styleUrls: ["./language-switcher.component.scss"],
})
export class LanguageSwitcherComponent implements OnInit {
  @ViewChild("languageButton", { static: true }) languageButton: ElementRef;
  showSelection = false;

  languages: {
    code: string;
    path: string;
    name: string;
  }[] = [
    { code: "de", path: "assets/i18n/de.png", name: "Deutsch" },
    { code: "en", path: "assets/i18n/en.png", name: "English" },
    { code: "es", path: "assets/i18n/es.png", name: "Español" },
  ];

  LSS = LanguageSwitcherService;
  localStorage = localStorage;
  constructor(private tr: TranslateService) {}

  ngOnInit() {}

  isPositioned = false;
  showSelector() {
    this.showSelection = !this.showSelection;
  }

  selectLang(selected: string) {
    LanguageSwitcherService.get().set(selected, this.tr);
    this.showSelection = false;
  }

  getSelected(): {
    code: string;
    path: string;
    name: string;
  } {
    return this.languages.find((el) => LanguageSwitcherService.get().lang == el.code);
  }
}
