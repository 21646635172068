import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'orderBy' })
export class OrderBy implements PipeTransform {

  transform(value: any[], attr: {order: string, column: string}): any[] {
    if (!value || attr.order === '' || !attr.order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    else {
      if(attr.order==='desc'){
        return value.sort((a,b) => b[attr.column] - a[attr.column]);
      }
      else{
        return value.sort((a,b) => a[attr.column] - b[attr.column]);
      }
    } // sort 1d array
  }
}
