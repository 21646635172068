import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { ListTemplateService } from "src/app/_services/list-template.service";
import { ListTemplateActions } from "./list-template.actions";

@Injectable({
  providedIn: "root",
})
export class ListTemplateEffects {
  constructor(
    private actions$: Actions,
    private listTemplateService: ListTemplateService
  ) {}

  getAllListTemplates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ListTemplateActions.getAll),
        mergeMap(() =>
          this.listTemplateService
            .getAll()
            .pipe(
              map((templates) =>
                ListTemplateActions.getAllSuccess({ listTemplates: templates })
              )
            )
        )
      ),
    { dispatch: true }
  );
}
