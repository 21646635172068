import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private serverUrl = environment.wsprotocol+"://"+environment.host+":"+environment.serverPort+"/socket/websocket";
  readonly RECONNECT_LIMIT = 5;
  private ws: WebSocket;
  private onOpen:EventEmitter<Event> = new EventEmitter();
  private onError:EventEmitter<Event> = new EventEmitter();
  private onMessage:EventEmitter<MessageEvent> = new EventEmitter();
  private onClose:EventEmitter<Event> = new EventEmitter();
  private onSend:EventEmitter<Event> = new EventEmitter();

  private connected = false;
  private msgBacklog = [];

  constructor() {
    this.initReconnectionHandler();
    this.initWS();
  }

  public static setup(object:any, method:WSMethods, type:string, userToken:string){
    return {
      __method : method,
      __type : type,
      __authorization: "Bearer " + userToken,
      data : object
    };
  }

  async initWS(): Promise<Event>{
    this.ws = new WebSocket(this.serverUrl);

    this.ws.onopen = (e) => {
      this.connected = true;
      this.resetReconnectCounter();

      this.onOpen.emit(e);
      this.flushMsgBacklog();
    };
    this.ws.onclose = (e) => {
      this.connected = false;
      this.onClose.emit(e);
    }
    this.ws.onerror = (e) => this.onError.emit(e);
    this.ws.onmessage = (e) =>  this.onMessage.emit(e);

    return this.onOpen.toPromise();
  }

  private initReconnectionHandler(){
    if(!this.connected){
      this.close((e) => this.reconnect());
    }
  }

  reconnectCounter:number = 1; // is resetted if connection was successfully established (onOpen)
  private reconnect(){
    if(this.reconnectCounter <= this.RECONNECT_LIMIT){
      this.reconnectCounter++;
      this.initWS();
    }
  }

  resetReconnectCounter(){
    this.reconnectCounter = 1;
  }

  // Prüfung eingebaut, da Error in der Console kam
  msgCounter = 0;
  send(data:any){
    if(this.connected != true) {
      this.msgBacklog.push(data);
      return;
    }

    let msgArray = this.chunk(JSON.stringify(data), 4096);
    msgArray = []; // TEST: to supress partials

    if(msgArray.length > 1){
      this.msgCounter ++;
      for(let i=0;  i < msgArray.length; i++){
        let data = this.createPart(this.msgCounter, i, msgArray.length, msgArray[i]);
        this.ws.send(JSON.stringify(data));
      }

      this.msgCounter--;
    }
    else {
      this.onSend.emit(data);
      this.ws.send(JSON.stringify(data));
    }
  }

  createPart(msgID, partNo, parts,msg){
    return {
      dataPart: "" + msg,
      id: msgID,
      nr: partNo,
      parts: parts
    };
  }

  open(cb:(event:Event) => any){
    return this.onOpen.subscribe((e) => {
      cb(e);
    });
  }

  error(cb:(event:Event) => any){
    return this.onError.subscribe((e) => cb(e));
  }

  message(cb:(event:MessageEvent) => any){
    return this.onMessage.subscribe((e) => cb(e));
  }

  close(cb:(event:Event) => any){
    return this.onClose.subscribe((e) => cb(e));
  }

  sent(cb: (event:Event) => any){
    return this.onSend.subscribe((e) => cb(e));
  }

  isConnected(){
    return this.connected;
  }

  closeSubForLog: Subscription;
  openSubForLog: Subscription;
  messageSubForLog: Subscription;
  errorSubForLog: Subscription;
  sendSubForLog: Subscription;
  activateLog(){
    this.closeSubForLog = this.close(e => console.warn(e));
    this.openSubForLog = this.open(e => console.info(e));
    this.messageSubForLog = this.message(e => console.info(e));
    this.errorSubForLog = this.error(e => console.error(e));
    this.sendSubForLog = this.onSend.subscribe((msg) => console.info(msg));
  }
  deactivateLog() {
    this.closeSubForLog.unsubscribe();
    this.openSubForLog.unsubscribe();
    this.messageSubForLog.unsubscribe();
    this.errorSubForLog.unsubscribe();
    this.sendSubForLog.unsubscribe();
  }

  private chunk(s, maxChars) {
    let result = [];
    let nS = "";
    for(let i = 0; i < s.length; i++){
      nS += s.charAt(i);

      if(i >= maxChars){
        result.push(nS);
        nS = "";
      }
    }

    return result;
  }

  private flushMsgBacklog(){
    while(this.msgBacklog != undefined && this.msgBacklog.length != 0 ){
      let msg = this.msgBacklog.pop();
      this.send(msg);
    }
  }

  closeConnection(){
    this.ws.close();
  }
}

export enum WSMethods {
  GET = "GET", PUT = "PUT", POST = "POST", DELETE = "DELETE"
}
