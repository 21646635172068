import { Category } from "./Category";
import { Entry, TEntryStatus } from "./Entry";
import { ListTemplate } from "./ListTemplate";

export class PBCList extends ListTemplate {
  description:string;
  categories: Category[] = [];
  client: string;
  customListSettings: {[index: string] : string} = {};
  archived: boolean = false;

  static fromJSON(source: any) : PBCList {
    const list = new PBCList();
    list.id = source.id;
    list.description = source.description;
    list.client = source.client;
    list.type = source.type === '' || source.type === undefined ? 'PbC' : source.type;
    list.defaultEntryType = source.defaultEntryType;
    list.archived = source.archived;

    if(source.customListSettings != undefined){
      list.customListSettings = source.customListSettings;
    }

    if(source.categories != undefined && source.categories.length > 0)
      list.categories = source.categories.map(Category.fromJSON);
    else
      list.categories = [];

    list.setInternalIndex();
    return list;
  }

  getStatus(filterByName: string = undefined) : TPBCListStatusMap {
    const text_exceeded = Entry.statusText(TEntryStatus.exceeded.toString());
    const text_due = Entry.statusText(TEntryStatus.due.toString());

    if(this.categories == undefined)
      return {};

    return this.categories
      .map(cat => cat.determineStatus(filterByName))
      .reduce((prev, el) => {
        if(el === undefined) return prev;
        else return prev.concat(el);
      }, [])
      .reduce((prev, el) => {
        if(prev[el.text] === undefined) prev[el.text] = {count: 1, value: el.value};
        else prev[el.text].count++;

        if(el.exceeded && prev[text_exceeded+el.text] === undefined)
          prev[text_exceeded+el.text] = { count: 1, value: TEntryStatus.exceeded }
        else if(el.exceeded) prev[text_exceeded+el.text].count++;

        if(el.due && prev[text_due+el.text] === undefined)
          prev[text_due+el.text] = { count: 1, value: TEntryStatus.due }
        else if(el.due) prev[text_due+el.text].count++;

        return prev;
      }, {});
  }

  setDatesForAll(start: Date, end: Date) {
    this.categories = this.categories.map(c => c.setDatesForAll(start, end));
  }

  setDateForAll(date: Date, which: 'startDate' | 'endDate') {
    this.categories = this.categories.map(c => c.setDateForAll(date, which));
  }

  setInternalIndex(){
    this.categories = this.categories.map((c, i) => c.setInternalIndex([i]));
  }

  findEntryPosition(entryId: string) : number[] {
    for(let i = 0; i < this.categories.length; i++){
      const indices = this.categories[i].findEntryPosition(entryId, i);
      if(indices != undefined) {
        return indices;
      }
    }
    return undefined;
  }

  listAllCategories() : Category[] {
    return this.categories.reduce((prev, el) => {
      prev.push(el);
      prev = prev.concat(el.listAllCategories());
      return prev;
    }, []);
  }

  forEachEntry(callback: (entry:Entry) => void){
    if(this.categories!= undefined)
      this.categories.forEach((category) => category.forEachEntry(callback));
  }

  replaceAllEntries(indices: number[], entries: Entry[]) : Category{
    const first = indices.shift();
    return this.categories[first].replaceAllEntriesAt(indices, entries);
  }

  appendAllEntries(indices: number[], entries: Entry[]) : Category{
    const first = indices.shift();
    return this.categories[first].appendAllEntriesAt(indices, entries);
  }

  removeEntryAt(internalId: string, target: number[]){
    Category.removeEntryAt(target, internalId, this.categories);
  }
}

export type TPBCListStatusMap = {[state: string] : { count: number, value: string, exceeded: boolean, due: boolean } };
export type TListRight = { user_id : string, list_id: string, state: boolean };
