import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchInput: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchValue: string =  "";
  @Input() autofocus: boolean = false;

  @ViewChild("searchViewChild", {static: true}) searchViewChild: ElementRef;

  constructor() {}

  ngOnInit() {
    if(this.autofocus) this.searchViewChild.nativeElement.focus();
  }

}
