import {
  Component,
  destroyPlatform,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Observable } from "rxjs";
import { UploadedFile } from "src/app/_core/UploadedFile";
import { OrderBy } from "src/app/_pipes/OrderBy";

@Component({
  selector: "file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  @Output() onFileUpload: EventEmitter<{
    formData: FormData;
    callbackFn: (any) => void;
  }> = new EventEmitter<{ formData: FormData; callbackFn: (any) => void }>();
  @Output() onFileDelete: EventEmitter<{
    formData: FormData;
    callbackFn: (any) => void;
  }> = new EventEmitter<{ formData: FormData; callbackFn: (any) => void }>();
  @Output() onFileDownload: EventEmitter<FormData> =
    new EventEmitter<FormData>();
  @Output() onFilesDownload: EventEmitter<FormData> =
    new EventEmitter<FormData>();
  @Output() onUploadLimitExceeded: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() filesChange: EventEmitter<UploadedFile[]> = new EventEmitter<
    UploadedFile[]
  >();
  @Input() fileStates: {};
  @Input() username: string;
  @Input() isDeleteable: boolean = true;
  @Input() fileLimit: number = 0; // 0 => unlimited
  @Input() hideFileControls: boolean = false;
  @Input() isHidden: boolean = false;
  @Input() clickFileOpen: Observable<FileList>;
  @Input() isDownloading: boolean = false;
  @ViewChild("fileSelectRef", { static: false }) viewFileInput: any;

  __files: UploadedFile[];
  locale: string = "";

  constructor(@Inject(LOCALE_ID) locale) {
    this.locale = locale;
  }

  ngOnInit() {
    if (this.clickFileOpen != undefined) {
      this.clickFileOpen.subscribe((_) => {
        this.onFileInput(_);
      });
    }
  }

  @Input() set files(files: UploadedFile[]) {
    this.__files = new OrderBy().transform(files, {order: 'desc', column: 'timestamp'});;
  }

  get files(): UploadedFile[] {
    return this.__files;
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  onFileInput(fileList: FileList) {
    if (
      this.fileLimit > 0 &&
      this.files.length + fileList.length > this.fileLimit
    ) {
      this.onUploadLimitExceeded.emit(fileList.length);
      return;
    }
    let newFiles: File[] = this.fileListToArray(fileList);
    //this.__files = this.__files.concat(newFiles.map(f => new UploadedFile(f.name, f.size.toString(), f.name)));
    this.uploadFile(newFiles);
    //this.filesChange.emit(this.__files);
  }

  fileListToArray(fileList: FileList): File[] {
    let files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList.item(i));
    }
    return new OrderBy().transform(files, {order: 'asc', column: 'lastModified'});
  }

  downloadFile(pos: number) {
    const formData = new FormData();
    formData.append("file", JSON.stringify(this.__files[pos]));
    this.onFileDownload.emit(formData);
  }

  downloadAllFiles() {
    const formData = new FormData();
    formData.append("files", JSON.stringify(this.__files));
    this.onFilesDownload.emit(formData);
  }

  deleteFile(pos: number) {
    const formData = new FormData();
    formData.append("file", JSON.stringify(this.__files[pos]));
    this.onFileDelete.emit({
      formData: formData,
      callbackFn: this.handleDeleteResponse(pos),
    });
  }

  uploadFile(files: File[]) {
    const formData: FormData = new FormData();

    let counter = 0;
    for (let file of files) {
      const formData: FormData = new FormData();
      formData.set("file", file);

      this.onFileUpload.emit({
        formData: formData,
        callbackFn: this.handleUploadResponse(this.__files.length + counter),
      });
      counter++;
    }
  }

  handleUploadResponse = (fileIndex) => {
    return (state) => {
      this.fileStates[fileIndex] = state;

      if (state.type == 4) {
        this.fileStates[fileIndex] = undefined;
        const file = state.body[0].file;
        this.__files[fileIndex] = new UploadedFile(
          file.name,
          file.size,
          file.internalName,
          file.user,
          file.timestamp
        );
      }
    };
  }

  handleDeleteResponse(fileIndex) {
    return (state) => {
      if (state.status == 200) {
        setTimeout(() => {
          this.__files.splice(fileIndex, 1);
        }, 0);
      }
    };
  }
}
