import { _fixedSizeVirtualScrollStrategyFactory } from '@angular/cdk/scrolling';
import { createContentChild } from '@angular/compiler/src/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EntryTemplate } from 'src/app/_core/EntryTemplate';
import UserData from 'src/app/_core/UserData';
import { date2YMD } from 'src/app/_helper/ConversionHelper';
import { cssVarValue } from 'src/app/_helper/Helpers';
import { AuthorizationService } from 'src/app/_services/authorization.service';
import { HistoryLogService } from 'src/app/_services/history-log.service';
import { AppState } from 'src/app/_store/app.state';
import { EntryTemplateActions, EntryTemplateSelector } from 'src/app/_store/entry-template';
import { Category } from '../../_core/Category';
import { Entry, TEntryStatus, WSEntryType } from '../../_core/Entry';
import { WSMethods } from '../../_services/web-socket.service';

@Component({
  selector: 'category-show',
  templateUrl: './category-show.component.html',
  styleUrls: ['./category-show.component.scss']
})
export class CategoryShowComponent implements OnInit {
  @Input() hideEntries:Boolean = false;
  @Input() noCollapse = false;
  @Input() index: string[];
  @Input() isDownloading: boolean;
  @Input() responsibles: UserData[];
  @Input() showListNumbers: boolean;
  @Input() listType: string;
  @Input() listIsArchived: boolean = true;
  @Input() clientName: string;

  @Output() onCategoryClicked:EventEmitter<number[]> = new EventEmitter();
  @ViewChildren(CategoryShowComponent) categoryChildren: QueryList<CategoryShowComponent>;

  @Output() onEntryChanged = new EventEmitter<{changeData: string | any, changeType:WSEntryType, method:WSMethods, newEntry: Entry }>();
  @Output() onFileAction = new EventEmitter<{formData:FormData, action:WSMethods, callbackFn:(arg: any) => void}>();
  @Output() onDownload = new EventEmitter<string[] | number>();

  collapsed:boolean = false;
  isSelected:boolean = false;
  __category: Category;
  __filterState: TFilterValues;
  isEntryInEditMode: boolean[];
  hasEditRights: boolean = false;

  WSEntryType = WSEntryType;
  WSMethods = WSMethods;

  @Input() entryTemplates$: Observable<EntryTemplate[]>;

  constructor(private changeRef: ChangeDetectorRef, public authService: AuthorizationService, private historyLogService: HistoryLogService, private store: Store<AppState>,) {
  }

  async ngOnInit() {
    this.hasEditRights = (this.authService.canUpdatePBCList(this.clientName) || this.authService.canUpdatePBCList("*")) && !this.listIsArchived;

    this.applyFilterToEntries(this.__filterState);
  }

  get category():Category {
    return this.__category;
  }

  @Input()
  set category(category: Category) {
    this.isEntryInEditMode = new Array(category.entries.length).fill(false);
    this.__category = category;
  }

  sortOrder: 'asc' | 'desc' = 'desc';
  orderBy: string;
  sortEntries(fieldName: string) {
    // TODO: HACKED sorting (umstrukturieren)
    this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
    this.orderBy = fieldName;

    let sortFunction = (a,b) => {
      let fieldA = a[fieldName];
      let fieldB = a[fieldName];
      if(a[fieldName] instanceof Date){
        fieldA = date2YMD(a[fieldName] as Date);
      }
      if(b[fieldName] instanceof Date){
        fieldB = date2YMD(b[fieldName] as Date);
      }

      if(this.sortOrder == 'asc'){
        if(!isNaN(fieldA) && !isNaN(fieldB)) return Number(fieldA) - Number(fieldB);
        return String(fieldA).localeCompare(fieldB);
      }
      else {
        if(!isNaN(fieldA) && !isNaN(fieldB)) return Number(fieldB) - Number(fieldA);
        return String(fieldB).localeCompare(fieldA);
      }
    }
    if(fieldName.startsWith('cust.')) {
      fieldName = fieldName.replace("cust.", "");
      sortFunction = (a: Entry,b:Entry) => {
        const vala = a.fields.find(cust => cust.text == fieldName);
        const valb = b.fields.find(cust => cust.text == fieldName);

        if((vala == undefined && valb == undefined) || (vala.value == null && valb.value == null)) return 0;
        else if(valb == undefined || valb == null || valb.value == null || valb.value == undefined) return 1;
        else if(vala == undefined || vala.value == null) return -1;

        console.log(vala);

        if(this.sortOrder === 'asc'){
          if(!isNaN(Number(vala.value)) && !isNaN(Number(valb.value))) return Number(vala.value) - Number(valb.value);
          return vala.value.localeCompare(valb.value);
        }
        else {
          if(!isNaN(Number(vala.value)) && !isNaN(Number(valb.value))) return Number(valb.value) - Number(vala.value);
          return valb.value.localeCompare(vala.value);
        }
      }
    }

    this.category.entries = this.category.entries.sort(sortFunction)
  }

  categoryClicked(){
    if(this.noCollapse){
      this.isSelected = true;
      this.categoryChildren.forEach(sub => sub.deselect());
    }

    this.onCategoryClicked.emit([]);
  }

  get isVisible(): boolean {
    return this.category.isShown();
  }

  onChildCategoryClicked(clickedCategoryIndices:number[] = [], clickedChild:number){
    if(this.noCollapse){
      this.isSelected = true;
      this.categoryChildren.forEach((sub, i) => {
        if(i !== clickedChild) sub.deselect();
      });
    }

    clickedCategoryIndices.push(clickedChild);
    this.onCategoryClicked.emit(clickedCategoryIndices);
  }

  download(){
    this.onDownload.emit(this.category.getAllChildEntryIds());
  }

  deselect(){
    this.isSelected = false;
    this.categoryChildren.forEach(sub => sub.deselect());
  }

  entryChanged(changeData: string | any, changeType:WSEntryType, newEntry: Entry, method?:WSMethods){
    this.onEntryChanged.emit({changeData: changeData, changeType: changeType, method: method, newEntry: newEntry});
  }

  fileAction(formData:FormData, action:WSMethods, callbackFn:(arg: any) => void){
    this.onFileAction.emit({formData: formData, action: action, callbackFn:callbackFn});
  }

  @Input()
  set filterState(filter: TFilterValues){
    if(this.category != undefined && this.category.entries != undefined){
      if(filter != undefined){
        this.__filterState = filter;
      }

      if(this.__filterState != undefined){
        this.applyFilterToEntries(filter);
      }
      this.changeRef.detectChanges();
    }
  }

  get filterState() {
    return this.__filterState;
  }

  applyFilterToCategory(filter: TFilterValues){
    this.filterState = filter;
    this.applyFilterToEntries(filter);//.map(e => Object.assign({}, e) as Entry));

    if(this.categoryChildren)
      this.categoryChildren.forEach(cc => cc.applyFilterToCategory(filter));
  }

  applyFilterToEntries(filter:TFilterValues){
    this.category.entries = this.category.entries.map(e => e.visible(true));

    if(this.filterState !== undefined){
      if(this.filterState.isDue)
        this.category.entries = this.category.entries.map(entry => entry.visible(Entry.isDueDate(entry.endDate) && this.isStatusRelevant(entry, [TEntryStatus.feedback, TEntryStatus.opened]) && entry.isVisible ));

      if(this.filterState.isOverdue)
        this.category.entries = this.category.entries.map(entry => entry.visible(Entry.isOverdueDate(entry.endDate) && this.isStatusRelevant(entry, [TEntryStatus.feedback, TEntryStatus.opened])  && entry.isVisible ));

      if(this.filterState.searchText != undefined && this.filterState.searchText != "")
        this.category.entries = this.category.entries.map(entry => entry.visible(entry.description.toLowerCase().includes(this.filterState.searchText.toLowerCase())  && entry.isVisible ));

      if(this.filterState.onlyForStatus != "" && this.filterState.onlyForStatus != undefined)
        this.category.entries = this.category.entries.map(entry => entry.visible(entry.status == this.filterState.onlyForStatus  && entry.isVisible ));

      if(this.filterState.onlyForResponsible != "" && this.filterState.onlyForResponsible != undefined)
        this.category.entries = this.category.entries.map(entry => entry.visible((entry.responsibles ? entry.responsibles.includes(this.filterState.onlyForResponsible) : false) &&  entry.isVisible));

      if(this.filterState.cust != undefined){
        for(let fname in this.filterState.cust){
          if(this.filterState.cust[fname] !== undefined) { // meaning filter is resetted
            this.category.entries = this.category.entries.map(entry => {
              const idx = entry.fields.findIndex(v => v.text == fname);
              if(idx >=0 && entry.fields[idx] != null && entry.fields[idx] != undefined && entry.fields[idx].value != null && entry.fields[idx].value != undefined)
                return entry.visible((entry.fields[idx].value.includes(this.filterState.cust[fname])) && entry.isVisible);
              return entry.visible(false);
            });
          }
        }
      }
    }
  }

  isStatusRelevant(entry: Entry, relevantStates: TEntryStatus[]){
    const fstates = relevantStates.filter(status => entry.status === status);
    return fstates.length > 0;
  }

  toggleEntryEditMode(entryPos: number) {
    this.isEntryInEditMode[entryPos] = !this.isEntryInEditMode[entryPos];
  }
}

export type TFilterValues = {
  searchText: string,
  onlyForResponsible: string,
  isDue: boolean;
  isOverdue: boolean;
  onlyForStatus: string;
  cust : {[index:string]: any};
}
