import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/_services/user.service";
import * as bcrypt from "bcryptjs";
import { extractFieldFromJWT } from "src/app/_helper/JwtHelper";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  newPassword: string = "";
  verificationPassword: string = "";
  token: string = "";

  states = {
    passwordsDontMatch: false,
    passwordsEmpty: false,
    userNotFound: false,
    usageWrong: false,
    otherError: false,
    tokenInvalid: false,
  };

  setStateTrue(stateName: string) {
    for (let state in this.states) {
      this.states[state] = false;
    }

    this.states[stateName] = true;
  }

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((p) => {
      this.token = p.token;
      location.href.replace(this.token, "");
      if (this.isTokenExpired(this.token)) this.setStateTrue("tokenInvalid");
    });
  }

  isTokenExpired(token: string): boolean {
    const exp = Number(extractFieldFromJWT(token, "exp"));
    const now = new Date().getTime() / 1000;
    return exp <= now;
  }

  keydown(event) {
    if (event.key === "Enter") {
      this.changePassword();
    }
  }

  changePassword() {
    if (this.newPassword !== this.verificationPassword) {
      this.setStateTrue("passwordsDontMatch");
      return;
    }

    if (this.newPassword == "" || this.verificationPassword === "") {
      this.setStateTrue("passwordsEmpty");
      return;
    }

    bcrypt.hash(this.newPassword, 12).then((pw) => {
      this.userService.resetPassword(this.token, pw).subscribe(
        (s) => {
          this.router.navigate(["/"]);
        },
        (e) => {
          if (e.status === 404) this.setStateTrue("userNotFound");
          else if (e.status === 417) this.setStateTrue("usageWrong");
          else if (e.status === 401) this.setStateTrue("tokenInvalid");
          else this.setStateTrue("otherError");
        }
      );
    });
  }
}
