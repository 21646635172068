import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debug, group } from 'console';
import { RootedGroupList } from 'src/app/_core/Group';
import { PBCList } from 'src/app/_core/PBCList';
import { DefaultingMap } from 'src/app/_helper/DefaultingMap';
import { AuthorizationService } from 'src/app/_services/authorization.service';
import { PbcService } from 'src/app/_services/pbc.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'group-show',
  templateUrl: './group-show.component.html',
  styleUrls: ['./group-show.component.scss']
})
export class GroupShowComponent implements OnInit {
  @Input() lists: PBCList[];
  @Input() groups: RootedGroupList[];
  @Input() filteredListType: string;
  @Input() listsStatus: any;
  @Input() listsIsPartial:  {[index: string] : boolean};
  @Input() showArchived: boolean = false;
  @Input() isChild: boolean = false;

  @Output() onHovered: EventEmitter<boolean> = new EventEmitter<boolean>();

  _searchText: string = "";

  isChildHovered: DefaultingMap<string, boolean> = new DefaultingMap();
  isGroupVisible: {[index:string] : boolean} = {};

  constructor(public pbcService: PbcService, public authService: AuthorizationService, public userService: UserService) { }

  @Input() set searchText(searchText: string) {
    this._searchText = searchText;
  }

  get searchText() : string {
    return this._searchText;
  }

  ngOnInit() {
    this.groups.forEach(el => {
      this.isGroupVisible[el.group.name] = false;
    });
  }

  deleteList(listId: string): void {
    if (confirm("Wollen Sie die Liste wirklich löschen?"))
      this.pbcService.deleteById(listId);
  }

  copyList(listId: string) {
    this.pbcService.copyById(listId);
  }

  archive(listId: string) {
    this.pbcService.archive(listId);
  }

  unarchive(listId: string) {
    this.pbcService.unarchive(listId);
  }

  hasAnyChildren(item: RootedGroupList, lists: PBCList[]) : boolean {
    const inList = lists.find(el => el.description.toLowerCase().includes(this.searchText.toLowerCase()) || el.client.toLowerCase().includes(this.searchText.toLowerCase()));
    if(inList !== undefined) return true;

    if(item.children){
      for(let child of item.children) {
        const res = this.hasAnyChildren(child, child.lists);
        if(res) return true;
      }
    }

    return false;
  }
}
