import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import Commentary from 'src/app/_core/Commentary';
import { AuthorizationService } from 'src/app/_services/authorization.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'commentary-view',
  templateUrl: './commentary.component.html',
  styleUrls: ['./commentary.component.scss']
})
export class CommentaryComponent implements OnInit {

  @Input() comments:Commentary[];
  @Input() deactivated: boolean = false;
  @Output() onPost: EventEmitter<Commentary> = new EventEmitter<Commentary>();
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();

  commentInput:string;
  locale: string = "";

  constructor(public userService:UserService, public authService:AuthorizationService, @Inject(LOCALE_ID) locale:string) {
    this.locale = locale;
  }

  ngOnInit() {
  }

  postComment(event = undefined){
    if(this.commentInput !== "" && this.commentInput !== undefined){
      this.onPost.emit({text: this.commentInput, timestamp: new Date().getTime(), user: this.userService.user.name })
      this.commentInput = "";
    }
    if(event != undefined)
      event.preventDefault();
  }

  deleteComment(index:number){
    this.onDelete.emit(index);
  }
}
