import { TranslateService } from "@ngx-translate/core";
import { EmptyError } from "rxjs";
import Commentary from "./Commentary";
import { CustomEntryField } from "./CustomEntryField";
import { EntryTemplate } from "./EntryTemplate";
import { UploadedFile } from "./UploadedFile";

export class Entry extends EntryTemplate {
  modificationDate: Date; // also creation date
  description: string;
  comment: string;
  startDate: Date;
  receivedDate: Date;
  endDate: Date;
  responsibles: (string | {id: string, displayName: string})[];
  status: TEntryStatus = TEntryStatus.opened;
  userComment: Commentary[] = [];
  files: UploadedFile[] = [];
  internalID: string;
  internalIndex: number[];
  isVisible: boolean = true;

  constructor(startDate:Date = undefined ,endDate:Date = undefined){
    super();
    this.startDate = startDate;
    this.endDate = endDate;
  }

  isEmpty(): boolean {
    return (this.description == undefined || this.description == "") &&
           (this.startDate == undefined || this.startDate == undefined) &&
           (this.receivedDate == undefined || this.receivedDate == undefined) &&
           (this.endDate == undefined || this.endDate == undefined) &&
           (this.comment == undefined || this.comment == "") &&
           (this.responsibles == undefined || this.responsibles.length === 0) &&
           (this.userComment == undefined || this.userComment.length === 0);
  }

  // for chaining
  visible(visibility: boolean) : Entry {
    this.isVisible = visibility;
    return this;
  }

  applyTemplate(e?:EntryTemplate) : Entry {
    this.fields = e.fields;
    this.type = e.type;
    this.showCommentary = e.showCommentary;
    this.showFileUpload = e.showFileUpload;
    this.showResponsibles = e.showResponsibles;

    return this;
  }

  static createFromTemplate(e?:EntryTemplate) : Entry {
    if(e == undefined) e = new EntryTemplate();
    const entry = new Entry();
    entry.type = e.type;
    entry.showCommentary = e.showCommentary;
    entry.showFileUpload = e.showFileUpload;
    entry.showResponsibles = e.showResponsibles;
    entry.fields = e.fields.map(f => Object.assign({}, f));

    return entry;
  }

  static fromJSON(source): Entry {
    const entry = new Entry();
    entry.internalID = source.internalID;
    entry.modificationDate = source.modificationDate;
    entry.description = source.description;
    entry.comment = source.comment;
    entry.startDate = source.startDate && new Date(source.startDate);
    entry.receivedDate = source.receivedDate && new Date(source.receivedDate);
    entry.endDate = source.endDate && new Date(source.endDate);
    if(typeof source.responsibles === 'string')
      entry.responsibles = [source.responsibles];
    else {
      entry.responsibles = source.responsibles;
    }
    entry.status = source.status;
    entry.userComment = source.userComment;
    entry.files = source.files;
    entry.type = source.type;
    entry.showResponsibles = source.showResponsibles == undefined ? true :  source.showResponsibles;
    entry.showCommentary = source.showCommentary == undefined ? true :  source.showCommentary;
    entry.showFileUpload = source.showFileUpload == undefined ? true :  source.showFileUpload;
    entry.showUserCommentary = source.showUserCommentary == undefined ? true :  source.showUserCommentary;
    entry.showStartDate = source.showStartDate == undefined ? true :  source.showStartDate;
    entry.showEndDate = source.showEndDate == undefined ? true :  source.showEndDate;
    entry.showStatusChangeDate = source.showStatusChangeDate == undefined ? true :  source.showStatusChangeDate;
    entry.showDescription = source.showDescription == undefined ? true :  source.showDescription;
    entry.showStatus = source.showStatus == undefined ? true :  source.showStatus;

    if(source.fields != undefined){
      entry.fields = source.fields.map(CustomEntryField.fromJSON);
    }

    return entry;
  }

  static statusText( value:string, lang: string = "DE_de") : string {
    switch(value){
      case TEntryStatus.opened: return "STATES.OPENED";
      case TEntryStatus.closed: return "STATES.CLOSED";
      case TEntryStatus.not_needed: return "STATES.NOT_NEEDED";
      case TEntryStatus.accepted: return "STATES.ACCEPTED";
      case TEntryStatus.for_download: return "STATES.FOR_DOWNLOAD";
      case TEntryStatus.feedback: return "STATES.FEEDBACK";
      case TEntryStatus.exceeded: return "STATES.EXCEEDED";
      case TEntryStatus.due: return "STATES.DUE";
    }
  }

  statusText(lang:string = "DE_de") : string {
    return Entry.statusText(this.status, lang);
  }

  relativeIndex(): number {
    return this.internalIndex.slice(-1)[0];
  }

  static isDueDate(date:Date) : boolean {
    if(date == undefined || date == null)
      return false;
    const today = new Date();
    today.setDate(today.getDate()+7);
    const diff = (date.getTime() - today.getTime())/(1000*60*60*24);
    if(diff >= -7 && diff < 0)
      return true;

    return false;
  }

  static isOverdueDate(date: Date) : boolean {
    if(date == undefined || date == null)
      return false;
    const diff: number = (date.getTime() - (new Date().getTime()))/(1000*60*60*24);
    if(diff < 0)
      return true;

    return false;
  }
}

export type TEntryRight = { user_id: string, entry_id: string, state: boolean };

export enum TEntryStatus {
  opened = "open",
  closed = "closed",
  not_needed = "not-needed",
  accepted = "accepted",
  feedback = "feedback",
  for_download = "for-download",
  exceeded = "exceeded",
  due = "due"
}


export enum WSEntryType {
  ONE = "entry",
  STATE = "entry-state",
  COMMENT = "entry-comment",
  FILE = "entry-file",
  CUSTOMFIELD = "entry-custom-field",
  IMPORT = "entries-import"
}
