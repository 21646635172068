import { Location } from "@angular/common";
import {
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  ActivatedRoute,
  NavigationStart,
  Router,
  RouterModule,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { dir } from "console";
import { Observable, Subject, Subscription } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { SharedModule } from "../shared/Shared.module";
import { WSPBCListType } from "../_core/AuthObjects";
import { Category, TInsertionPosition } from "../_core/Category";
import { Entry } from "../_core/Entry";
import { EntryTemplate } from "../_core/EntryTemplate";
import { ListTemplate } from "../_core/ListTemplate";
import { PBCList, TListRight } from "../_core/PBCList";
import UserData from "../_core/UserData";
import { isEmptyString } from "../_helper/Helpers";
import { AuthorizationService } from "../_services/authorization.service";
import { FileController } from "../_services/file.controller";
import { FileService } from "../_services/file.service";
import {
  ListLockService,
  WSListLockType,
} from "../_services/list-lock.service";
import { PbcService } from "../_services/pbc.service";
import { UserService } from "../_services/user.service";
import { WebSocketService, WSMethods } from "../_services/web-socket.service";
import { AppState } from "../_store/app.state";
import {
  EntryTemplateActions,
  EntryTemplateSelector,
} from "../_store/entry-template";
import { ListTemplateActions } from "../_store/list-template";
import { ListTemplateSelector } from "../_store/list-template/list-template.selectors";
import { CategoryEditCreateComponent } from "./category-edit-create/category-edit-create.component";
import { EntryEditCreateDialogComponent } from "./entry-edit-create-dialog/entry-edit-create-dialog.component";
import { EntryEditCreateComponent } from "./entry-edit-create/entry-edit-create.component";
import { EntriesMoveService } from "./services/entries-move.service";
import { TocEditCreateComponent } from "./toc-edit-create/toc-edit-create.component";
import { EventEmitter } from "events";
import { TranslateService } from "@ngx-translate/core";
import {
  NotifierIcons,
  NotifierService,
} from "../_uicomponents/notifier/notifier-service";

@Component({
  selector: "app-list-edit-create",
  templateUrl: "./list-edit-create.component.html",
  styleUrls: ["./list-edit-create.component.scss"],
})
export class ListEditCreateComponent implements OnInit, OnDestroy {
  @ViewChildren(TocEditCreateComponent)
  viewCategory: TocEditCreateComponent[];
  @ViewChild("saveButton", { static: false }) saveButton: any;

  stringify = JSON.stringify;

  list: PBCList;
  defaultEntryTemplate: EntryTemplate;

  clients: string[];
  filteredClients: Observable<string[]>;
  responsibles: UserData[];
  listAuthorizations: string[];
  isDownloading: boolean = false;

  inputClients = new FormControl();
  selectedCategory: Category;
  selectedCategoryIndex: string;
  isDirty = false;
  presetStartDate: Date;
  presetEndDate: Date;
  categoriesHovered: boolean = false;
  canAddNewClient: boolean = false;

  loadedData: { list: boolean; responsibles: boolean; permissions: boolean } = {
    permissions: false,
    responsibles: false,
    list: false,
  };

  dragHoverIdx: [number[], TInsertionPosition] = [[], "after"];
  componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  uiState: {
    isSaving: boolean;
  } = {
    isSaving: false,
  };

  dataLoadState = {
    onDataLoaded: new Subject(),
    wasLoaded: false,
  };

  responseCounter: number = 0;

  listTemplates$: Observable<ListTemplate[]>;
  entryTemplates$: Observable<EntryTemplate[]>;

  constructor(
    private socket: WebSocketService,
    private pbcService: PbcService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthorizationService,
    private fileService: FileService,
    private entriesMoveService: EntriesMoveService,
    private store: Store<AppState>,
    private listLockService: ListLockService,
    private location: Location,
    private tr: TranslateService,
    private notifService: NotifierService
  ) {
    this.socket.message((e) => {
      let message = JSON.parse(e.data);
      const { data, info, __sender__ } = message;

      this.handleResponseCounting(info);

      if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.CLIENTS_ALL
      ) {
        this.clients = message.data;
        this.addClientInputFilters();
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.ONE
      ) {
        if (data.isPartialList != undefined && data.isPartialList === true) {
          location.back();
        }

        if (data.list != undefined) {
          this.list = PBCList.fromJSON(data.list);
          this.canAddNewClient = data.canAddNewClient;
        } else this.list = PBCList.fromJSON(data);

        if (this.list != undefined && this.list.categories.length > 0) {
          this.route.params
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((params) => {
              if (params.categoryIndex)
                this.categorySelected(params.categoryIndex.replace(/,/g, "."));
            });
        }

        if (this.list.client != "" && this.list.client != undefined)
          this.pbcService.getResponsiblesForClient(this.list.client);

        if (this.list == undefined) {
          console.error(
            "Custom Error: List should be defined but is undefined"
          );
          console.error("Response Data from GET ONE PBC", data);
        }

        pbcService.getEntryRights(this.list.id);
        pbcService.getListRights(this.list.id);
        this.getEntryTemplates();
        this.loadedData.list = true;
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.RESPONSIBLES
      ) {
        this.responsibles = Array.from(message.data).map(UserData.fromJSON);
        this.loadedData.responsibles = true;
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.ENTRY_RIGHTS
      ) {
        this.loadedData.permissions = true;
      } else if (
        (info.__method == WSMethods.POST || info.__method == WSMethods.PUT) &&
        info.__type == WSPBCListType.ONE
      ) {
        this.list = PBCList.fromJSON(message.data);
        this.pbcService.putListRights(this.list.id, this.changedListRights);
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.LIST_RIGHTS
      ) {
        let userList: { list_id: string; user_id: string }[] = data;
        if (this.list != undefined && this.list.id !== undefined)
          this.listAuthorizations = userList
            .filter((u) => u.list_id == this.list.id)
            .map((u) => u.user_id);
      } else if (
        info.__method == WSMethods.PUT &&
        info.__type == WSPBCListType.LIST_RIGHTS
      ) {
        this.pbcService.getListRights(this.list.id);
      }

      this.checkSaveState();
      this.checkLoadState();
    });

    this.dataLoadState.onDataLoaded
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((_) => (this.dataLoadState.wasLoaded = true));

    router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe({
      next: this.promptOnNavigation(this),
    });

    this.entriesMoveService.onMoveBegin
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(this.moveEntries);
  }

  ngOnDestroy(): void {
    this.listLockService.stopHeartbeat();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.dataLoadState.onDataLoaded
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((_) => {
        this.route.params
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((params) => {
            if (params.categoryIndex)
              this.categorySelected(params.categoryIndex.replace(/,/g, "."));
            else this.categorySelected("0");
          });
      });

    this.pbcService.getAllClients();
    this.store.dispatch(ListTemplateActions.getAll());
    this.listTemplates$ = this.store.select(ListTemplateSelector.all);

    this.store.dispatch(EntryTemplateActions.getAll());
    this.entryTemplates$ = this.store.select(EntryTemplateSelector.all);

    this.route.params
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        if (params.id) {
          this.startHeartbeat(params.id);
        } else this.makeNewList();
      });

    window.onkeydown = (ev: KeyboardEvent): any => {
      if (ev.ctrlKey == true && (ev.key === "S" || ev.key === "s")) {
        this.saveButton._elementRef.nativeElement.click();
        ev.preventDefault();
      }
    };
  }

  heartbeatSub: Subscription;
  startHeartbeat = (listId) => {
    this.heartbeatSub = this.listLockService.event.subscribe((obs) => {
      if (obs.type === WSListLockType.LOCK_EDIT_LIST && obs.status === "OK")
        this.pbcService.getById(listId);
      else if (
        obs.type === WSListLockType.LOCK_EDIT_LIST &&
        obs.status === "LOCKED"
      ) {
        alert(
          `Die Bearbeitung ist seit ${obs.value.locked_since} durch ${obs.value.user_name} gesperrt.`
        );
        this.location.back();
      } else if (
        obs.type === WSListLockType.UNLOCK_EDIT_LIST &&
        obs.status === "OK"
      ) {
        this.heartbeatSub.unsubscribe();
      }
    });

    this.listLockService.startHeartbeat(listId);
  };

  moveEntries = (entries: Entry[]) => {
    this.selectedCategory.entries.push(...entries);
    entries.forEach((el) => {
      const target = el.internalIndex.slice(0, -1);
      this.list.removeEntryAt(el.internalID, target);
    });
    this.list.setInternalIndex();
    this.isDirty = true;
  };

  deleteEntry(entryId: string, target: string[]) {
    this.list.removeEntryAt(entryId, target.map(Number));
    this.isDirty = true;
  }

  checkSaveState() {
    if (this.responseCounter == 0) this.uiState.isSaving = false;
    else this.uiState.isSaving = true;
  }

  checkLoadState() {
    if (
      this.loadedData.list &&
      this.loadedData.permissions &&
      this.loadedData.responsibles &&
      !this.dataLoadState.wasLoaded
    )
      this.dataLoadState.onDataLoaded.next();
    this.dataLoadState.onDataLoaded.complete();
  }

  handleResponseCounting(info: any) {
    console.log(info, this.responseCounter);
    if (
      (info.__method == WSMethods.GET || info.__method == WSMethods.PUT) &&
      info.__type == WSPBCListType.LIST_RIGHTS
    )
      this.decrementResponseCounter();
    else if (
      info.__method == WSMethods.PUT &&
      info.__type == WSPBCListType.ENTRY_RIGHTS
    )
      this.decrementResponseCounter();
    else if (
      (info.__method == WSMethods.POST || info.__method == WSMethods.PUT) &&
      info.__type == WSPBCListType.ONE
    )
      this.decrementResponseCounter();
  }

  incrementResponseCounter(num: number = 1) {
    this.responseCounter += num;
  }

  decrementResponseCounter(num: number = 1) {
    if (this.responseCounter > 0) this.responseCounter -= num;
  }

  makeNewList() {
    this.list = new PBCList();
    this.pbcService.getAmountClients().subscribe(success => this.canAddNewClient = success.canAddNewClient);
    this.list.categories.push(
      new Category().withDescription(this.tr.instant("CATEGORY_EDIT.NEW"))
    );
    this.categorySelected("0");
    this.list.client = "";
    this.getEntryTemplates();
    this.list.setInternalIndex();
    this.loadedData.list = true;
    this.loadedData.permissions = true;
    this.loadedData.responsibles = true;
  }

  async listTypeChanged(newTemplateId: string) {
    // If everything would be in ngrx there would be no subscription
    this.store
      .select(ListTemplateSelector.getById, { id: newTemplateId })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((listTemplate) => {
        this.list.type = listTemplate.type;
        this.list.defaultEntryType = listTemplate.defaultEntryType;
        this.getEntryTemplates();
        this.isDirty = true;
      });
  }

  getEntryTemplates() {
    if (isEmptyString(this.list.defaultEntryType))
      this.list.defaultEntryType = "PbC Standard";

    this.store
      .select(EntryTemplateSelector.getByType, {
        type: this.list.defaultEntryType,
      })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (success: EntryTemplate) => {
          this.defaultEntryTemplate = success;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  promptOnNavigation(comp: ListEditCreateComponent) {
    return (event) => {
      if (event instanceof NavigationStart) {
        if (
          comp.isDirty &&
          confirm(
            "Sie haben ungespeicherte Änderungen. Wollen Sie diese Speichern (Ok) oder Verwerfen (Abbrechen)?"
          )
        ) {
          comp.savePBCList();
        }
      }
    };
  }

  get isDataLoaded(): boolean {
    return (
      this.loadedData.list &&
      this.loadedData.permissions &&
      this.loadedData.responsibles
    );
  }

  addClientInputFilters() {
    this.filteredClients = this.inputClients.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value, this.clients))
    );
  }

  private _filter(value: string, srcList: string[]): string[] {
    if (value != undefined) {
      const filterValue = value.toLowerCase();
      return srcList.filter((el) => el.toLowerCase().includes(filterValue));
    }

    return srcList;
  }

  categorySelected(index: string) {
    const indices = index.split(".");

    let first = this.list.categories[indices.splice(0, 1)[0]];
    this.selectedCategory = indices.reduce(
      (prev, el) => prev.categories[el],
      first
    );
    this.selectedCategoryIndex = index;
    // timeout so that the value in this.selectedCategoryIndex will be propagated to category-edit-create before the state is updated
    // (timeout puts the function later in the event loop, therefore zone is triggered before the callback)
    setTimeout(() => this.entriesMoveService.updateState(), 5);
  }

  changeEntries(entries: Entry[]) {
    this.list.replaceAllEntries(
      this.selectedCategoryIndex.split(".").map((_) => Number(_)),
      entries
    );
    this.list.setInternalIndex();
  }

  clientSelectionChanged(client: string) {
    this.list.client = client;
    this.pbcService.getResponsiblesForClient(client);
    this.isDirty = true;
  }

  changeDatesForAll() {
    if (
      isNullOrUndefined(this.presetEndDate) &&
      !isNullOrUndefined(this.presetStartDate)
    )
      this.list.setDateForAll(this.presetStartDate, "startDate");
    else if (
      !isNullOrUndefined(this.presetEndDate) &&
      isNullOrUndefined(this.presetStartDate)
    )
      this.list.setDateForAll(this.presetEndDate, "endDate");
    else if (
      !isNullOrUndefined(this.presetEndDate) &&
      !isNullOrUndefined(this.presetStartDate)
    )
      this.list.setDatesForAll(this.presetStartDate, this.presetEndDate);
    this.isDirty = true;
  }

  savePBCList() {
    if (this.list.id != undefined && this.list.id != "" && this.isDirty) {
      this.uiState.isSaving = true;
      this.pbcService.put(this.list);
      this.incrementResponseCounter();
      //is called when pbcList is put this.pbcService.putEntryRights(this.list.id, this.entryPermissions);
      //this.pbcService.putListRights(this.list.id, this.changedListRights);
    } else if (this.isDirty) {
      this.uiState.isSaving = true;
      this.pbcService.post(this.list);
      this.incrementResponseCounter(3);
    }

    this.isDirty = false;
  }

  addCategory() {
    if (this.list.categories == undefined) {
      this.list.categories = [];
    }

    this.list.categories.splice(
      this.list.categories.length,
      0,
      new Category().withDescription(this.tr.instant("CATEGORY_EDIT.NEW"))
    );
    this.isDirty = true;
  }

  categoryTreeDragHover([container, internalIndex, position]: [
    Element,
    number[],
    TInsertionPosition
  ]) {
    if (container) {
      this.dragHoverIdx = [internalIndex, position];
    }
  }

  categoryTreeDropped(internalIndexDropped: number[]) {
    const targetIndex = this.dragHoverIdx[0];
    const targetCategory = Category.getAt(targetIndex, this.list.categories);

    if (!targetCategory) return;

    const targetPosition = this.dragHoverIdx[1];

    const droppedCategory = Category.removeAt(
      internalIndexDropped,
      this.list.categories
    );
    this.list.setInternalIndex();
    Category.insertAt(
      targetCategory.internalIndex,
      droppedCategory,
      targetPosition,
      this.list.categories
    );
    // TODO: if returing false the removal must be undone
    this.list.setInternalIndex();
    this.dragHoverIdx = [[], "after"];
    this.isDirty = true;
  }

  drop(event) {
    console.log(event);
  }

  get showListNumbers(): boolean {
    return this.list.customListSettings["showListNumbers"] == undefined ||
      this.list.customListSettings["showListNumbers"] == "false"
      ? false
      : true;
  }

  set showListNumbers(show: boolean) {
    this.list.customListSettings["showListNumbers"] = show.toString();
    this.isDirty = true;
  }

  changedListRights: TListRight[] = [];
  responsibleChanged(user_id: string, state: boolean) {
    if (this.changedListRights == undefined) this.changedListRights = [];

    if (!state)
      this.listAuthorizations = this.listAuthorizations.filter(
        (v) => v !== user_id
      );
    else this.listAuthorizations.push(user_id);

    this.changedListRights = this.responsibles.map((resp) => {
      if (this.listAuthorizations.includes(resp.user_id)) {
        return { user_id: resp.user_id, list_id: this.list.id, state: state };
      } else {
        return { user_id: resp.user_id, list_id: this.list.id, state: false };
      }
    });

    this.isDirty = true;
  }

  isResponsible(user_id: string): boolean {
    return (
      this.listAuthorizations != undefined &&
      this.listAuthorizations.find((v) => v == user_id) != undefined
    );
  }

  fileAction(
    formData: FormData,
    action: WSMethods,
    callbackFn: (arg: any) => void
  ) {
    const fc = new FileController(this.fileService);
    let fileActionPromise = fc.fileAction(
      formData,
      action,
      callbackFn,
      this.list
    );
    fileActionPromise
      .then((success) => {})
      .catch((error) => {
        this.notifService.error(
          "Http" + error.status,
          "Beim hochladen der Datei ist ein Fehler aufgetreten: " +
            error.error.msg,
          NotifierIcons.EXCALAMATION,
          5000
        );
      });
  }

  uploadFile(formData: FormData): Observable<any> {
    formData.append("list_id", this.list.id);
    return this.fileService.uploadWithProgress(formData);
  }

  deleteFile(formData: FormData) {
    formData.append("list_id", this.list.id);
    return this.fileService.deleteFile(formData);
  }

  downloadFile(formData: FormData) {
    formData.append("list_id", this.list.id);
    return this.fileService.downloadFile(formData);
  }

  openDownloadDialog(
    data: any,
    filename: string,
    typee = "application/octet-stream"
  ) {
    const blob = new Blob([data], { type: typee });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }

  downloadFilesAsZip(entry_id_list: string[] = undefined) {
    this.isDownloading = true;
    const formData = new FormData();
    formData.append("list_id", this.list.id);

    if (entry_id_list !== undefined && entry_id_list.length > 0) {
      entry_id_list.forEach((e) =>
        formData.append("entry_id_list", e.toString())
      );
    }

    this.fileService
      .downloadFilesAsZip(formData)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (success) => {
          this.isDownloading = false;
          this.openDownloadDialog(
            success,
            `${this.list.client}.zip`,
            "application/zip"
          );
        },
        () => (this.isDownloading = false),
        () => (this.isDownloading = false)
      );
  }

  categoryChanged(category: Category, pos: number) {
    if (pos !== undefined) {
      this.list.categories[pos] = category;
    } else this.list.categories[this.selectedCategoryIndex] = category;

    this.isDirty = true;
  }
}

const ROUTES = [
  {
    path: "list/new",
    component: ListEditCreateComponent,
    canActivate: [UserService],
  },
  {
    path: "list/edit/:id",
    component: ListEditCreateComponent,
    canActivate: [UserService],
  },
  {
    path: "list/edit/:id/:categoryIndex",
    component: ListEditCreateComponent,
    canActivate: [UserService],
  },
];

@NgModule({
  declarations: [
    ListEditCreateComponent,
    TocEditCreateComponent,
    EntryEditCreateComponent,
    EntryEditCreateDialogComponent,
    CategoryEditCreateComponent,
  ],
  imports: [RouterModule, RouterModule.forRoot(ROUTES), SharedModule],
  providers: [UserService, EntriesMoveService],
  exports: [
    RouterModule,
    EntryEditCreateComponent,
    EntryEditCreateDialogComponent,
    ListEditCreateComponent,
    CategoryEditCreateComponent,
  ],
  entryComponents: [EntryEditCreateDialogComponent],
})
export class ListEditCreateModule {}
