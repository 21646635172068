import { Pipe, PipeTransform } from '@angular/core';
import { timingSafeEqual } from 'crypto';

@Pipe({name: 'searchInArray'})
export class SearchInArray implements PipeTransform {
  transform(value: any, filter: any): any {
    if(typeof filter === 'string') filter = { vlaue: filter };
    if(filter.value == undefined || filter.value === "") return value;

    if(filter.value instanceof Array) return this.checkForArrayValue(value, filter);
    else return this.checkForSimpleValue(value, filter);
  }

  checkForSimpleValue(value:any, filter:any){
    filter.value = filter.value == undefined ? "" : filter.value.toString().toLowerCase();

    if(filter.attribute instanceof Array && filter.multipleAttributes) {
      return value == undefined ? [] : value.filter( v => filter.attribute.some(attr => v[attr] != null && v[attr].toString().toLowerCase().match(new RegExp(".*"+filter.value+".*"))));
    }
    else if(filter.attribute instanceof Array){
      return value == undefined ? [] : value.filter( v => { return this.extractAttributeValue(filter.attribute, v).toString().toLowerCase().match(new RegExp(".*"+filter.value+".*")); });
    }
    else if(filter.attribute === '' || filter.attribute === undefined)
      return value == undefined ? [] : value.filter( v => { return v.toString().toLowerCase().match(new RegExp(".*"+filter.value+".*")); });
    else
      return value == undefined ? [] : value.filter( v => { return v[filter.attribute] != undefined ? v[filter.attribute].toString().toLowerCase().match(new RegExp(".*"+filter.value+".*")) : false; });
  }

  checkForArrayValue(value:any, filter: { value: any[], attribute: string | string[], multipleAttributes: boolean}){
    filter.value = filter.value == undefined ? [] : filter.value.map(e => e.toLowerCase());

    if(filter.attribute instanceof Array && filter.multipleAttributes) {
      return value == undefined ? [] : value.filter( v => filter.value.some( sf =>  (filter.attribute as Array<string>).some(attr => v[attr] !== null && v[attr].toString().toLowerCase().match(new RegExp(".*"+sf+".*")))));
    }
    else if(filter.attribute instanceof Array)
      return value == undefined ? [] : value.filter( v =>  filter.value.some(e => this.extractAttributeValue(filter.attribute as string[], v).toString().toLowerCase().match(new RegExp(".*"+e+".*"))));
    else
      return value == undefined ? [] : value.filter( v => filter.value.some(e => v[filter.attribute as string] != null && v[filter.attribute as string].toString().toLowerCase().match(new RegExp(".*"+e+".*"))));
  }

  extractAttributeValue(attributeArray:Array<string>, object){
    if(attributeArray.length > 1){
      let newObj = object[attributeArray.splice(0, 1)[0]];
      return this.extractAttributeValue(attributeArray, newObj);
    }
    else return object[attributeArray[0]];
  }
}
