import { TranslateService } from "@ngx-translate/core";

/**
 * IMPORTANT: This must stay a singleton. Don't use DI with Injectable!
 */
export class LanguageSwitcherService {
  static instance: LanguageSwitcherService;
  public lang: string = "en";

  private constructor() {
    const localLang = localStorage.getItem("lang");
    if (localLang != undefined) this.lang = localLang;
    else this.lang = this.getLang(navigator.language, "en");
  }

  static get() {
    if (LanguageSwitcherService.instance === undefined) {
      LanguageSwitcherService.instance = new LanguageSwitcherService();
    }

    return LanguageSwitcherService.instance;
  }

  static lang() {
    return LanguageSwitcherService.get().lang;
  }

  getLang(lang: string, defLang = "en"): string {
    if (this.defaultLang("en", lang)) return "en";
    else if (this.defaultLang("de", lang)) return "de";
    else if (this.defaultLang("es", lang)) return "es";
    else return defLang;
  }

  defaultLang(targetLang: string, sourceLang: string): boolean {
    if (
      sourceLang.startsWith(targetLang + "_") ||
      sourceLang.startsWith(targetLang + "-") ||
      sourceLang === targetLang
    )
      return true;
    return false;
  }

  set(lang: string, tr: TranslateService) {
    if (lang == "" || lang == undefined) {
      this.lang = this.getLang(navigator.language);
      localStorage.removeItem("lang");
    } else {
      this.lang = this.getLang(lang);
      localStorage.setItem("lang", this.lang);
    }

    tr.use(this.lang);
  }
}
