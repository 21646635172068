import { isArrayEmpty } from "../_helper/Helpers";
import { Entry, TEntryStatus } from "./Entry";

export class Category {
  description: string;
  categories: Category[] = [];
  entries: Entry[] = [];
  internalIndex: number[] = [];

  static fromJSON(source): Category {
    const category = new Category();
    category.description = source.description;
    if (source.categories != undefined && source.categories.length > 0)
      category.categories = source.categories.map(Category.fromJSON);
    else category.categories = [];

    if (source.entries != undefined)
      category.entries = source.entries.map(Entry.fromJSON);

    return category;
  }

  getAllChildEntryIds(): string[] {
    const list = !isArrayEmpty(this.entries)
      ? this.entries.map((entry) => entry.internalID)
      : [];

    return !isArrayEmpty(this.categories)
      ? this.categories
          .map((category) => category.getAllChildEntryIds())
          .reduce((prev, curr) => {
            return prev.concat(curr);
          }, list)
      : list;
  }

  areEntriesSameState(): boolean {
    if (this.entries != undefined && this.entries.length > 0) {
      const firstState = this.entries[0].status;
      return this.entries.every((e) => e.status == firstState);
    }
    return false;
  }

  withDescription(description: string): Category {
    this.description = description;
    return this;
  }

  hasVisibleEntries(): boolean {
    return this.entries.some((e) => e.isVisible);
  }

  isShown() {
    return (
      this.hasVisibleEntries() || this.categories.some((cat) => cat.isShown())
    );
  }

  determineStatus(
    filterByName: string = undefined
  ): { text: string; value: TEntryStatus; exceeded: boolean; due: boolean }[] {
    if (this.entries == undefined) return [];

    const ret = this.entries
      .map((entry) => {
        const retVal = {
          text:
            entry.status == null
              ? Entry.statusText(TEntryStatus.opened)
              : entry.statusText(),
          value: entry.status == null ? TEntryStatus.opened : entry.status,
          exceeded:
            (entry.status === TEntryStatus.opened ||
              entry.status === TEntryStatus.feedback) &&
            (entry.endDate == null || entry.endDate == undefined
              ? false
              : Entry.isOverdueDate(entry.endDate)),
          due:
            (entry.status === TEntryStatus.opened ||
              entry.status === TEntryStatus.feedback) &&
            (entry.endDate == null || entry.endDate == undefined
              ? false
              : Entry.isDueDate(entry.endDate)),
        };

        if (
          filterByName != undefined &&
          filterByName != "" &&
          entry.responsibles != null &&
          entry.responsibles.includes(filterByName)
        )
          return retVal;
        else if (filterByName === undefined || filterByName === "")
          return retVal;
        else return undefined;
      })
      .filter((entry) => entry != undefined);

    if (this.categories == undefined) return ret;
    else
      return ret.concat(
        this.categories
          .map((cat) => cat.determineStatus(filterByName))
          .reduce((prev, el) => prev.concat(el), [])
      );
  }

  setDatesForAll(start: Date, end: Date): Category {
    if (this.entries != undefined)
      this.entries = this.entries.map((e) => {
        e.startDate = start;
        e.endDate = end;
        return e;
      });

    if (this.categories != undefined)
      this.categories = this.categories.map((c) => {
        c.setDatesForAll(start, end);
        return c;
      });

    return this;
  }

  setDateForAll(date: Date, which: "startDate" | "endDate"): Category {
    if (this.entries != undefined)
      this.entries = this.entries.map((e) => {
        e[which] = date;
        return e;
      });

    if (this.categories != undefined)
      this.categories = this.categories.map((c) => {
        c.setDateForAll(date, which);
        return c;
      });

    return this;
  }

  setInternalIndex(indices: number[]): Category {
    this.internalIndex = indices;

    if (this.entries != undefined)
      this.entries = this.entries.map((e, i) => {
        e.internalIndex = indices.concat([i]);
        return e;
      });

    if (this.categories != undefined)
      this.categories = this.categories.map((c, i) =>
        c.setInternalIndex(indices.concat([i]))
      );

    return this;
  }

  relativeIndex(): number {
    return this.internalIndex.slice(-1)[0];
  }

  entryStatus():
    | TEntryStatus.accepted
    | TEntryStatus.opened
    | TEntryStatus.feedback {
    if (this.entries.length === 0 && this.categories.length === 0)
      return TEntryStatus.accepted;
    if (this.entries.length === 0) return TEntryStatus.accepted;

    const someNotOpen = this.entries.some(
      (c) => c.status !== TEntryStatus.opened
    );
    if (someNotOpen) {
      const allClosed = this.entries.every(
        (c) =>
          c.status == TEntryStatus.accepted ||
          c.status === TEntryStatus.closed ||
          c.status === TEntryStatus.not_needed
      );
      if (allClosed) return TEntryStatus.accepted;
      else return TEntryStatus.feedback;
    } else return TEntryStatus.opened;
  }

  childEntriesStatus():
    | TEntryStatus.accepted
    | TEntryStatus.opened
    | TEntryStatus.feedback {
    const entryStatus = this.entryStatus();
    if (entryStatus == TEntryStatus.feedback) {
      return entryStatus;
    } else if (this.categories.length === 0) {
      return entryStatus;
    } else if (entryStatus === TEntryStatus.accepted) {
      const allAccepted = this.categories.every(
        (c) => c.childEntriesStatus() === TEntryStatus.accepted
      );
      if (allAccepted) return TEntryStatus.accepted;
      else {
        const allOpen = this.categories.every(
          (c) => c.childEntriesStatus() === TEntryStatus.opened
        );
        return allOpen ? TEntryStatus.opened : TEntryStatus.feedback;
      }
    } else {
      // entryStatus === TEntryStatus.opened
      const allOpen = this.categories.every(
        (c) => c.childEntriesStatus() === TEntryStatus.opened
      );
      if (allOpen) return TEntryStatus.opened;
      else return TEntryStatus.feedback;
    }
  }

  findEntryPosition(entryId: string, ownPosition: number): number[] {
    for (let i = 0; i < this.entries.length; i++) {
      const foundEntry = this.entries.findIndex(
        (entry) => entry.internalID == entryId
      );
      if (foundEntry >= 0) {
        return [ownPosition, foundEntry];
      }
    }

    for (let i = 0; i < this.categories.length; i++) {
      const foundEntry = this.categories[i].findEntryPosition(entryId, i);
      if (foundEntry != undefined) return [ownPosition].concat(foundEntry);
    }

    return undefined;
  }

  listAllCategories(): Category[] {
    return this.categories.reduce((prev, el) => {
      prev.push(el);
      prev = prev.concat(el.listAllCategories());
      return prev;
    }, []);
  }

  replaceAllEntriesAt(indices: number[], entries: Entry[]): Category {
    const first = indices.shift();
    if (first == undefined) {
      this.entries = entries;
      return this;
    } else {
      return this.categories[first].replaceAllEntriesAt(indices, entries);
    }
  }

  appendAllEntriesAt(indices: number[], entries: Entry[]): Category {
    const first = indices.shift();
    if (first == undefined) {
      this.entries = this.entries.concat(entries);
      console.log(this.entries);

      return this;
    } else {
      return this.categories[first].appendAllEntriesAt(indices, entries);
    }
  }

  static getAt(internalIndex: number[], from: Category[]): Category {
    if (internalIndex.length > 1) {
      const nextIdx = internalIndex.shift();
      return this.getAt(internalIndex, from[nextIdx].categories);
    } else return from[internalIndex[0]];
  }

  static removeAt(internalIndex: number[], from: Category[]): Category {
    if (internalIndex.length > 1) {
      const nextIdx = internalIndex.shift();
      return Category.removeAt(internalIndex, from[nextIdx].categories);
    } else return from.splice(internalIndex[0], 1)[0];
  }

  static removeEntryAt(internalIndex: number[], internalID: string,from: Category[]) {
    if (internalIndex.length > 1) {
      const nextIdx = internalIndex.shift();
      Category.removeEntryAt(internalIndex, internalID, from[nextIdx].categories);
    } else {
      const lastIdx = internalIndex.shift();
      from[lastIdx].entries = from[lastIdx].entries.filter(el => el.internalID !== internalID);
    }
  }

  static insertAt(
    internalIndex: number[],
    category: Category,
    position: TInsertionPosition,
    into: Category[]
  ): boolean {
    if (internalIndex == undefined || internalIndex[0] == undefined)
      return false;
    if (internalIndex.length > 1) {
      const nextIdx = internalIndex.shift();
      Category.insertAt(
        internalIndex,
        category,
        position,
        into[nextIdx].categories
      );
    } else {
      if (position === "before") into.splice(internalIndex[0], 0, category);
      else if (position === "after")
        into.splice(internalIndex[0] + 1, 0, category);
      else if (position === "inside")
        into[internalIndex[0]].categories.push(category);
      return true;
    }
  }

  forEachEntry(callback: (entry: Entry) => void) {
    if (this.entries != undefined) this.entries.forEach(callback);

    if (this.categories != undefined)
      this.categories.forEach((category) => category.forEachEntry(callback));
  }

  makeTOCIndex(offset: number = 1): string {
    return this.internalIndex.map((i) => i + offset).join(".");
  }
}

export type TInsertionPosition = "before" | "inside" | "after";
