import { Observable } from "rxjs";
import { TEntryStatus } from "../_core/Entry";
import { PBCList } from "../_core/PBCList";
import { FileService } from "./file.service";
import { WSMethods } from "./web-socket.service";
import { log } from "util";

export class FileController {
  constructor(private fileService: FileService) {}

  async fileAction(
    formData: FormData,
    action: WSMethods,
    callbackFn: (arg: any) => void,
    list: PBCList
  ) {
    //this.addAndSortFormData(formData, entryPos);

    if (action == WSMethods.POST) {
      return new Promise((resolve, reject) =>
        this.uploadFile(formData, list).subscribe(
          (success) => {
            callbackFn(success);
            return resolve(success);
          },
          (error) => {
            return reject(error);
          }
        )
      );
    } else if (action == WSMethods.DELETE) {
      this.deleteFile(formData, list).subscribe((success) =>
        callbackFn(success)
      );
    } else if (action == WSMethods.GET && formData.get("file") != undefined) {
      return new Promise((resolve, _) => {
        this.downloadFile(formData, list).subscribe((success) => {
          let filename = JSON.parse(formData.get("file").toString()).name;
          this.openDownloadDialog(success, filename);
          resolve(true);
        });
      });
    } else if (action == WSMethods.GET && formData.get("files") != undefined) {
      return this.downloadFilesAsZip(
        [formData.get("entry_id").toString()],
        list
      );
    }
  }

  openDownloadDialog(
    data: any,
    filename: string,
    typee = "application/octet-stream"
  ) {
    const blob = new Blob([data], { type: typee });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }

  downloadFilesAsZip(
    entry_id_list: string[] = undefined,
    list: PBCList
  ): Promise<boolean> {
    return new Promise((resolve, _) => {
      const formData = new FormData();
      formData.append("list_id", list.id);

      if (entry_id_list !== undefined && entry_id_list.length > 0) {
        entry_id_list.forEach((e) =>
          formData.append("entry_id_list", e.toString())
        );
      }

      this.fileService.downloadFilesAsZip(formData).subscribe(
        (success) => {
          this.openDownloadDialog(
            success,
            `${list.client}.zip`,
            "application/zip"
          );

          return resolve(false);
        },
        () => resolve(false),
        () => resolve(false)
      );
    });
  }

  downloadFilesAsZipByState(list: PBCList): Promise<boolean> {
    return new Promise((resolve, _) => {
      const formData = new FormData();
      formData.append("list_id", list.id);

      this.fileService
        .downloadFilesAsZipByState(
          formData,
          TEntryStatus.for_download,
          TEntryStatus.accepted
        )
        .subscribe(
          (success) => {
            this.openDownloadDialog(
              success,
              `${list.client}.zip`,
              "application/zip"
            );
            return resolve(false);
          },
          () => resolve(false),
          () => resolve(false)
        );
    });
  }

  uploadFile(formData: FormData, list: PBCList): Observable<any> {
    formData.append("list_id", list.id);
    return this.fileService.uploadWithProgress(formData);
  }

  deleteFile(formData: FormData, list: PBCList) {
    formData.append("list_id", list.id);
    return this.fileService.deleteFile(formData);
  }

  downloadFile(formData: FormData, list: PBCList) {
    formData.append("list_id", list.id);
    return this.fileService.downloadFile(formData);
  }
}
