import { createReducer, on } from "@ngrx/store";
import { LocalConfig } from "src/app/_core/LocalConfig";
import { LocalConfigActions } from "./local-config.actions";

export interface LocalConfigState {
  localConfig: LocalConfig;
  status: "init" | "loading" | "success" | "error";
}

const initialState: LocalConfigState = {
  localConfig: new LocalConfig(),
  status: "init",
};

export const localConfigReducer = createReducer(
  initialState,
  on(LocalConfigActions.getAll, (state) => ({ ...state, status: "loading" })),
  on(LocalConfigActions.getAllSuccess, (state, { localConfig }) => ({
    ...state,
    localConfig: localConfig,
    status: "success",
  }))
);
