import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PbcService } from "../_services/pbc.service";
import { WebSocketService, WSMethods } from "../_services/web-socket.service";
import { UserService } from "../_services/user.service";
import { WSPBCListType } from "../_core/AuthObjects";
import { PBCList, TPBCListStatusMap } from "../_core/PBCList";
import { AuthorizationService } from "../_services/authorization.service";
import { IdxCollection } from "../_helper/Helpers";
import { Entry, TEntryStatus } from "../_core/Entry";
import { NotifierService } from "../_uicomponents/notifier/notifier-service";
import { Observable } from "rxjs";
import { LocalConfig, LocalConfigData } from "../_core/LocalConfig";
import { Store } from "@ngrx/store";
import { AppState } from "../_store/app.state";
import {
  LocalConfigActions,
  LocalConfigSelectors,
} from "../_store/local-config";
import { RootedGroupList, TRootedGroupList } from "../_core/Group";
import { split } from "cypress/types/lodash";
import { ChangeDetectionStrategy } from "@angular/compiler/src/compiler_facade_interface";

@Component({
  selector: "lists-show",
  templateUrl: "./lists-show.component.html",
  styleUrls: ["./lists-show.component.scss"],
})
export class ListsShowComponent implements OnInit {
  username: string;
  lists: PBCList[] = [];
  groupedLists: TRootedGroupList[] = [];
  listsStatus: {};
  listsIsPartial: { [index: string]: boolean } = {};
  searchText: string = "";
  showArchived = false;
  listsLoading: boolean = true;
  filteredListType: string = "";

  searchText$: Observable<string>;

  wsHandlers: {
    check: (message: any) => boolean;
    do: (message: any) => void;
  }[] = [
    {
      check: (message) =>
        message.info.__method == WSMethods.GET &&
        message.info.__type == WSPBCListType.ALL,
      do: (message) => this.onGetAllLists(message),
    },
    {
      check: (message) =>
        message.info.__method == WSMethods.DELETE &&
        message.info.__type == WSPBCListType.ONE,
      do: (message) =>
        (this.lists = this.lists.filter((list) => list.id !== message.data.id)),
    },
    {
      check: (message) =>
        message.info.__method == WSMethods.PUT &&
        (message.info.__type == WSPBCListType.ARCHIVE ||
          message.info.__type == WSPBCListType.UNARCHIVE),
      do: (message) => this.onArchiveAction(message),
    },
    {
      check: (message) =>
        message.info.__method === WSMethods.POST &&
        message.info.__type === WSPBCListType.COPY,
      do: (message) => this.onCopyList(message),
    },
    {
      check: (message) =>
        message.info.__method === WSMethods.DELETE &&
        message.info.__type === WSPBCListType.ONE,
      do: (message) => this.onListDelete(message),
    },
  ];

  constructor(
    private pbcService: PbcService,
    private socket: WebSocketService,
    public userService: UserService,
    public authService: AuthorizationService,
    private notifService: NotifierService,
    private store: Store<AppState>,
    private cRef: ChangeDetectorRef
  ) {
    this.username = userService.getUserName();
  }

  ngOnInit() {
    this.socket.message((e) => {
      let message = JSON.parse(e.data);

      this.wsHandlers.forEach((handler) => {
        if (handler.check(message)) handler.do(message);
      });

      if (this.lists != undefined) {
        this.lists.sort((a, b) => ("" + a.client).localeCompare(b.client));
      }
    });

    this.socket.open((e) => {
      this.pbcService.get();
    });
    this.pbcService.get();

    this.searchText$ = this.store.select(LocalConfigSelectors.getSearchValue);
    this.store.dispatch(LocalConfigActions.getAll());
  }

  onGetAllLists(message: any) {
    const splitLists: {
      lists: PBCList[];
      groupedLists: Map<string, RootedGroupList>;
    } = message.data.reduce(
      (
        prev: { lists: PBCList[]; groupedLists: Map<string, RootedGroupList> },
        data
      ) => this.addListFromMessage(data, prev),
      { lists: [], groupedLists: new Map<string, RootedGroupList>() }
    );

    this.updateLists(splitLists);

    this.listsStatus = this.convertStatus(
      message.data
        .filter((data) => data.status != undefined && data.status.value != TEntryStatus.for_download)
        .map((data) => {
          return { state: data.status, listId: data.list.id };
        })
    );

    //this.cRef.detectChanges();
    this.listsLoading = false;
  }

  onCopyList(message: any) {
    const splitLists = this.addListFromMessage(message.data, {
      lists: this.lists,
      groupedLists: this.groupedListArrayAsMap(),
    });

    this.updateLists(splitLists);
  }

  onArchiveAction(message: any) {
    const index = this.lists.findIndex((el) => el.id === message.data.listId);

    if (index == -1) {
      this.groupedLists = this.groupedLists.map((glist) =>
        RootedGroupList.setArchiveStateForListId(
          glist,
          message.data.listId,
          message.data.archived
        )
      );
    } else {
      this.lists[index].archived = message.data.archived;
    }
  }

  onListDelete(message: any): void {
    const listId = message.data.id;
    const listIdx = this.lists.findIndex((list) => list.id === listId);
    if (listIdx > -1) {
      this.lists = this.lists.splice(listIdx, 1);
      return;
    } else {
      this.groupedLists = this.groupedLists.map((gl) =>
        RootedGroupList.applyFnToListsById(gl, listId, (lists) =>
          lists.filter((list) => list.id != listId)
        )
      );
    }
  }

  updateLists(splitLists: any) {
    this.lists = splitLists.lists;
    this.groupedLists = [];
    splitLists.groupedLists.forEach((value, key) => {
      this.groupedLists.push(value);
    });
  }

  groupedListArrayAsMap(): Map<string, RootedGroupList> {
    return this.groupedLists.reduce((prev, el) => {
      prev.set(el.group.name, RootedGroupList.createFromRootedGroupList(el));
      return prev;
    }, new Map<string, RootedGroupList>());
  }

  addListFromMessage(
    data: any,
    prev: {
      lists: PBCList[];
      groupedLists: Map<string, RootedGroupList>;
    } = { lists: [], groupedLists: new Map() }
  ) {
    const list = PBCList.fromJSON(data.list);
    this.listsIsPartial[list.id] = data.isPartialList;

    if (data.rootPath == null) {
      prev.lists.push(list);
    } else {
      const root = data.rootPath;
      if (prev.groupedLists.get(root.group.name) == undefined) {
        const rootGroup = RootedGroupList.createFromRootedGroupList(root);
        prev.groupedLists.set(rootGroup.group.name, rootGroup);
      }

      prev.groupedLists
        .get(root.group.name)
        .addListAt(new RootedGroupList(root), list);
    }
    return prev;
  }

  setSearchText(value: string) {
    this.searchText = value;
    this.store.dispatch(
      LocalConfigActions.setField({
        key: "listsShow.searchItem",
        value: value,
      })
    );
  }

  convertStatus(data: { state: TPBCListStatusMap; listId: string }[]): {
    [listid: string]: {
      text: string;
      count: number;
      value: string;
      percentage: number;
    }[];
  } {
    return data
      .map((state) => {
        const states = state.state;
        const convertedStates = IdxCollection.toArray(states, (name, obj) => {
          return { text: name, count: obj[name].count, value: obj[name].value };
        }).filter(
          (v) => v.value != TEntryStatus.due && v.value != TEntryStatus.exceeded
        );

        const overall = convertedStates.reduce(
          (prev, el) => prev + el.count,
          0
        );

        const nums = convertedStates.reduce(
          (prev, elem) => {
            switch (elem.value) {
              case TEntryStatus.accepted:
              case TEntryStatus.closed:
              case TEntryStatus.not_needed:
                prev.closed += elem.count;
                break;
              case TEntryStatus.feedback:
              case TEntryStatus.opened:
                prev.open += elem.count;
                break;
            }
            return prev;
          },
          { closed: 0, open: 0 }
        );

        return [
          {
            text: Entry.statusText(TEntryStatus.opened),
            count: nums.open,
            value: TEntryStatus.opened,
            percentage: overall === 0 ? 1 : nums.open / overall,
          },
          {
            text: Entry.statusText(TEntryStatus.closed),
            count: nums.closed,
            value: TEntryStatus.closed,
            percentage: overall === 0 ? 0 : nums.closed / overall,
          },
        ];
      })
      .reduce((prev, el, idx) => {
        prev[data[idx].listId] = el;
        return prev;
      }, {});
  }
}
