import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewChecked, DoCheck } from '@angular/core';
import { TEntryStatus } from 'src/app/_core/Entry';
import { isType } from 'src/app/_helper/Helpers';
import { Item } from './Item';

@Component({
  selector: 'item-picker',
  templateUrl: './item-picker.component.html',
  styleUrls: ['./item-picker.component.scss']
})
export class ItemPickerComponent implements OnInit {
  @Input() items:Item[];
  @Input() disabled: boolean = false;
  @Input() positionDialogAt: (MouseEvent) => Point = undefined;
  @Input() showFirstLetter: boolean = false;
  @Input() defaultSelection : string;
  @Input() border: boolean;
  @Output() onItemClicked:EventEmitter<Item> = new EventEmitter<Item>();
  @ViewChild('dialogWrapper', { static: false }) viewDialogWrapper: HTMLDivElement;
  selectedItem:Item;
  dialogStyle:DialogStyle = {position: "absolute", top: "", left: ""};

  height: number = 0;
  isDialogVisible: boolean = false;

  approxDialogHeight = 0;
  HEIGHT_PER_ITEM = 45;

  isType = isType;

  constructor() {
    if(this.positionDialogAt == undefined)
      this.positionDialogAt = this.unpackClickPosition;
  }

  ngOnInit() {
    this.selectedItem = this.items.find(e => e.selected == true);
    if(this.selectedItem === undefined) this.selectDefault();
    this.approxDialogHeight = this.items.length * this.HEIGHT_PER_ITEM;
  }

  @Input("value") set value(value:string){
    let isSet = false;
    this.items.forEach(e => {
      if(e.value != value) e.selected = false;
      else {
        e.selected = true;
        this.selectedItem = e;
        isSet = true;
      }
    });

    if(!isSet){
      this.selectDefault();
    }
  }

  selectDefault() {
    this.items.forEach(e => {
      if(e.value === this.defaultSelection) {
        e.selected = true;
        this.selectedItem = e;
      }
    });
  }

  showDialog(event: MouseEvent){
    const {x, y} = this.positionDialogAt(event);

    const usedHeight = window.innerHeight;  //window.screenY > window.outerHeight ? window.screenY : window.outerHeight;

    if(usedHeight - y < this.approxDialogHeight) this.setDialogPositionAt(x, (y - this.approxDialogHeight));
    else this.setDialogPositionAt(x, y);
  }

  setDialogPositionAt(x:number, y:number){
    this.dialogStyle.position = 'fixed';
    this.dialogStyle.left = x + "px";
    this.dialogStyle.top = y + "px";
  }

  unpackClickPosition = (clickEvent: MouseEvent): Point => {
    const {clientX: xPos, clientY: yPos} = clickEvent;
    return {x: xPos, y:yPos};
  }

  itemClicked(clickedItem:Item){
    if(clickedItem.value != this.selectedItem.value && clickedItem.clickable(this.selectedItem.value)){
      this.onItemClicked.emit(clickedItem);
      this.isDialogVisible = false;
      this.items.forEach(e => {
        if(clickedItem != e) e.selected = false;
        else {
          e.selected = true;
          this.selectedItem = e;
        }
      });
    }
  }
}

type DialogStyle = {
  position:string;
  left:string;
  top?:string;
  bottom?:string;
}
type Point = {
  x:number;
  y:number;
}
