import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import {
  HttpBackend,
  HttpClient,
  HttpClientModule
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIcon,
  MatIconModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatTooltipModule
} from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatFileUploadModule } from "angular-material-fileupload";
import { Action } from "rxjs/internal/scheduler/Action";
import { HistoryLogShowComponent } from "../history-log-show/history-log-show.component";
import { TopMenuComponent } from "../top-menu/top-menu.component";
import { SearchInArray } from "../_pipes/SearchArrayFilter";
import { ActionDialogComponent } from "../_uicomponents/action-dialog/action-dialog.component";
import { CubeLoadingAnimationComponent } from "../_uicomponents/cube-loading-animation/cube-loading-animation.component";
import { CustInputComponent } from "../_uicomponents/custom-fields/cust-input/cust-input.component";
import { FileUploadComponent } from "../_uicomponents/file-upload/file-upload.component";
import { InfoBubbleComponent } from "../_uicomponents/info-bubble/info-bubble.component";
import { ItemPickerComponent } from "../_uicomponents/item-picker/item-picker.component";
import { NotifierComponent } from "../_uicomponents/notifier/notifier.component";
import { SaveLoadingAnimationComponent } from "../_uicomponents/save-loading-animation/save-loading-animation.component";
import { SearchInputComponent } from "../_uicomponents/search-input/search-input.component";
import { SocketStatusIndicatorComponent } from "../_uicomponents/socket-status-indicator/socket-status-indicator.component";
import { ToggleButtonComponent } from "../_uicomponents/toggle-button/toggle-button.component";
import { NotifierService } from "../_uicomponents/notifier/notifier-service";
import { LanguageSwitcherComponent } from "../_uicomponents/language-switcher/language-switcher.component";

export function HttpLoaderFactory(http: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(http),
    "./assets/i18n/",
    ".json"
  );
}

@NgModule({
  declarations: [
    ToggleButtonComponent,
    SearchInArray,
    SearchInputComponent,
    CustInputComponent,
    FileUploadComponent,
    TopMenuComponent,
    SocketStatusIndicatorComponent,
    NotifierComponent,
    ItemPickerComponent,
    SaveLoadingAnimationComponent,
    CubeLoadingAnimationComponent,
    InfoBubbleComponent,
    HistoryLogShowComponent,
    ActionDialogComponent,
    LanguageSwitcherComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpBackend],
        useFactory: HttpLoaderFactory,
      },
      isolate: false,
      extend: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    MatBadgeModule,
    MatFileUploadModule,
    MatButtonModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
  ],
  exports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatIcon,
    MatBadgeModule,
    MatFileUploadModule,
    MatButtonModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SearchInArray,
    SearchInputComponent,
    ToggleButtonComponent,
    CustInputComponent,
    FileUploadComponent,
    TopMenuComponent,
    SocketStatusIndicatorComponent,
    NotifierComponent,
    ItemPickerComponent,
    SaveLoadingAnimationComponent,
    CubeLoadingAnimationComponent,
    InfoBubbleComponent,
    HistoryLogShowComponent,
    ActionDialogComponent,
    LanguageSwitcherComponent
  ],
  entryComponents: [HistoryLogShowComponent, ActionDialogComponent]
})
export class SharedModule {}
