import { Component, OnInit } from '@angular/core';
import { WebSocketService } from '../../_services/web-socket.service';

@Component({
  selector: 'socket-status-indicator',
  templateUrl: './socket-status-indicator.component.html',
  styleUrls: ['./socket-status-indicator.component.scss']
})
export class SocketStatusIndicatorComponent implements OnInit {

  constructor(public webSocketService:WebSocketService) {}

  ngOnInit() {
  }

  close(){
    this.webSocketService.closeConnection();
  }

  tryReconnect(){
    if(this.webSocketService.reconnectCounter > this.webSocketService.RECONNECT_LIMIT){
      this.webSocketService.resetReconnectCounter();
      this.webSocketService.initWS();
    }
  }

  testDisconnect(){
    this.webSocketService.reconnectCounter = this.webSocketService.RECONNECT_LIMIT + 1;
    this.close();
  }
}
