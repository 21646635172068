import { Injectable } from "@angular/core";
import { CustomEntryField } from "../_core/CustomEntryField";
import { Entry } from "../_core/Entry";
import { ConvertLocaleStringNumber, isEmptyString, replaceAll } from "../_helper/Helpers";

@Injectable({
  providedIn: 'root'
})
export class ListImportService {
  async entryCSV(files: FileList) : Promise<Entry[]> {
    // TODO: HACKED import
    // TODO: basically hacked (more thought through to make it enhancealbe (should be completely handled by a service))
    // Aufbau: Debitor/Kreditor;Betrag;gezahlt/erhalten am; Re.Nr./GS.Nr.;angefragt am;erhalten am;Status
    const collectedEntries: Entry[] = [];

    for(let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      reader.readAsText(files[i], 'windows-1252');
      let entries = await new Promise<Entry[]>((resolve, reject) => {
        reader.onload = () => {
          const rows = reader.result.toString().split('\n');
          rows.splice(0, 1);
          const transformedRows = rows.map(row => {
            let cols = this.cleanCols(row.split(';'));

            if(this.isRowEmpty(cols)) return;
            let newEntry = new Entry();
            newEntry.type = "FiBu Standard";
            newEntry.showCommentary = true;
            newEntry.showResponsibles = false;
            newEntry.showFileUpload = true;
            newEntry.description = cols[0];
            if(!isEmptyString(cols[4])) newEntry.startDate = this.toDate(cols[4]);
            if(!isEmptyString(cols[5])) newEntry.endDate = this.toDate(cols[5]);

            let renr = CustomEntryField.fromJSON({
              type: 'input-text', text: 'Rechnungsnummer', value: !isEmptyString(cols[3])?cols[3]: '', isForEditMode: true
            });
            let bezam = CustomEntryField.fromJSON({
              type: 'input-date', text: 'Bezahlt am', value: !isEmptyString(cols[2])? this.convertDate(cols[2]): '', isForEditMode: true
            });
            let betr = CustomEntryField.fromJSON({
              type: 'input-number', text: 'Rechnungsbetrag', value: !isEmptyString(cols[1])?ConvertLocaleStringNumber(replaceAll(replaceAll(cols[1], " ", ""), "€", ""), ",", ".") : '', isForEditMode: true
            });

            newEntry.fields = [];
            newEntry.fields.push(renr);
            newEntry.fields.push(bezam);
            newEntry.fields.push(betr);

            return newEntry;
          });

          resolve(transformedRows);
        };
      });

      collectedEntries.push(...entries);
    }

    return Promise.resolve(collectedEntries);
  }

  private convertDate(date: string) : string {
    let sdate = date.split('.');
    return sdate[2] + '-' + sdate[1] + '-' + sdate[0];
  }

  private toDate(date:string) : Date {
    let sdate = date.split('.');
    let d = new Date();
    d.setDate(Number(sdate[0]));
    d.setMonth(Number(sdate[1]) - 1);
    d.setFullYear(Number(sdate[2]));
    return d;
  }

  private cleanCols(cols: string[]) : string [] {
    let newCols = [];
    for(let col of cols) {
      col = replaceAll(col, "\r", "");
      newCols.push(col);
    }

    return newCols;
  }

  private isRowEmpty(cols: string[]) : boolean {
    const newCols = [];
    for(let col of cols) if(!isEmptyString(col)) newCols.push(col);

    return newCols.length == 0;
  }
}
