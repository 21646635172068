import { debounce } from "@agentepsilon/decko";
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Category } from "src/app/_core/Category";
import { Entry } from "src/app/_core/Entry";

@Component({
  selector: "toc-edit-create",
  templateUrl: "./toc-edit-create.component.html",
  styleUrls: ["./toc-edit-create.component.scss"],
})
export class TocEditCreateComponent implements OnInit, AfterContentInit {
  @Input() listIndex;
  @Input() categories: Category[];
  @Input() showButton = true;
  @Input() isExpanded: boolean = false;
  @Input() isChild: boolean = false;
  @Input() movingEntries: Observable<{
    entries: Entry[];
    event: EventEmitter<boolean>;
  }> = new Observable();
  @Input() showListNumbers: boolean = false;
  @Output() onCategorySelected = new EventEmitter<string>();
  @Output() categoriesChange = new EventEmitter<Category[]>();
  @Output() onDragHover: EventEmitter<[Element, number[], string]> =
    new EventEmitter();
  @Output() onCategoryDropped: EventEmitter<number[]> = new EventEmitter();
  @Output() onHierarchyUp: EventEmitter<Category> = new EventEmitter();
  @Output() onRefreshIndex: EventEmitter<void> = new EventEmitter();
  @Output() makeDirty: EventEmitter<void> = new EventEmitter();

  @ViewChildren(TocEditCreateComponent)
  viewCategories: TocEditCreateComponent[];
  @ViewChildren("txtDescription") viewsDescriptionInput: QueryList<any>;
  @ViewChild("rootElement", { static: true }) rootElement: ElementRef;

  intCounter: number = 0;
  lastClickedCategory: string = "";
  isSelected: boolean[] = [];
  isChildSelected = true; // not working rn
  showHierarchyIcons: boolean[];
  stopShowHierarchyIcons: boolean = false;
  isTextEditable = false;
  __selectedCategoryIndex: string;
  constructor(private tr: TranslateService) {}

  ngAfterContentInit(): void {
    this.animate("animate__fadeIn", undefined, 0, 2000);
  }

  ngOnInit() {
    this.isSelected = new Array(this.categories.length).fill(false);
    this.showHierarchyIcons = new Array(this.categories.length).fill(false);
  }

  @Input() set selectedCategoryIndex(cati: string) {
    if (cati != undefined) {
      let categoryPos = -1;
      if (cati.includes(".")) {
        let cats = cati.split(".");
        categoryPos = Number(cats[cats.length - 1]);
      } else {
        categoryPos = Number(cati);
      }

      if (
        this.categories[categoryPos] != undefined &&
        cati == this.categories[categoryPos].internalIndex.join(".")
      ) {
        this.isSelected[categoryPos] = true;
        for (let i = 0; i < this.categories.length; i++)
          if (i != categoryPos) {
            this.isSelected[i] =
              this.categories[i].internalIndex.join(".") == cati;
            this.isChildSelected = cati.includes(
              this.categories[i].internalIndex.join(".")
            );
          }
      } else {
        for (let i = 0; i < this.categories.length; i++) {
          this.isSelected[i] =
            this.categories[i].internalIndex.join(".") == cati;
          this.isChildSelected = cati.includes(
            this.categories[i].internalIndex.join(".")
          );
        }
      }

      this.__selectedCategoryIndex = cati;
    }
  }

  __dragHoverIdx: [number[], string] = [[], ""];
  @Input() set dragHoverIdx(idx: [number[], string]) {
    this.__dragHoverIdx = idx;
  }
  get dragHoverIdx(): [number[], string] {
    return this.__dragHoverIdx;
  }

  addCategory(pos: number = 0) {
    if (this.categories == undefined) {
      this.categories = [];
    }

    this.categories.splice(
      pos,
      0,
      new Category().withDescription(this.tr.instant("CATEGORY_EDIT.NEW"))
    );
    this.categoriesChange.emit(this.categories);
    //this.viewCategories[pos].animate("animate_fadeIn") // does not exist in that moment first one new render cycle must be executed
  }

  addCategoryInside(pos: number) {
    if (this.categories[pos].categories === undefined)
      this.categories[pos].categories = [];

    this.categories[pos].categories.push(
      new Category().withDescription(this.tr.instant("CATEGORY_EDIT.NEW"))
    );
    this.viewCategories[pos].categoriesChange.emit(
      this.categories[pos].categories
    );

    setTimeout(() => this.viewCategories[pos].viewCategories[this.viewCategories[pos].viewCategories.length - 1].animate("animate__fadeIn"), 3000);
  }

  deleteCategory(pos: number) {
    this.categories.splice(pos, 1);
    this.categoriesChange.emit(this.categories);
    this.onCategorySelected.emit("0");
  }

  dragMoved(event: CdkDragMove<any>, internalIndex) {
    let e = document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );

    if (!e) {
      return;
    }
    let container = e.classList.contains("node-item")
      ? e
      : e.closest(".node-item");

    if (!container) {
      this.onDragHover.emit([container, internalIndex, ""]);
      return;
    }

    const targetRect = container.getBoundingClientRect();
    const oneThird = targetRect.height / 3;
    let hoveredIndex = container
      .getAttribute("data-id")
      .split("-") as unknown as number[];

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.onDragHover.emit([container, hoveredIndex, "before"]);
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.onDragHover.emit([container, hoveredIndex, "after"]);
    } else {
      // inside
      this.onDragHover.emit([container, hoveredIndex, "inside"]);
    }
  }

  drop(event: CdkDragDrop<any>, internalIndexDroppedElement: number[]) {
    this.onCategoryDropped.emit(internalIndexDroppedElement);
  }

  moveUp(event, pos: number) {
    const tmp = this.categories[pos];
    this.categories[pos] = this.categories[pos - 1];
    this.categories[pos - 1] = tmp;
    this.onRefreshIndex.emit();
    this.animate("animate__fadeIn");
  }

  moveDown(event, pos: number) {
    const tmp = this.categories[pos];
    this.categories[pos] = this.categories[pos + 1];
    this.categories[pos + 1] = tmp;
    this.onRefreshIndex.emit();
    this.animate("animate__fadeIn");
  }
  moveToStart(pos: number) {
    const moveMe = this.categories.splice(pos, 1);
    this.categories.unshift(moveMe[0]);
    this.onRefreshIndex.emit();
    this.animate("animate__fadeIn");
  }

  moveToEnd(pos: number) {
    const moveMe = this.categories.splice(pos, 1);
    this.categories.push(moveMe[0]);
    this.onRefreshIndex.emit();
    this.animate("animate__fadeIn");
  }

  moveHierarchyUp(pos: number) {
    const removed = this.categories.splice(pos, 1);
    this.onHierarchyUp.emit(removed[0]);
    this.animate("animate__fadeIn");
  }

  moveHierarchyDown(pos: number) {
    const removed = this.categories.splice(pos, 1);
    this.categories[pos - 1].categories.push(removed[0]);
    this.onRefreshIndex.emit();
    this.animate("animate__fadeIn");
  }

  hierarchyUp(category: Category, insertPos: number) {
    this.categories.splice(insertPos + 1, 0, category);
    this.onRefreshIndex.emit();
  }

  switchEditMode(pos: number) {
    this.isTextEditable = !this.isTextEditable;

    setTimeout(() => {
      const nee = this.viewsDescriptionInput.find(
        (_, i) => i == pos
      ).nativeElement;
      nee.focus();
      nee.select();
    }, 10);
  }

  showHierarchyWTimeout(pos: number) {
    setTimeout(() => {
      this.showHierarchyIcons[pos] = true && !this.stopShowHierarchyIcons;
      this.stopShowHierarchyIcons = false;
    }, 1000);
  }

  createTOCIndex(pos: number) {
    return this.categories[pos].internalIndex.map((v) => v + 1).join(".");
  }

  categoryClicked(pos: number) {
    this.lastClickedCategory = pos.toString();
    this.onCategorySelected.emit(
      this.listIndex === "" ? pos.toString() : this.listIndex + "." + pos
    );
  }

  descriptionChanged(pos: number, description: string) {
    this.categories[pos].description = description;
    this.categoriesChange.emit(this.categories);
  }

  onKeyDownDescriptionInput(event, categoryPosition: number) {
    switch (event.key) {
      case "Enter":
        if (this.isTextEditable) this.isTextEditable = false;
        event.preventDefault();
        break;
      case "Tab":
        if (this.isTextEditable) {
          event.target.blur();
          this.isTextEditable = true;
          const inputs = document.getElementsByName("txtCategoryDescription");
          if (inputs.length > categoryPosition + 1)
            inputs[categoryPosition + 1].focus();
        }
        event.preventDefault();
        break;
    }
  }

  getViewChildAt(pos: number, vc: any) {
    if (vc[pos] === undefined)
      vc = (vc as unknown as QueryList<any>).toArray()[pos];
    return vc;
  }

  animate(
    className: string,
    delayedCallback: () => void = () => {},
    delay: number = 0,
    animationDelay: number = 0
  ) {
    setTimeout(() => {
      if (this.rootElement == undefined) return;
      this.rootElement.nativeElement.classList.remove(className);
      this.rootElement.nativeElement.classList.add(className);
      this.rootElement.nativeElement.scrollTo();
      setTimeout(
        () => this.rootElement.nativeElement.classList.remove(className),
        1000
      );
      if(delayedCallback != undefined) {
        setTimeout(() => delayedCallback(), delay);
      }
    }, animationDelay);
  }
}
