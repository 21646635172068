// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = (() => {
  const IS_PROD_INUX = false;
  const IS_PROD_VPS = true;
  const IS_INTERNAL = false;

  // docker internal
  if(IS_INTERNAL) return {
    production: true,
    // remote dev setup (live system)
    protocol: "http",
    wsprotocol: "ws",
    host: "localhost",
    authHost: "localhost",
    serverPort: "9090",
    authPort: "9292"
  }
  // iNUX
  else if(IS_PROD_INUX && !IS_PROD_VPS) return {
    production: true,
    // remote dev setup (live system)
    protocol: "https",
    wsprotocol: "wss",
    host: "pbc-data.inux-partner.de",
    authHost: "pbc-auth.inux-partner.de",
    serverPort: "443",
    authPort: "443"
  };
  // VPS
  else if(IS_PROD_VPS && !IS_PROD_INUX) return {
    production: true,
    // remote dev setup (live system)
    protocol: "https",
    wsprotocol: "wss",
    host: "backend.digihub.tnux.de",
    authHost: "auth.digihub.tnux.de",
    serverPort: "443",
    authPort: "443"
  };
  // local
  else return {
    production: false,
    // public local dev setup
    protocol: "http",
    wsprotocol: "ws",
    host: "localhost",
    authHost: "localhost",
    serverPort: "90",
    authPort: "92"
  };
})();

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
