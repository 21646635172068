import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'header-row',
  templateUrl: './header-row.component.html',
  styleUrls: ['./header-row.component.scss']
})
export class HeaderRowComponent implements OnInit {
  @Output() onSortEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input() orderBy: string;
  @Input() sortOrder: string;

  constructor() { }

  ngOnInit() {
  }

}
