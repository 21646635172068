import { Component, OnInit } from "@angular/core";
import { WSPBCListType } from "src/app/_core/AuthObjects";
import { User } from "src/app/_core/User";
import { AdministrationService } from "src/app/_services/administration.service";
import { PbcService } from "src/app/_services/pbc.service";
import {
  WebSocketService,
  WSMethods,
} from "src/app/_services/web-socket.service";

@Component({
  templateUrl: "./rights-overview.component.html",
  styleUrls: ["./rights-overview.component.scss"],
})
export class RightsOverviewComponent implements OnInit {
  clients: string[];
  users: User[];

  filteredUsers: string[] = [];
  filteredClients: string[] = [];

  constructor(
    private wss: WebSocketService,
    private pbcListService: PbcService,
    private adminService: AdministrationService
  ) {
    this.wss.message((e) => {
      let message = JSON.parse(e.data);
      const { data, info, __sender__ } = message;

      if (
        info.__method === WSMethods.GET &&
        info.__type === WSPBCListType.CLIENTS_ALL
      ) {
        this.clients = data;
        this.filteredClients = this.clients;
      }
    });

    adminService.getAuthorizations().subscribe((ur) => {
      this.users = ur.sort((a, b) => a.name.localeCompare(b.name));
      this.filteredUsers = this.users.map((a) => a.name);
    });
    this.pbcListService.getAllClients();
  }

  usersChanged(sel: string[]) {
    if (sel.length > 0) {
      const newClients = this.clientsFromUserRights(
        this.users.filter((c) => sel.includes(c.name))
      );

      this.filteredClients = Array.from(newClients);
    } else this.filteredClients = this.clients;
  }

  clientsChanged(sel: string[]) {
    if (sel.length > 0) {
      const newUsers = this.usersByClients(this.clients.filter((_) => sel.includes(_))).map(a => a.name);

      this.filteredUsers = newUsers;
    } else this.filteredUsers = this.users.map((_) => _.name);
  }

  clientsFromUserRights(users: User[]) {
    return users
      .map((c) =>
        c.rights
          .map((r) => {
            if (r.object === "pbc-list" && this.checkMethods(r.methods)) return r.values;
            else return undefined;
          })
          .filter((el) => el != undefined)
          .reduce((prev, el) => {
            prev.push(...el);
            return prev;
          }, [])
      )
      .reduce((prev, el) => {
        el.forEach(_ => prev.add(_));
        return prev;
      }, new Set<string>()).keys();
  }

  usersByClients(clients: string[]): User[] {
    return this.users.filter(
      (u) =>
        u.rights
          .map((r) => {
            if (r.object === "pbc-list" && this.checkMethods(r.methods))
              return r.values.some((v) => clients.includes(v) || v === '*');
            return false;
          })
          .filter(r => r).length > 0
    );
  }

  checkMethods(methods: string[]) :boolean {
    return methods.includes("GET");
  }

  ngOnInit() {}
}
