import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Entry } from "src/app/_core/Entry";

@Injectable({
  providedIn: "root",
})
export class EntriesMoveService {
  onSelect = new Subject<Entry[]>();
  onDeselect = new Subject<Entry[]>();
  onMoveBegin = new Subject<Entry[]>();
  onMoveFinish = new Subject<Entry[]>();
  onUpdateState = new Subject<void>();

  selectedEntries: Entry[] = [];
  selectedCategory: string = "";

  select(entry: Entry[] | Entry) {
    // first selection
    if (this.selectedEntries.length == 0) {
      this.selectedCategory =
        entry instanceof Entry
          ? entry.internalIndex.slice(0, -1).join(".")
          : entry[0].internalIndex.slice(0, -1).join(".");
    }

    if (entry instanceof Entry) {
      this.selectedEntries.push(entry);
      this.onSelect.next([entry]);
    } else {
      this.selectedEntries.push(...entry);
      this.onSelect.next(entry);
    }
  }

  deselect(entry: Entry[] | Entry) {
    if (entry instanceof Entry) {
      this.selectedEntries = this.selectedEntries.filter(
        (e) => e.internalID != entry.internalID
      );
      if (this.selectedEntries.length === 0) {
        this.selectedCategory = "";
      }
      this.onDeselect.next([entry]);
    } else {
      this.selectedEntries = this.selectedEntries.filter(
        (el) => !entry.some((el2) => el2.internalID === el.internalID)
      );
      if (this.selectedEntries.length === 0) {
        this.selectedCategory = "";
      }

      this.onDeselect.next(entry);
    }
  }

  deselectAll() {
    this.selectedEntries = [];
    this.selectedCategory = "";
    this.onDeselect.next(this.selectedEntries);
  }

  move() {
    this.onMoveBegin.next(this.selectedEntries);
    this.selectedEntries = [];
    this.selectedCategory = "";
    this.onMoveFinish.next(this.selectedEntries);
  }

  updateState() {
    this.onUpdateState.next();
  }
}

