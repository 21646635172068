import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CategoryShowComponent } from '../category-show/category-show.component';
import { Category } from '../../_core/Category';
import { TocEntryComponent } from './toc-entry.component';

@Component({
  selector: 'toc-show',
  templateUrl: './toc-show.component.html',
  styleUrls: ['./toc-show.component.scss']
})
export class TocShowComponent implements OnInit {
  @Input() list:any; // TODO: retype either PBCList/AccountingList or a general type
  @Input() showListNumbers: boolean;
  @Output() onSelect:EventEmitter<Category> = new EventEmitter<Category>();
  @Output() onSelectIndex:EventEmitter<number[]> = new EventEmitter<number[]>();
  selectedCategory:Category[];

  @ViewChildren(TocEntryComponent) children: QueryList<CategoryShowComponent>;

  isSelected:boolean = false;

  constructor() { }

  ngOnInit() {
  }

  deselect(){
    this.children.forEach(child => child.deselect());
  }

  select(category: string) {
    let cats = category.split(".").map(el => Number(el)).reverse();
    let first = cats.pop();
    this.onCategoryClicked(cats, first);
  }

  onCategoryClicked(indexOfCategories:number[], clickedCategory:number){
    this.isSelected = true;
    this.children.forEach((sub, i) => {
      if(i !== clickedCategory) sub.deselect();
    });

    indexOfCategories.push(clickedCategory);
    this.onSelect.emit(this.getClickedCategory(indexOfCategories));
    this.onSelectIndex.emit(indexOfCategories);
  }

  getClickedCategory(indexOfCategories:number[], activeCategory:Category = this.list){
    indexOfCategories = indexOfCategories.reverse();
    indexOfCategories.forEach(v => {
      activeCategory = activeCategory.categories[v]
    });

    return activeCategory;
  }

}
