import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WSUserType } from "src/app/_core/AuthObjects";
import { User } from "src/app/_core/User";
import { AdministrationService } from "src/app/_services/administration.service";
import { AuthorizationService } from "src/app/_services/authorization.service";
import { PbcService } from "src/app/_services/pbc.service";
import { UserService } from "src/app/_services/user.service";
import {
  WebSocketService,
  WSMethods
} from "src/app/_services/web-socket.service";

@Component({
  selector: "user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
  users: User[] = [];
  selectedUserIdx: number = -1;
  dataLoaded = false;
  showNewUserComponent = false;
  searchUser: string;
  preselected: number = -1;

  constructor(
    public adminService: AdministrationService,
    private router: ActivatedRoute,
    private socket: WebSocketService,
    private userService: UserService,
    private pbcService:PbcService,
    public authService: AuthorizationService
  ) {
    /*adminService.getAuthorizations().subscribe(ur => {
      this.users = ur.sort((a,b) => a.name.localeCompare(b.name));
      this.dataLoaded = true;
    });*/

    socket.message((event: MessageEvent) => {
      const eventData = JSON.parse(event.data);
      const  { __method, __type, __status } = eventData.info;
      const data = eventData.data;

      if (
        __method === WSMethods.GET &&
        (__type === WSUserType.USERS || __type === WSUserType.USERS_ALL) &&
        __status == 200
      ) {
        if(!this.dataLoaded)
          this.users = data.sort((a, b) => a.name.localeCompare(b.name)).map(u => User.fromJSON(u)).filter(u => !u.isTechnicalRole);
        this.dataLoaded = true;
      }
    });

    userService.getAuthorizations().subscribe((rights) => {
      if(rights.some(right => right.object === 'pbc-list' && right.values.includes('*') && right.methods.includes("GET"))) {
        this.pbcService.getAllUsers();
      }
      else {
        const clientRights = rights.filter(
          (right) => right.object === "pbc-list" && right.methods.includes("GET")
        );
        const allowedClients = clientRights.reduce(
          (prev, el) => [...prev, ...el.values],
          []
        );
        userService.getUsersByClients(allowedClients);
      }
    });
  }

  ngOnInit() {}

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "ArrowUp" && this.preselected > 0) {
      this.preselected -= 1;
    } else if (event.key == "ArrowDown") {
      if (this.preselected < 0) {
        this.preselected = 0;
      } else if (this.preselected < this.users.length - 1) {
        this.preselected += 1;
      }
    } else if (event.key === "Enter") {
      this.selectedUserIdx = this.preselected;
    }
  }

  selectUser(userId: string) {
    this.selectedUserIdx = this.users.findIndex((user) => user._id === userId);
    this.showNewUserComponent = false;
  }

  userDeleted(userId: string) {
    this.users = this.users.filter(user => user._id !== userId);
    this.selectedUserIdx = -1;
  }

  userAdded(event) {
    this.users.push(event.user);
    this.users = [...this.users];
    this.selectedUserIdx = this.users.length - 1;
    this.showNewUserComponent = false;
  }

  markHovered(iPos: number) {
    this.preselected = iPos;
  }
}
