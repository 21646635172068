import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { __values } from 'tslib';
import { resolve } from 'url';
import { WSPBCListType, WSUserType } from '../_core/AuthObjects';
import { TEntryStatus, WSEntryType } from '../_core/Entry';
import UserData from '../_core/UserData';
import { BasicAuthorizationService } from './basic-authorization.service';
import { TStatus, UserService } from './user.service';

/**
 * Implements a subset of queries based on the retrieved user-authorizations if the logged-in user can perform certain actions.
 * Generally used to hide certain UI elements, forbid certain navigations and prevent unnecessary requests.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private userService:UserService, private bas:BasicAuthorizationService, private http:HttpClient) {
  }

  async init(){
    return this.bas.init();
  }

  canChangeEntryStateTo(newEntryState:TEntryStatus): boolean {
    return this.bas.canUpdate(WSEntryType.STATE, newEntryState.toLowerCase());
  }

  canCreateComment(): boolean {
    return this.bas.canCreate(WSEntryType.COMMENT, "*");
  }

  canDeleteComment(): boolean {
    return this.bas.canDelete(WSEntryType.COMMENT, "*");
  }

  canCreateNewPBCList(value: string = undefined) : boolean {
    return this.bas.canCreate(WSPBCListType.ONE, value);
  }

  canUpdatePBCList(value: string = "*") : boolean {
    return this.bas.canUpdate(WSPBCListType.ONE, value);
  }

  canCreateNewEntry(value: string = "*"){
    return this.bas.canCreate(WSEntryType.ONE, value);
  }

  canCreateNewEntryByImport(value: string = "*") {
    return this.bas.canCreate(WSEntryType.IMPORT, value);
  }

  canDeletePBCList(value: string = "*") : boolean {
    return this.bas.canDelete(WSPBCListType.ONE, value);
  }

  canUpdateUserRights(): boolean {
    return this.isRightsAdmin() || this.isSuperAdmin();
  }

  hasListRight(clientName: string) : boolean {
    return this.bas.canRead("pbc-list", clientName) && !this.bas.canRead("pbc-entry-id", "pbc-list" + clientName);
  }

  public getListReadRights(list_id: string) : Observable<UserData[]> {
    let authHeader = new HttpHeaders({"Authorization": "Bearer " + this.userService.token});
    return this.http.get<UserData[]>(UserService.BASE_AUTH_URL + "/users/with/authorization/on/GET/with/"+list_id+"/for/pbc-list-id/by/pbc", {headers: authHeader});
  }

  canArchivePBCList(value: string = "*") : boolean {
    return this.bas.canUpdate(WSPBCListType.ARCHIVE, value);
  }

  canHijackUser(userName: string){
    return this.bas.canCreate("HIJACK_USER", userName);
  }

  canCreateGroup(value: string = "*") {
    return this.bas.canCreate("GROUP", value);
  }

  isSuperAdmin() : boolean {
    return this.bas.canRead(WSUserType.SUPER_ADMIN, "*");
  }

  isRightsAdmin() : boolean {
    return this.bas.canRead(WSUserType.RIGHTS, "*");
  }

  getTenant(): string[] {
    return this.bas.getValue("tenant");
  }
}
