import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { act } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "cypress/types/bluebird";
import { WSPBCListType, WSUserType } from "src/app/_core/AuthObjects";
import { Entry, TEntryStatus } from "src/app/_core/Entry";
import { User } from "src/app/_core/User";
import UserData, { MailNotificationConfig } from "src/app/_core/UserData";
import { updatePropertyByDotSeparatedString } from "src/app/_helper/Helpers";
import { AdministrationService } from "src/app/_services/administration.service";
import { AuthorizationService } from "src/app/_services/authorization.service";
import { PbcService } from "src/app/_services/pbc.service";
import { UserConfigService } from "src/app/_services/user-config.service";
import { UserService } from "src/app/_services/user.service";
import {
  WebSocketService,
  WSMethods,
} from "src/app/_services/web-socket.service";
import {
  NotifierIcons,
  NotifierService,
} from "src/app/_uicomponents/notifier/notifier-service";

@Component({
  selector: "rights-edit",
  templateUrl: "./rights-edit.component.html",
  styleUrls: ["./rights-edit.component.scss"],
})
export class RightsEditComponent implements OnInit {
  __selectedUserIdx: string;
  selectedUserData: UserData;
  clients: {
    name: string;
    read: boolean;
    write: boolean;
    delete: boolean;
    create: boolean;
    entry: {
      create: boolean;
    };
  }[];
  generalListRight: {
    write: boolean;
    delete: boolean;
    create: boolean;
    read: boolean;
  } = {
    write: false,
    delete: false,
    create: false,
    read: false,
  };

  item_all = {
    read: false,
    entry_create: false,
    write: false,
    delete: false,
    create: false,
  };

  superAdmin: boolean = false;
  rightsAdmin: boolean = false;
  showClientRights: boolean = true;
  lists: any[] = [];
  tenant: string = "";

  @Input() users: User[];
  searchText: string = "";

  isSaving: boolean = false;
  listRightCounter = 0;

  alert = alert;
  @Output() onAbort: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private userConfService: UserConfigService,
    private pbcListService: PbcService,
    private wss: WebSocketService,
    private notifS: NotifierService,
    public authService: AuthorizationService,
    private adminService: AdministrationService,
    private tr: TranslateService
  ) {
    this.wss.message((e) => {
      let message = JSON.parse(e.data);
      const { data, info, __sender__ } = message;

      if (
        info.__method === WSMethods.GET &&
        info.__type === WSPBCListType.CLIENTS_ALL
      ) {
        const pbcListRights = this.users[this.__selectedUserIdx].rights.filter(
          (r) => r.object === "pbc-list"
        );

        const entryRights = this.users[this.__selectedUserIdx].rights
          .filter((r) => r.object === "entry")
          .reduce((prev, el) => {
            prev[el.values[0]] = el;
            return prev;
          }, {});

        this.clients = data.map((client) =>
          this.mapRightToClient(client, pbcListRights, entryRights)
        );
        this.clients = this.orderClients(this.clients);
        this.item_all = this.determineItemAllFlags(this.clients);
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.ALL
      ) {
        this.onGetLists(data);
      } else if (
        info.__method == WSMethods.PUT &&
        info.__type == WSPBCListType.LIST_RIGHTS
      ) {
        /*let jsonData = JSON.parse(data);

        jsonData.add.forEach(
          (right) =>
            (this.users[this.selectedUserIdx].rights =
              this.removeListRightsById(right))
        );

        this.users[this.selectedUserIdx].rights.push(...jsonData.add);

        jsonData.delete.forEach(
          (right) =>
            (this.users[this.selectedUserIdx].rights =
              this.removeListRightsById(right))
        );

        if (this.listRightCounter == this.lists.length - 1) {
          this.listRightCounter = 0;
          this.isSaving = false;
          this.saveUser();
        } else this.listRightCounter++;*/
      }
    });
  }

  getTenantOfUser() {
    const foundTenant = this.users[this.selectedUserIdx].rights.find(
      (right) => right.object === "tenant"
    );
    if (foundTenant) return foundTenant.values;
  }

  removeListRightsById(right) {
    return this.users[this.selectedUserIdx].rights.filter((uRight) => {
      if (
        uRight.application == right.application &&
        uRight.values.some((e) => right.values.includes(e)) &&
        uRight.object == right.object
      ) {
        return false;
      } else return true;
    });
  }

  get selectedUserIdx(): string {
    return this.__selectedUserIdx;
  }

  userDataSelected = false;
  @Input() set selectedUserIdx(s: string) {
    if (s === undefined) {
      this.userDataSelected = false;
      return;
    }

    this.userDataSelected = false;
    this.userConfService
      .getUserData(this.users[s].name, this.userService.token)
      .subscribe(
        (d) => {
          this.selectedUserData = d;
          this.userDataSelected = true;
        },
        (error) => {
          this.selectedUserData = UserData.empty(this.users[s].name);
          this.userDataSelected = true;
        }
      );
    if (this.pbcListService) this.pbcListService.getAllClients(); // TODO: why? who dis? Sollte ein call gegen userService.getClients... haben
    this.generalListRight.create = this.users[s].hasRight(
      "pbc-list",
      "*",
      "POST"
    );
    this.generalListRight.write = this.users[s].hasRight(
      "pbc-list",
      "*",
      "PUT"
    );
    this.generalListRight.delete = this.users[s].hasRight(
      "pbc-list",
      "*",
      "DELETE"
    );
    this.generalListRight.read = this.users[s].hasRight("pbc-list", "*", "GET");

    this.superAdmin = this.users[s].hasRight(
      WSUserType.SUPER_ADMIN,
      "*",
      "GET"
    );
    this.rightsAdmin = this.users[s].hasRight(WSUserType.RIGHTS, "*", "POST");

    this.__selectedUserIdx = s;
    this.tenant = this.getTenantOfUser();
    this.pbcListService.get();
  }

  onGetLists(data: any[]) {
    let activeClients = this.clients
      .filter(
        (client) =>
          client.create ||
          client.delete ||
          client.read ||
          client.write ||
          client.entry.create
      )
      .map((client) => client.name);

    this.lists = data
      .map((d) => d.list)
      .filter((list) => activeClients.includes(list.client))
      .map((list) => {
        list.hasRight = this.users[this.selectedUserIdx].hasRight(
          WSPBCListType.ONE + "-id",
          list.id,
          WSMethods.GET
        );
        return list;
      });
  }

  determineItemAllFlags(clientList: any[]) {
    return clientList.reduce(
      (prev: any, el: any) => {
        prev.read = prev.read && el.read;
        prev.write = prev.write && el.write;
        prev.entry_create = prev.entry_create && el.entry.create;
        prev.delete = prev.delete && el.delete;
        prev.create = prev.create && el.create;
        return prev;
      },
      {
        read: true,
        write: true,
        entry_create: true,
        delete: true,
        create: true,
      }
    );
  }

  ngOnInit() {}

  stringify = JSON.stringify;

  mapRightToClient(client: string, pbcListRights: any, entryRights: any) {
    const listRights = pbcListRights.filter((r) =>
      r.values.some((v) => v === client || v === "*")
    );

    return {
      name: client,
      read: listRights.some((r) =>
        r.methods.some((m) => m === "GET" || m === "ALL")
      ),
      write: listRights.some((r) =>
        r.methods.some((m) => m === "PUT" || m === "ALL")
      ),
      delete: listRights.some((r) =>
        r.methods.some((m) => m === "DELETE" || m === "ALL")
      ),
      create: listRights.some((r) =>
        r.methods.some((m) => m === "POST" || m === "ALL")
      ),
      entry: this.extractEntryRight(client, entryRights),
    };
  }

  extractEntryRight(client: string, entryRights: any) {
    if (entryRights[client] === undefined) return { create: false };

    return {
      create: entryRights[client].methods.some(
        (v) => v === "ALL" || v === "POST"
      ),
    };
  }

  mapClientsToRight(
    clients: {
      name: string;
      write: boolean;
      read: boolean;
      delete: boolean;
      create: boolean;
      entry: {
        create: boolean;
      };
    }[]
  ) {
    return clients.reduce((prev, c) => {
      const methods = [];
      if (c.write) methods.push("PUT");
      if (c.read) methods.push("GET");
      if (c.delete) methods.push("DELETE");
      if (c.create) methods.push("POST");
      prev.push({
        application: "pbc",
        object: "pbc-list",
        values: [c.name],
        methods: methods,
      });

      const eMethods = [];
      if (c.entry.create) eMethods.push("POST");
      if (eMethods.length > 0)
        prev.push({
          application: "pbc",
          object: "entry",
          values: [c.name],
          methods: eMethods,
        });

      return prev;
    }, []);
  }

  orderClients(clients): any[] {
    const sortedClients: any[] = this.clients.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    return sortedClients
      .filter((r) => r.write || r.read)
      .concat(sortedClients.filter((r) => !r.write && !r.read));
  }

  getStatusList() {
    return [
      TEntryStatus.opened,
      TEntryStatus.closed,
      TEntryStatus.not_needed,
      TEntryStatus.for_download,
      TEntryStatus.feedback,
      TEntryStatus.accepted,
    ];
  }

  statusChanged(status: string, isSelected: boolean) {
    if (isSelected)
      this.users[this.selectedUserIdx].addRight("entry-state", status, "PUT");
    else
      this.users[this.selectedUserIdx].removeValue(
        "entry-state",
        status,
        "PUT"
      );
  }

  archiveChanged(isSelected: boolean) {
    if (isSelected) {
      this.users[this.selectedUserIdx].addRight("archive-list", "*", "PUT");
      this.users[this.selectedUserIdx].addRight("unarchive-list", "*", "PUT");
    } else {
      this.users[this.selectedUserIdx].removeValue("archive-list", "*", "PUT");

      this.users[this.selectedUserIdx].removeValue(
        "unarchive-list",
        "*",
        "PUT"
      );
    }
  }

  commentChanged(method: string, isSelected: boolean) {
    if (isSelected) {
      if (method === "POST")
        this.users[this.selectedUserIdx].addRight("entry-comment", "*", "POST");
      if (method === "DELETE")
        this.users[this.selectedUserIdx].addRight(
          "entry-comment",
          "*",
          "DELETE"
        );
    } else
      this.users[this.selectedUserIdx].removeMethod("entry-comment", method);
  }

  changeListRight(
    item,
    right: "write" | "read" | "delete" | "create",
    value: boolean
  ) {
    const idx = this.clients.findIndex((c) => c.name == item.name);
    item = updatePropertyByDotSeparatedString(item, right, value);
    this.clients[idx] = item;

    this.item_all = this.determineItemAllFlags(this.clients);
    this.pbcListService.get();
  }

  changeGeneralListRights(
    right: "POST" | "PUT" | "DELETE" | "GET",
    value: boolean
  ) {
    switch (right) {
      case "POST":
        this.generalListRight.create = value;
        this.setClientAllRights("create", value);
        break;
      case "DELETE":
        this.generalListRight.delete = value;
        this.setClientAllRights("delete", value);
        break;
      case "PUT":
        this.generalListRight.write = value;
        this.setClientAllRights("write", value);
        break;
      case "GET":
        this.generalListRight.read = value;
        this.setClientAllRights("read", value);
        break;
    }
  }

  toggleListRightAt(listPos) {
    this.lists[listPos].hasRight = !this.lists[listPos].hasRight;
  }

  abort() {
    this.onAbort.emit(undefined);
  }

  save() {
    this.isSaving = true;
    this.users[this.selectedUserIdx].rights = this.users[
      this.selectedUserIdx
    ].rights.filter((r) => r.object != "pbc-list" && r.object != "entry");
    this.users[this.selectedUserIdx].rights.push(
      ...this.mapClientsToRight(this.clients)
    );

    if (
      this.generalListRight.create ||
      this.generalListRight.delete ||
      this.generalListRight.write ||
      this.generalListRight.read
    ) {
      const methods = [];
      if (this.generalListRight.create) methods.push("POST");
      if (this.generalListRight.write) methods.push("PUT");
      if (this.generalListRight.delete) methods.push("DELETE");
      if (this.generalListRight.read) methods.push("GET");

      this.users[this.selectedUserIdx].rights.push({
        application: "pbc",
        object: "pbc-list",
        values: ["*"],
        methods: methods,
      });
    }

    this.users[this.selectedUserIdx].rights = this.users[
      this.selectedUserIdx
    ].rights.filter(
      (r) =>
        r.methods !== undefined &&
        r.methods.length !== 0 &&
        r.values !== undefined &&
        r.values.length !== 0
    );

    this.setRight(WSUserType.SUPER_ADMIN, this.superAdmin, ["*"], ["GET"]);

    if (!this.superAdmin) this.rightsAdmin = false;

    this.setRight(
      WSUserType.RIGHTS,
      this.rightsAdmin || this.superAdmin,
      ["*"],
      ["GET", "PUT", "POST", "DELETE"]
    );

    this.setRight(
      WSUserType.USER_DATA,
      this.rightsAdmin || this.superAdmin,
      ["*"],
      ["GET", "PUT", "POST", "DELETE"]
    );

    if (!this.rightsAdmin && !this.superAdmin) {
      this.setRight(
        WSUserType.USER_DATA,
        true,
        [this.users[this.selectedUserIdx].name],
        ["GET", "PUT", "POST", "DELETE"]
      );
    }

    this.clients.forEach((client) => {
      if (client.read) {
        if (
          this.selectedUserData.data.mailNotifications[client.name] ===
          undefined
        ) {
          this.selectedUserData.data.mailNotifications[client.name] =
            new MailNotificationConfig();
        }
      } else {
        this.selectedUserData.data.mailNotifications[client.name] = undefined;
      }
    });

    const filteredRights = this.users[this.selectedUserIdx].rights.filter(
      (right) => right.object !== "pbc-list-id"
    );

    const newRights = this.lists
      .filter((list) => list.hasRight)
      .map((list) => ({
        object: "pbc-list-id",
        application: "pbc",
        methods: ["GET", "PUT", "POST", "DELETE"],
        values: [list.id, "pbc-list" + list.client],
      }));

    this.users[this.selectedUserIdx].rights = filteredRights;
    this.users[this.selectedUserIdx].rights.push(...newRights);

    this.saveUser();
  }

  isSelectedUserLoggedIn() : boolean {
    return this.userService.getUserName() === this.users[this.selectedUserIdx].name;
  }

  wasUserNameChanged() : boolean {
    return this.selectedUserData.name != this.selectedUserData.data.mail;
  }

  saveUser() {
    const userNameChanged = this.wasUserNameChanged();
    this.userConfService
      .putUserData(
        this.selectedUserData,
        this.users[this.selectedUserIdx].name,
        this.userService.token
      )
      .subscribe(
        (success) => {
          this.selectedUserData = UserData.fromJSON(success);
          this.users[this.selectedUserIdx].name = this.selectedUserData.name;
          let merge = false;
          if (
            this.authService.isRightsAdmin() &&
            !this.authService.isSuperAdmin()
          )
            merge = true;

          const update = () => {
            this.userConfService
              .putUser(
                User.fromJSON(this.users[this.selectedUserIdx]),
                merge,
                this.userService.token
              )
              .subscribe((success) => {
                this.users[this.selectedUserIdx] = User.fromJSON(success);
                this.notifS.success(
                  "user-saved",
                  "Benutzerdaten wurden gespeichert!",
                  NotifierIcons.OK,
                  3000
                );
                this.determineItemAllFlags(this.clients);
                this.pbcListService.get();
                this.isSaving = false;
                if(this.isSelectedUserLoggedIn() && userNameChanged)
                  this.userService.logout();
              });
          };

          update();
        },
        (error) => {
          // TODO: ROLLBACK THE USER NAME CHANGE IF IT WAS CHANGED!!
          if (error.status === 304) {
            window.alert(
              "Es konnte nicht gespeichert werden. Die E-Mail ist bereits vergeben!"
            );
            this.isSaving = false;
          } else {
            window.alert(
              `Ein Fehler ist aufgetreten: \nDetails ${JSON.stringify(error)}`
            );
            this.isSaving = false;
          }
        }
      );
  }

  setClientAllRights(right: string, value: boolean) {
    switch (right) {
      case "entry_create":
        this.clients = this.clients.map((c) => {
          c.entry.create = value;
          return c;
        });
        break;
      default:
        this.clients = this.clients.map((c) => {
          const newC = {};
          newC[right] = value;
          return { ...c, ...newC };
        });
    }
  }

  setRight(right: string, state: boolean, values: string[], methods: string[]) {
    if (state) {
      this.users[this.selectedUserIdx].rights = this.users[
        this.selectedUserIdx
      ].rights.filter((r) => r.object !== right);

      this.users[this.selectedUserIdx].rights.push({
        application: "pbc",
        object: right,
        values: values,
        methods: methods,
      });
    } else
      this.users[this.selectedUserIdx].rights = this.users[
        this.selectedUserIdx
      ].rights.filter((r) => r.object !== right);
  }

  logRight(obj: string, field: "methods" | "values") {
    console.table(
      this.users[this.selectedUserIdx].rights[
        this.users[this.selectedUserIdx].getRightIdxByObject(obj)
      ][field]
    );
  }

  deleteUser() {
    if (confirm("Wollen Sie den User wirklich löschen?")) {
      this.userConfService
        .deleteUser(
          this.users[this.selectedUserIdx]._id,
          this.userService.token
        )
        .subscribe((success) => {
          if (success.userId === this.users[this.selectedUserIdx]._id)
            this.onDelete.emit(this.users[this.selectedUserIdx]._id);
          this.selectedUserIdx = "0";
        });
    }
  }

  hijack(username: string) {
    this.adminService.hijackUser(username).subscribe((data) => {
      const token = data.token;
      this.userService.autoRefreshWithToken(token);
    });
  }

  openOnBoardingMail() {
    const mail = this.selectedUserData.data.mail;
    if (mail) {
      this.userConfService
        .generateInitPasswordLink(this.userService.token, mail)
        .subscribe((resp) => {
          if (resp.body.hasOwnProperty("url")) {
            const magicLink = resp.body["url"];
            const body = `Guten Tag Herr/Frau ${UserData.fromJSON(
              this.selectedUserData
            ).resolveResponsible()},
\ndies ist eine Einladung zur Zusammenarbeit auf unserer Digitalplattform.
\nSie können optional Benachrichtigungen einschalten, indem Sie oben rechts im Menü, neben der "Abmelden" Schaltfläche auf
\nEinstellungen klicken. Dort können Sie im Einzelnen einstellen welche Benachrichtigungen Sie erhalten wollen.
\nBitte vergeben Sie unter folgendem Link Ihr Kennwort (der Link ist 24 Stunden verfügbar): ${magicLink} .
\nViel Erfolg`;

            window.location.href = `mailto:${mail}?body=${encodeURIComponent(
              body
            )}&subject=${encodeURIComponent(
              "Herzlich willkommen auf unserer Digitalplattform"
            )}`;
          }
        });
    } else {
      alert(this.tr.instant("USER_RIGHTS.ERROR.NO_MAIL"));
    }
  }

  statusText = Entry.statusText;
}
