import { PBCList } from "./PBCList"

export class Group {
  constructor(public name: string, public parentName?:string, public id?: string) {}
}

export type TRootedGroup = {
  group: Group
  children?: TRootedGroup[]
  id?: string;
}

export type TRootedGroupList = {
  group: Group,
  children?: TRootedGroupList[]
  lists: PBCList[];
}

export class RootedGroupList {
  group: Group;
  children?: RootedGroupList[];
  lists: PBCList[];

  constructor(group: TRootedGroupList) {
    this.group = group.group;
    this.lists = group.lists != undefined ? group.lists : [];
    this.children = group.children != undefined ? group.children.map(grpl => new RootedGroupList(grpl)) : [];

  }

  static createFromRootedGroupList(grp: TRootedGroupList) : RootedGroupList {
    return new RootedGroupList(grp);
  }

  addListAt(rootPath: TRootedGroupList, list: PBCList) {
    if(list.client === this.group.name) this.lists.push(list);
    else {
      const child = this.children.find(child => rootPath.children != undefined && rootPath.children[0] != undefined && child.group.name === rootPath.children[0].group.name);
      if(child != undefined) child.addListAt(rootPath.children[0], list);
      else {
        this.children.push(new RootedGroupList(rootPath.children[0]));
        this.children.find(child => child.group.name === rootPath.children[0].group.name).addListAt(rootPath.children[0], list);
      }
    }
  }

  static setArchiveStateForListId(rootPath: TRootedGroupList, listId: String, newArchivedState: boolean) {
    const idx  = rootPath.lists.findIndex(el => el.id === listId);
    if(idx != -1) {
      rootPath.lists[idx].archived = newArchivedState;
    }
    else if(rootPath.children != undefined) {
      rootPath.children = rootPath.children.map<TRootedGroupList>(child => RootedGroupList.setArchiveStateForListId(child, listId, newArchivedState));
    }

    return rootPath;
  }

  // applies function on the given `root`s lists if lists contains the list with the id listId.
  // can be used for filtering or manipulating a certain list
  static applyFnToListsById(root: TRootedGroupList, listId: string, fn = (list:PBCList[]) => list) : TRootedGroupList {
    const foundList = root.lists.find(list => list.id === listId);
    if(foundList !== undefined) {
      root.lists = fn(root.lists);
    }
    else if(foundList === undefined) {
      root.children = root.children.map(child => {
        return RootedGroupList.applyFnToListsById(child, listId, fn);
      });
    }

    return root;
  }
}
