import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Authorization, User } from "src/app/_core/User";
import UserData from "src/app/_core/UserData";
import { UserService } from "src/app/_services/user.service";
import * as bcrypt from "bcryptjs";
import { AuthorizationService } from "src/app/_services/authorization.service";
import { UserConfigService } from "src/app/_services/user-config.service";
import { OperationalError } from "cypress/types/bluebird";

@Component({
  selector: "user-create",
  templateUrl: "./user-create.component.html",
  styleUrls: ["./user-create.component.scss"],
})
export class UserCreateComponent implements OnInit {
  user: User = User.empty();
  userData: UserData = UserData.empty("");
  plainTextPw: string = "";
  selectedTenant: string = "";
  tenants: string[];
  @Output() onUserAdded: EventEmitter<{ user: User; data: UserData }> =
    new EventEmitter();
  constructor(
    private userService: UserService,
    private userConfService: UserConfigService,
    private authService: AuthorizationService
  ) {}

  ngOnInit() {
    if (this.authService.isSuperAdmin()) {
      this.userConfService.getTenantList(this.userService.token).subscribe(
        (success) => {
          this.tenants = success.body;
        },
        (error) => {}
      );
    }

    const preselected = this.authService.getTenant();
    if (preselected === undefined) this.selectedTenant = "";
    else this.selectedTenant = preselected[0];
  }

  validateData(): boolean {
    if (this.user.name == "") return false;
    return true;
  }

  async addUser() {
    this.user.name = this.userData.data.mail;

    if (!this.validateData()) {
      return;
    }

    this.setUserRights();
    await this.generateHash();
    this.registerUser();
  }

  registerUser() {
    this.userService.register(this.user).subscribe(
      (response) => {
        switch (response.status) {
          case 226:
            alert("Der Benutzername ist bereits vergeben!");
            break;

          case 200:
            this.userData.name = this.user.name;
            this.user._id = response.body._id;

            this.userConfService
              .postUserData(this.userData, this.userService.token)
              .subscribe((response) => {
                this.onUserAdded.emit({
                  user: this.user,
                  data: response.body,
                });
              });
            break;

          default:
            alert(
              "Fehler: <" +
                response.status +
                " = " +
                response.statusText +
                "> ist aufgetreten"
            );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setUserRights() {
    const rSetState: Authorization = {
      object: "entry-state",
      methods: ["PUT"],
      values: ["closed", "open", "not-needed", "feedback"],
      application: "pbc",
    };
    const rWriteComment: Authorization = {
      object: "entry-comment",
      methods: ["POST", "DELETE"],
      values: ["*"],
      application: "pbc",
    };
    const rModifyOwnUserData: Authorization = {
      object: "USER_DATA",
      methods: ["GET", "PUT"],
      values: [this.user.name],
      application: "pbc",
    };

    const rTenant: Authorization = {
      object: "tenant",
      methods: ["GET"],
      values: [this.selectedTenant],
      application: "pbc",
    };

    if(this.selectedTenant !== undefined && this.selectedTenant != "")
      this.user.rights.push(rTenant);

    this.user.rights.push(rSetState, rWriteComment, rModifyOwnUserData);
  }

  async generateHash(): Promise<string> {
    return new Promise((resolve, reject) => {
      bcrypt.hash(this.plainTextPw, 12).then((pw) => {
        this.user.password = pw;
        return resolve(pw);
      });
    });
  }

  tenantChanged(newTenant: string) {
    this.selectedTenant = newTenant;
  }
}
