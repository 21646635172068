export enum WSPBCListType {
  ALL = "pbc-lists",
  ONE = "pbc-list",
  CLIENTS_ALL = "clients-all",
  RESPONSIBLES = "responsibles",
  COPY = "copy-list",
  ENTRY_RIGHTS = "entry-rights",
  LIST_RIGHTS = "list-rights",
  ARCHIVE = "archive-list",
  UNARCHIVE = "unarchive-list"
}

export enum WSUserType {
  RIGHTS = "USER_AUTHORIZATIONS",
  SUPER_ADMIN = "SUPER_ADMIN",
  USER_DATA = "USER_DATA",
  USERS_ALL = "users_all",
  USERS = "users"
}
