import { CustomEntryField } from "./CustomEntryField";

export class EntryTemplate {
  type: string;
  showResponsibles: boolean = true;
  showCommentary: boolean = true;
  showFileUpload: boolean = true;
  showUserCommentary: boolean = true;
  showStartDate: boolean = true;
  showEndDate: boolean = true;
  showStatusChangeDate: boolean  = true;
  showDescription: boolean = true;
  showStatus: boolean = true;
  fields: CustomEntryField[];

  static fromJson(source){
    const e = new EntryTemplate();

    e.type = source.type;
    e.showResponsibles = source.showResponsibles == undefined ? true :  source.showResponsibles;
    e.showCommentary = source.showCommentary == undefined ? true :  source.showCommentary;
    e.showFileUpload = source.showFileUpload == undefined ? true :  source.showFileUpload;
    e.showUserCommentary = source.showUserCommentary == undefined ? true :  source.showUserCommentary;
    e.showStartDate = source.showStartDate == undefined ? true :  source.showStartDate;
    e.showEndDate = source.showEndDate == undefined ? true :  source.showEndDate;
    e.showStatusChangeDate = source.showStatusChangeDate == undefined ? true :  source.showStatusChangeDate;
    e.showDescription = source.showDescription == undefined ? true :  source.showDescription;
    e.showStatus = source.showStatus == undefined ? true :  source.showStatus;

    if(source.fields != undefined)
      e.fields = source.fields.map(CustomEntryField.fromJSON);

    return e;
  }
}
