import { OutputHashing } from "@angular-devkit/build-angular";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Category } from "src/app/_core/Category";
import { Entry } from "src/app/_core/Entry";
import { EntryTemplate } from "src/app/_core/EntryTemplate";
import UserData from "src/app/_core/UserData";
import { WSMethods } from "src/app/_services/web-socket.service";
import { EntriesMoveService } from "../services/entries-move.service";

@Component({
  selector: "category-edit-create",
  templateUrl: "category-edit-create.component.html",
  styles: [`
`],
})
export class CategoryEditCreateComponent implements OnInit {
  @Input() category;
  @Input() responsibles: UserData[];
  @Input() defaultEntryTemplate: EntryTemplate;
  @Input() isDownloading: boolean = false;
  @Input() categoryIndex: string;
  @Input() entryTemplates: EntryTemplate[];
  @Output() onChange = new EventEmitter<Category>(); // TODO: should be categoryChange event
  @Output() onFileAction = new EventEmitter<{
    formData: FormData;
    action: WSMethods;
    callbackFn: (arg0: any) => void;
  }>();
  @Output() onMove: EventEmitter<Category> = new EventEmitter<Category>();
  @Output() onDownload = new EventEmitter<string[] | number>();
  collapsed: boolean = false;
  isSelectedForMoving: boolean = true;
  selectedEntriesForMoving: string[] = [];
  isAllSelectedForMoving: boolean = false;

  constructor(public moveEntries: EntriesMoveService) {
    moveEntries.onSelect.subscribe(this.setMoveSelectionState);
    moveEntries.onDeselect.subscribe(this.setMoveSelectionState);
    moveEntries.onMoveFinish.subscribe(this.setMoveSelectionState);
    moveEntries.onUpdateState.subscribe(this.setMoveSelectionState);
  }
  ngOnInit(): void {}

  addEntry(pos: number = 0) {
    if (this.category.entries == undefined) {
      this.category.entries = [];
    }

    const newEntry =
      this.defaultEntryTemplate === undefined
        ? new Entry()
        : Entry.createFromTemplate(this.defaultEntryTemplate);
    newEntry.modificationDate = new Date();
    this.category.entries.splice(pos, 0, newEntry);
    this.onChange.emit(this.category);
  }

  entryDelete(pos: number) {
    if (this.category.entries == undefined) {
      this.category.entries = [];
    }

    this.category.entries.splice(pos, 1);
    this.onChange.emit(this.category);
  }

  move(isSelected: boolean) {
    if (isSelected) this.moveEntries.select(this.category.entries);
    else this.moveEntries.deselect(this.category.entries);
  }

  setMoveSelectionState = (_: Entry[] | void) => {
    this.isSelectedForMoving =
      (this.moveEntries.selectedCategory == this.categoryIndex &&
        this.moveEntries.selectedEntries.length > 0) ||
      this.moveEntries.selectedEntries.length == 0;

    this.selectedEntriesForMoving = this.moveEntries.selectedEntries.map(
      (ent) => ent.internalID
    );

    this.isAllSelectedForMoving =
      this.moveEntries.selectedEntries.length ===
        this.category.entries.length &&
      this.moveEntries.selectedEntries.length != 0 &&
      this.categoryIndex == this.moveEntries.selectedCategory;
  };

  categoryChanged(category: Category, pos: number) {
    this.category.categories[pos] = category;
    this.onChange.emit(this.category);
  }

  entryChanged(entry: Entry, pos: number) {
    this.category.entries[pos] = entry;
    this.onChange.emit(this.category);
  }

  fileAction(
    formData: FormData,
    action: WSMethods,
    callbackFn: (arg: any) => void
  ) {
    this.onFileAction.emit({
      formData: formData,
      action: action,
      callbackFn: callbackFn,
    });
  }

  download() {
    this.onDownload.emit(this.category.getAllChildEntryIds());
  }

  moveUp(pos: number) {
    const left = this.category.entries.slice(0, pos);
    const right = this.category.entries.slice(
      pos + 1,
      this.category.entries.length
    );
    this.category.entries = [
      ...left.slice(0, pos - 1),
      this.category.entries[pos],
      left[left.length-1],
      ...right,
    ];

    this.onMove.emit(this.category);
  }

  moveDown(pos: number) {
    const left = this.category.entries.slice(0, pos);
    const right = this.category.entries.slice(
      pos + 1,
      this.category.entries.length
    );
    this.category.entries = [
      ...left,
      right[0],
      this.category.entries[pos],
      ...right.slice(1, right.length),
    ];

    this.onMove.emit(this.category);
  }
}
