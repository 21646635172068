import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "../_core/User";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  static baseURL: string = environment.protocol + "://" + environment.authHost + ":" + environment.authPort;
  authHeader: HttpHeaders;

  constructor(private http:HttpClient, private userService: UserService) {
    this.authHeader = new HttpHeaders().append("Authorization", "Bearer " + this.userService.token);
  }

  getAuthorizations() : Observable<User[]> {
    return this.http.get<User[]>(AdministrationService.baseURL + "/users/authorizations/pbc", {headers: this.authHeader}).pipe(map(users => users.map(user => User.fromJSON(user))));
  }

  hijackUser(userName: string) : Observable<{ token: string }> {
    return this.http.post<{ token: string }>(AdministrationService.baseURL + `/hijack/${userName}/pbc`, {},{headers: this.authHeader});
  }
}
