import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDialogService } from './modal-dialog.service';

@Component({
  selector: 'modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialog implements OnInit {
  @Input() entryId: string;
  isShown: boolean = false;

  @ViewChild('root', {static: true}) rootElem: any;

  constructor(private mds: ModalDialogService) {
  }

  ngOnInit() {
  }

  show(at: {x: number, y: number }) {
    this.mds.open(this);
    this.rootElem.nativeElement.style.left = at.x + 'px';
    this.rootElem.nativeElement.style.top = at.y + 'px';

    this.isShown = true;
  }

  hide() {
    this.isShown = false;
  }

}
