import { HttpHeaders, HttpParams } from "@angular/common/http";

export const formData2HttpParams = (fd: FormData) : HttpParams => {
  let par = new HttpParams();
  fd.forEach((v,k) => par = par.append(k, v.toString()));
  return par;
}

export const generateAuthHeader = (token: any): HttpHeaders => {
  let authHeader = new HttpHeaders({ Authorization: "Bearer " + token });
  return authHeader;
}
