import { createReducer, on } from "@ngrx/store";
import { ListTemplate } from "src/app/_core/ListTemplate";
import { ListTemplateActions } from "./list-template.actions";

export interface ListTemplateState {
  listTemplates: ListTemplate[];
  status: "init" | "loading" | "success" | "error";
}

const initialState: ListTemplateState = {
  listTemplates: [],
  status: "init",
};

export const listTemplateReducer = createReducer(
  initialState,
  on(ListTemplateActions.getAll, (state) => ({ ...state, status: "loading" })),
  on(ListTemplateActions.getAllSuccess, (state, { listTemplates }) => ({
    ...state,
    listTemplates: listTemplates,
    status: "success",
  }))
);
