export default class UserData {
  _id:string;
  name:string;
  forApp: string;
  data: Data;
  user_id: string;

  static fromJSON(source: any): UserData {
    const ud = new UserData();
    ud._id = source._id;
    ud.user_id = source.user_id;
    ud.forApp = source.forApp;
    ud.name = source.name;
    if(source.data != undefined) ud.data = Data.fromJSON(source.data);

    return ud;
  }

  resolveResponsible() : string {
    if(this.data != undefined && this.data.surname != undefined && this.data.forename != undefined)
      return this.data.forename + " " + this.data.surname;
    else return this.name;
  }

  static empty(username:string): UserData {
    const u = new UserData();
    u.data = Data.empty();
    u.forApp = "pbc";
    u.name = username;

    return u;
  }
}

export class Data {
  mail: string;
  forename: string;
  surname: string;
  mailNotifications: {[clientName: string]: MailNotificationConfig};

  static fromJSON(source: any) : Data {
    const da = new Data();
    da.mail = source.mail;
    da.forename = source.forename;
    da.surname = source.surname;
    da.mailNotifications = source.mailNotifications;

    return da;
  }

  static empty() : Data {
    const u = new Data();
    u.mail = "";
    u.mailNotifications = {};
    return u;
  }
}

export class MailNotificationConfig {
  comments: { active: boolean, forAll: boolean } = {active: false, forAll: false};
  newEntry: { active: boolean, forAll: boolean } = {active: false, forAll: false};

  status: { active: boolean, forAll: boolean } = {active: false, forAll: false};
  files: { active: boolean, forAll: boolean } = {active: false, forAll: false};
}
