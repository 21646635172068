import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "src/app/shared/Shared.module";
import { UserService } from "src/app/_services/user.service";
import { GroupEditComponent } from "./group-edit/group-edit.component";
import { GroupNodeComponent } from "./group-edit/node.component";
import { GroupsComponent } from "./groups/groups.component";

const routes: Routes = [
  {
    path: "group/edit/:name",
    component: GroupEditComponent,
  },
  {
    path: "groups/overview",
    component: GroupsComponent,
    canActivate: [UserService],
  },
];

@NgModule({
  declarations: [GroupEditComponent, GroupsComponent, GroupNodeComponent],
  imports: [CommonModule, RouterModule.forChild(routes), SharedModule],
  providers: [UserService],
  bootstrap: [GroupEditComponent],
  schemas: [],
  exports: [RouterModule],
})
export class GroupModule {}
