export class User {
  constructor(public _id:string, public name:string, public isTechnicalRole?: boolean, public password?:string, public rights: Authorization[] = []){}

  static empty(){
      return new User("","",false, "");
  }

  setName(val:string): User{
      this.name = val
      return this;
  }

  static fromJSON(source: any) : User {
    const u = new User(source._id, source.name);
    u.isTechnicalRole = source.isTechnicalRole;
    u.password = source.password;
    u.rights = source.rights;
    return u;
  }

  getRightIdxByObject(obj: string) : number {
    if(this.rights == undefined) return undefined;

    const retIdx = this.rights.findIndex(v => v.object === obj);
    if(retIdx >= 0) return retIdx;
    else return undefined;
  }

  hasValue(object: string, value: string) : boolean {
    if(this.rights == undefined || this.getRightIdxByObject(object) == undefined) return false;
    return this.rights[this.getRightIdxByObject(object)].values.includes(value);
  }

  hasMethod(object: string, method: string) : boolean {
    if(this.rights == undefined || this.getRightIdxByObject(object) == undefined) return false;
    return this.rights[this.getRightIdxByObject(object)].methods.includes(method);
  }

  hasRight(obj: string, value: string, method: string) : boolean {
    return this.rights.some(r => r.object === obj && r.values.some(v => v === value || v === '*') && r.methods.some(m => m === method || m === 'ALL' ));
  }

  addRight(object: string, value: string, method: string){
    const pos = this.getRightIdxByObject(object);
    if(pos === undefined){
      this.rights.push({
        application: 'pbc',
        object: object,
        values: [value],
        methods: [method]
      });
    }
    else {
      if(this.rights[pos].values != undefined && !this.rights[pos].values.includes(value)) this.rights[pos].values.push(value);
      else if(this.rights[pos].values === undefined) this.rights[pos].values = [value];

      if(this.rights[pos].methods != undefined && !this.rights[pos].methods.includes(method)) this.rights[pos].methods.push(method);
      else if(this.rights[pos].methods === undefined) this.rights[pos].methods = [method];

    }
  }

  addValue(object: string, value: string){
    const pos = this.getRightIdxByObject(object);
    if(pos === undefined){
      this.rights.push({
        application: 'pbc',
        object: object,
        values: [value],
        methods: []
      });
    }
    else {
      this.rights[pos].values.push(value);
    }
  }

  removeValue(object: string, value: string) {
    const pos = this.getRightIdxByObject(object);
    if(pos != undefined) this.rights[pos].values = this.rights[pos].values.filter(v => v !== value)
  }

  addMethod(object: string, method: string){
    const pos = this.getRightIdxByObject(object);
    if(pos === undefined){
      this.rights.push({
        application: 'pbc',
        object: object,
        values: [],
        methods: [method]
      });
    }
    else {
      this.rights[pos].methods.push(method);
    }
  }

  removeMethod(object: string, method: string) {
    const pos = this.getRightIdxByObject(object);
    if(pos != undefined) this.rights[pos].methods = this.rights[pos].methods.filter(v => v !== method)
  }
}

export interface Authorization {
  object: string;
  application: string;
  methods: string[];
  values: string[];
}
