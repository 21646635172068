import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class EntryTemplateService {

  constructor(private userService: UserService, private http: HttpClient) { }

  public getAll() : Observable<Object[]>{
    const params = new HttpParams().append("auth", "Bearer " + this.userService.token);
    return this.http.get<Object[]>(environment.protocol + "://" + environment.host + ":" + environment.serverPort + "/entryTemplates", {params: params});
  }
}
