import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  LOCALE_ID,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { stat } from "fs";
import { Observable } from "rxjs";
import {
  HistoryLogShowComponent,
  THistoryLogDialogClose,
} from "../history-log-show/history-log-show.component";
import { EntryEditCreateDialogComponent } from "../list-edit-create/entry-edit-create-dialog/entry-edit-create-dialog.component";
import { WSPBCListType } from "../_core/AuthObjects";
import { Category } from "../_core/Category";
import Commentary from "../_core/Commentary";
import { Entry, TEntryStatus, WSEntryType } from "../_core/Entry";
import { EntryTemplate } from "../_core/EntryTemplate";
import HistoryLogEntry from "../_core/HistoryLogEntry";
import { PBCList } from "../_core/PBCList";
import { UploadedFile } from "../_core/UploadedFile";
import UserData from "../_core/UserData";
import { AuthorizationService } from "../_services/authorization.service";
import { EntryService } from "../_services/entry.service";
import { FileController } from "../_services/file.controller";
import { FileService } from "../_services/file.service";
import { HistoryLogService } from "../_services/history-log.service";
import { ListImportService } from "../_services/list-import.service";
import { PbcService } from "../_services/pbc.service";
import { UserService } from "../_services/user.service";
import { WebSocketService, WSMethods } from "../_services/web-socket.service";
import { AppState } from "../_store/app.state";
import {
  EntryTemplateActions,
  EntryTemplateSelector,
} from "../_store/entry-template";
import { Item } from "../_uicomponents/item-picker/Item";
import {
  NotifierIcons,
  NotifierService,
} from "../_uicomponents/notifier/notifier-service";
import { StatusOverviewComponent } from "../_uicomponents/status-overview/status-overview.component";
import { cssHeight, cssRect, cssWidth } from "./../_helper/Helpers";
import { TFilterValues } from "./category-show/category-show.component";
import { TocShowComponent } from "./toc-show/toc-show.component";
import { HistoryLogComponent } from "../_uicomponents/history-log/history-log.component";

@Component({
  selector: "list-show",
  templateUrl: "./list-show.component.html",
  styleUrls: ["./list-show.component.scss"],
})
export class ListShowComponent implements OnInit, AfterContentInit {
  @Output() filterStateChanged: EventEmitter<{ field: string; value: string }> =
    new EventEmitter<{ field: string; value: string }>();
  list: PBCList;
  responsibles: UserData[] = [];
  selectedCategory: Category = undefined;
  selectedCategoryIndex: number[] = undefined;
  __filterState: TFilterValues = {
    onlyForResponsible: "",
    searchText: "",
    isDue: false,
    isOverdue: false,
    onlyForStatus: "",
    cust: {},
  };
  statusDefinitions: Item[];
  dateMonthList: Item[];
  @ViewChild(StatusOverviewComponent, { static: false })
  viewStatusOverview: StatusOverviewComponent;
  @ViewChild(TocShowComponent, { static: false }) viewTocShow: TocShowComponent;
  @ViewChildren("needsHeightOffset")
  viewsNeedHeightOffset: QueryList<ElementRef>;
  @ViewChildren("tab")
  tabs: QueryList<ElementRef>;
  @ViewChild("heightOffset", { static: false }) viewHeightOffset: ElementRef;
  @ViewChild("searchFilterBar", { static: false })
  viewSearchFilterBar: ElementRef;
  @ViewChild("importFileRef", { static: false }) viewFileImport: ElementRef;
  @ViewChild(HistoryLogComponent, { static: false }) viewHistoryLog: HistoryLogComponent;
  refreshed: boolean = true;
  isDownloading = false;
  __isDataLoaded = false;
  isStatusShown = false; // status is either just faded in or above content if isStatusFixed == true
  isStatusPinned = false; // status overview is pinned and embedded without overlapping the content
  isStatusFixed = false; // status overview is floating above the other content but doesn't fade back
  isHistoryLogShown = false;
  isHistoryLogPinned = false;
  isHistoryLogFixed = false;
  locale = "";
  TEntryStatus = TEntryStatus;
  listArchived = false;
  cssHeight = cssHeight;
  cssWidth = cssWidth;
  fileController: FileController;
  revisionLoaded = false;
  revisionNumber = 0;

  entryTemplates$: Observable<EntryTemplate[]>;

  constructor(
    private route: ActivatedRoute,
    private pbcService: PbcService,
    private entryService: EntryService,
    private socket: WebSocketService,
    public userService: UserService,
    private fileService: FileService,
    private logService: HistoryLogService,
    private changeRef: ChangeDetectorRef,
    private translator: TranslateService,
    public authService: AuthorizationService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) locale,
    private router: Router,
    private historyLogService: HistoryLogService,
    private listImportService: ListImportService,
    private notifService: NotifierService,
    private store: Store<AppState>
  ) {
    this.fileController = new FileController(fileService);

    this.locale = locale;
    this.socket.message((e) => {
      let message = JSON.parse(e.data);
      const { data, info, __sender__ } = message;

      let historyLogEntry: HistoryLogEntry;
      if (info.__status === 401) {
        //router.navigate(["./"]); //TODO: Checken wann wieder eingebunden werden kann
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.ONE &&
        message.data.list != undefined
      ) {
        const temp_list = PBCList.fromJSON(message.data.list);
        if (this.list == undefined || this.list.id === temp_list.id) {
          this.list = temp_list;
          this.isDataLoaded = true;
          this.pbcService.getResponsiblesForClient(this.list.client);
          setTimeout(() => {
            this.positionHTMLElements();
            this.positionTabElements();
          }, 1000);

          if (this.list.type === "FiBu")
            this.changeFilterState("onlyForStatus", TEntryStatus.opened);

          this.listArchived = this.list.archived;

          this.logService.clear();
          if (message.data.log != undefined)
            this.logService.addAll(message.data.log);

        }
      } else if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.RESPONSIBLES
      ) {
        this.responsibles = Array.from(message.data).map(UserData.fromJSON);
      } else if (
        info.__method == WSMethods.PUT &&
        info.__type == WSEntryType.STATE &&
        this.list.id == data.list_id
      ) {
        let index_list = this.list.findEntryPosition(data.entry_id);
        this.setEntryStateAt(data.state, data.date, index_list);
        this.logService.addFromResponse(message);
        this.viewStatusOverview.refresh();

        // to trigger filtering again
        const state = this.__filterState["onlyForStatus"];
        this.changeFilterState("onlyForStatus", "");
        this.changeFilterState("onlyForStatus", state);
      } else if (
        info.__method == WSMethods.POST &&
        info.__type == WSEntryType.ONE &&
        this.list.id == data.list_id
      ) {
        let index_list = data.index_list;
        this.changeCategoryAt((cat) => {
          cat.entries.push(Entry.fromJSON(data.entry));
          this.list.setInternalIndex();
          return cat;
        }, index_list);
      } else if (
        info.__method == WSMethods.POST &&
        info.__type == WSEntryType.COMMENT &&
        this.list.id == data.list_id
      ) {
        let index_list = this.list.findEntryPosition(data.entry_id);
        this.addCommentForEntryAt(data.comment, index_list);
        this.logService.addFromResponse(message);
      } else if (
        info.__method == WSMethods.DELETE &&
        info.__type == WSEntryType.COMMENT &&
        this.list.id == data.list_id &&
        info.__status == 200
      ) {
        let index_list = this.list.findEntryPosition(data.entry_id);
        this.deleteCommentForEntryAt(data.comment, index_list);
        this.logService.addFromResponse(message);
      } else if (
        info.__method == WSMethods.POST &&
        info.__type == WSEntryType.FILE
      ) {
        let dataAr: ENTRY_FILE_POST_RESPONSE[] = data;
        dataAr.forEach((f) => {
          if (this.list.id == f.list_id) {
            this.addFileForEntryAt(f.file, f.indices);
            this.logService.addFromResponse({ data: f, info, __sender__ });
          }
        });
      } else if (
        info.__method == WSMethods.DELETE &&
        info.__type == WSEntryType.FILE &&
        this.list.id == data.list_id
      ) {
        this.logService.addFromResponse(message);
      }

      if (historyLogEntry) {
        this.logService.add(historyLogEntry);
      }
    });

    this.setupStatus();

    this.dateMonthList = new Array(12)
      .fill(0, 0)
      .map((_, i) => {
        const d = new Date();
        d.setMonth(i, 1);
        return Intl.DateTimeFormat(locale, { month: "short" }).format(d);
      })
      .map(
        (v, i) =>
          new Item(
            "#FFF",
            "#000",
            v,
            false,
            "-" + ("0" + (i + 1)).slice(-2).toString() + "-",
            () => true
          )
      );
    this.dateMonthList.unshift(
      new Item(
        "#FFF",
        "#000",
        "Bezahlt am (Monat)",
        true,
        undefined,
        () => true
      )
    );
  }

  translatePromise(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.translator.get(key).subscribe(
        (text) => resolve(text),
        (error) => reject(error)
      );
    });
  }

  setupStatus() {
    this.statusDefinitions = [
      new Item(
        "var(--color-no-filter)",
        "var(--color-text-no-filter)",
        this.translatePromise("STATES.SELECT_STATUS"),
        this.filterState.onlyForStatus == "",
        "",
        (activeState) => true
      ),
      new Item(
        "var(--color-open)",
        "var(--color-text-open)",
        this.translatePromise("STATES.OPENED"),
        TEntryStatus.opened.toLowerCase() == this.filterState.onlyForStatus
          ? true
          : false,
        TEntryStatus.opened,
        (activeState) => true
      ),
      new Item(
        "var(--color-closed)",
        "var(--color-text-closed)",
        this.translatePromise("STATES.CLOSED"),
        TEntryStatus.closed.toLowerCase() == this.filterState.onlyForStatus
          ? true
          : false,
        TEntryStatus.closed,
        (activeState) => true
      ),
      new Item(
        "var(--color-not-needed)",
        "var(--color-text-not-needed)",
        this.translatePromise("STATES.NOT_NEEDED"),
        TEntryStatus.not_needed.toLowerCase() == this.filterState.onlyForStatus
          ? true
          : false,
        TEntryStatus.not_needed,
        (activeState) => true
      ),
      new Item(
        "var(--color-accepted)",
        "var(--color-text-accepted)",
        this.translatePromise("STATES.FOR_DOWNLOAD"),
        TEntryStatus.for_download.toLowerCase() ==
        this.filterState.onlyForStatus
          ? true
          : false,
        TEntryStatus.for_download,
        (activeState) => true
      ),
      new Item(
        "var(--color-feedback)",
        "var(--color-text-feedback)",
        this.translatePromise("STATES.FEEDBACK"),
        TEntryStatus.feedback.toLowerCase() == this.filterState.onlyForStatus
          ? true
          : false,
        TEntryStatus.feedback,
        (activeState) => true
      ),
      new Item(
        "var(--color-accepted)",
        "var(--color-text-accepted)",
        this.translatePromise("STATES.ACCEPTED"),
        TEntryStatus.accepted.toLowerCase() == this.filterState.onlyForStatus
          ? true
          : false,
        TEntryStatus.accepted,
        (activeState) => true
      ),
    ];
  }

  positionHTMLElements(): void {
    const rect = cssRect(this.viewHeightOffset.nativeElement);
    if (rect != undefined || rect != null)
      this.viewsNeedHeightOffset.forEach((v) => {
        v.nativeElement.style.top = rect.top + rect.height + "px";
      });

    this.addLeftOffsetToSearchBar();
  }

  positionTabElements() : void {
    const rect = cssRect(this.viewHeightOffset.nativeElement);
    if (rect != undefined || rect != null)
      this.tabs.forEach((v, i) => {
        v.nativeElement.style.top = rect.top + rect.height + (Math.floor(i/2)*100) + "px";
      });

    this.addLeftOffsetToSearchBar();
  }

  done = false;
  addLeftOffsetToSearchBar() {
    if (!this.done) {
      const rect = cssRect(this.viewSearchFilterBar.nativeElement);
      if (rect) {
        this.viewSearchFilterBar.nativeElement.style.left =
          rect.left + 10 + "px";
        this.done = true;
      }
    }
    //this.viewSearchFilterBar.style.left = Number.isNaN(element.offsetLeft) ? Number.NaN : (Number(element.offsetLeft) + 10) + 'px';
  }

  get isDataLoaded(): boolean {
    return this.__isDataLoaded;
  }

  set isDataLoaded(isDataLoaded: boolean) {
    this.__isDataLoaded = isDataLoaded;
  }

  legendClicked(statusText: string) {
    this.changeFilterState("onlyForStatus", statusText);
  }

  ngOnInit() {
    this.store.dispatch(EntryTemplateActions.getAll());
    this.entryTemplates$ = this.store.select(EntryTemplateSelector.all);

    this.route.params.subscribe(async (params) => {
      this.pbcService.getById(params.id);
    });
  }

  ngAfterContentInit() {
    this.route.params.subscribe((params) => {
      this.pbcService.getById(params.id);

      this.selectCategory(params);
    });
  }

  selectCategory(params) {
    if (params.categoryIndex && this.viewTocShow) {
      this.onCategoryIndexSelected(params.categoryIndex.split("."));
      this.viewTocShow.select(params.categoryIndex);
    } else if (this.viewTocShow == undefined) {
      setTimeout(() => {
        this.selectCategory(params);
      }, 2000);
    }
  }

  openNewEntryDialog() {
    this.dialog.open(EntryEditCreateDialogComponent, {
      width: "80%",
      data: {
        responsibles: this.responsibles,
        pbcListId: this.list.id,
        categoryIndexList: this.selectedCategoryIndex,
        categoryName:
          this.selectedCategory != undefined
            ? this.selectedCategory.description
            : undefined,
        rootCategories: this.list.categories,
        entryTemplateType: this.list.defaultEntryType,
      },
    });
  }

  openImportFileDialog() {
    this.viewFileImport.nativeElement.click();
  }

  set filterState(filterState: TFilterValues) {
    setTimeout(() => (this.__filterState = filterState), 0);
  }

  get filterState(): TFilterValues {
    return this.__filterState;
  }

  importEntryCSV(files: FileList) {
    this.listImportService.entryCSV(files).then((entries) => {
      entries.forEach((entry) =>
        this.entryService.postEntry(
          this.list.id,
          this.selectedCategoryIndex,
          entry
        )
      );
      window.alert("Es wurden " + entries.length + " Einträge hochgeladen!");
    });
  }

  changeFilterState(field: string, value: string) {
    if (field.startsWith("cust."))
      this.filterState.cust[field.replace("cust.", "")] = value;
    else this.filterState[field] = value;
    console.log(field, value);

    setTimeout(
      () =>
        (this.filterState = Object.assign(
          {},
          this.filterState
        ) as TFilterValues),
      0
    );
  }

  onCategorySelected(event) {
    this.filterState = Object.assign({}, this.filterState);
    delete this.selectedCategory;
    this.selectedCategory = event;
    this.changeRef.detectChanges();
  }

  onCategoryIndexSelected(event) {
    this.filterState = Object.assign({}, this.filterState);
    this.selectedCategoryIndex = event;
    this.changeRef.detectChanges();
  }

  deselectCategory() {
    this.selectedCategory = undefined;
    this.selectedCategoryIndex = undefined;
    this.viewTocShow.deselect();
  }

  entryChanged(
    changeData: string | any,
    changeType: WSEntryType,
    newEntry: Entry,
    method?: WSMethods
  ) {
    /*if(this.selectedCategory != undefined){
      iEntryPos = this.selectedCategoryIndex.concat(iEntryPos)
    }*/

    let commentIndex = -1;
    if (changeType == WSEntryType.COMMENT && method == WSMethods.DELETE) {
      commentIndex = changeData["commentIdx"];
    }
    let changedEntry = newEntry;

    if (changeType == WSEntryType.STATE)
      this.entryService.putState(this.list.id, changeData, changedEntry.status);
    else if (changeType == WSEntryType.COMMENT && method == WSMethods.POST)
      this.entryService.postComment(
        this.list.id,
        changeData,
        changedEntry.userComment.pop()
      );
    else if (changeType == WSEntryType.COMMENT && method == WSMethods.DELETE)
      this.entryService.deleteComment(
        this.list.id,
        changeData["entryId"],
        changedEntry.userComment[commentIndex]
      );
    else if (changeType == WSEntryType.CUSTOMFIELD) {
      // TODO: here the some custom field was updated (basically it is an entry.put method)
      // TODO: check if that entrypoint exists in the backend
      // TODO: eval how the rights should be managed (should it be differtiated or have a simple post on entry)
    } else if (changeType == WSEntryType.ONE && method == WSMethods.PUT) {
      let listIdx = this.list.findEntryPosition(newEntry.internalID);
      this.entryService.putEntry(this.list.id, listIdx, newEntry);
    }
  }

  // does not modify the indices array!
  getEntryAtIndices(indices: number[]): Entry {
    let posOfEntry: number = indices.slice(-1)[0];
    let firstCategory: number = indices.slice(0)[0];
    let activeCategory = this.list.categories[firstCategory];
    indices.slice(1, -1).forEach((v) => {
      activeCategory = activeCategory.categories[v];
    });

    return activeCategory.entries[posOfEntry];
  }

  changeEntryAt(change: (e: Entry) => Entry, indices: number[]) {
    //this.changeSelectedAt(change, indices);

    const entryPos = indices.pop();
    const firstCategory = indices.shift();
    let activeCategory = this.list.categories[firstCategory];
    indices.forEach(
      (element) => (activeCategory = activeCategory.categories[element])
    );
    activeCategory.entries[entryPos] = change(activeCategory.entries[entryPos]);
  }

  changeCategoryAt(change: (c: Category) => Category, indices: number[]) {
    const lastCatPos = indices.pop();
    let activeCategories = this.list.categories;
    indices.forEach(
      (element) => (activeCategories = activeCategories[element].categories)
    );
    activeCategories[lastCatPos] = change(activeCategories[lastCatPos]);
  }

  setEntryAt(newEntry: Entry, indices: number[]) {
    this.changeEntryAt((_) => {
      return newEntry;
    }, indices);
  }

  // changes the entry of this.selectedCategory at the given indice position without modifying the indices array
  changeSelectedAt(change: (Entry) => Entry, indices: number[]) {
    if (this.selectedCategory != undefined) {
      let posOfEntry: number = indices.slice(-1)[0];
      let firstCategory: number = indices.slice(0)[0];
      let activeCategory = this.list.categories.find(
        (cat) => cat.relativeIndex() == firstCategory
      );
      if (activeCategory != undefined) {
        indices.slice(1, -1).forEach((v) => {
          if (activeCategory != undefined)
            activeCategory = activeCategory.categories.find(
              (cat) => cat.relativeIndex() == v
            );
          else activeCategory = undefined;
        });
      } else activeCategory = undefined;

      if (activeCategory != undefined)
        change(
          activeCategory.entries.find((e) => e.relativeIndex() == posOfEntry)
        );
    }
  }

  setEntryStateAt(newState: TEntryStatus, date: number, indices: number[]) {
    this.changeEntryAt((entry: Entry) => {
      entry.status = newState;
      entry.receivedDate = new Date(date);
      return entry;
    }, indices);
  }

  addCommentForEntryAt(comment: Commentary, indices: number[]) {
    this.changeEntryAt((entry: Entry) => {
      entry.userComment.unshift(comment);
      return entry;
    }, indices);
  }

  deleteCommentForEntryAt(comment: Commentary, index_list: any) {
    this.changeEntryAt((entry: Entry) => {
      const pos = entry.userComment.findIndex(
        (v) =>
          v.text == comment.text &&
          v.user == comment.user &&
          v.timestamp == comment.timestamp
      );
      entry.userComment.splice(pos, 1);
      return entry;
    }, index_list);
  }

  addFileForEntryAt(newFile: UploadedFile, indices: number[]) {
    this.changeEntryAt((entry: Entry) => {
      const pos = this.fileExists(newFile, entry);
      if (pos < 0) entry.files.push(newFile);
      else entry.files[pos] = newFile;

      return entry;
    }, indices);
  }

  removeFileForEntryAt(oldFile: any, indices: any) {
    this.changeEntryAt((entry: Entry) => {
      const pos = this.fileExists(oldFile, entry);
      if (pos >= 0) entry.files.splice(pos, 1);
      return entry;
    }, indices);
  }

  fileExists(newFile: UploadedFile, entry: Entry): number {
    return entry.files.findIndex(
      (file) => file.internalName == newFile.internalName
    );
  }

  showFloatingStatus() {
    this.isStatusPinned = !this.isStatusPinned;
    setTimeout(() => this.positionTabElements(), 200);
  }

  showHistoryLog() {
    this.isHistoryLogPinned = !this.isHistoryLogPinned;
    setTimeout(() => this.positionTabElements(), 200);
  }

  fadeInHistoryLog() {
    this.isHistoryLogShown = true;
    this.viewHistoryLog.setHeight();
  }

  showListNumbers(): boolean {
    return this.list.customListSettings["showListNumbers"] == undefined ||
      this.list.customListSettings["showListNumbers"] == "false"
      ? false
      : true;
  }

  showHistory() {
    this.loadHistory().then((revisions) => {
      revisions = revisions.sort((a, b) => a.timestamp - b.timestamp);

      let dialogRef = this.dialog.open(HistoryLogShowComponent, {
        width: "800px",
        height: "600px",
        data: {
          revisions: revisions,
          selected: this.revisionLoaded ? this.revisionNumber : undefined,
        },
      });

      dialogRef.afterClosed().subscribe((ret: THistoryLogDialogClose) => {
        const result = revisions[ret.revisionNumber];

        if (ret.recoveryType == "normal")
          this.historyLogService
            .getLogEntryAt(this.list.id, result.timestamp)
            .then(
              (list: string) => {
                this.recoverListFromRevision(list, ret);
              },
              (error) => {
                this.notifService.error(
                  "version loding error",
                  JSON.stringify(error),
                  NotifierIcons.EXCALAMATION,
                  3000
                );
                this.revisionLoaded = false;
              }
            );
        else if (ret.recoveryType === "complete")
          this.historyLogService
            .getListByLogFromTimestamp(this.list.id, result.timestamp)
            .then(
              (list: string) => {
                this.recoverListFromRevision(list, ret);
              },
              (error) => {
                this.notifService.error(
                  "version loding error",
                  JSON.stringify(error),
                  NotifierIcons.EXCALAMATION,
                  3000
                );
                this.revisionLoaded = false;
              }
            );
      });
    });
  }

  recoverListFromRevision(list: string, revision: THistoryLogDialogClose) {
    try {
      this.list = PBCList.fromJSON(JSON.parse(list));
    }
    catch(e) {
      this.list = PBCList.fromJSON(list);
    }
    this.notifService.success(
      "version loaded",
      "Version wurde geladen",
      NotifierIcons.OK,
      300
    );
    this.revisionLoaded = true;
    this.revisionNumber = revision.revisionNumber;
  }

  loadHistory() {
    return this.historyLogService.getHeaderInfo(this.list.id);
  }

  loadLatestVersion() {
    this.pbcService.getById(this.list.id);
    this.revisionLoaded = false;
  }

  saveLoadedVersion() {
    this.pbcService.put(this.list);
    this.revisionLoaded = false;
  }
}

type ENTRY_FILE_POST_RESPONSE = {
  file: UploadedFile;
  status: number;
  list_id: string;
  indices: number[];
};
