

import { Router } from "@angular/router";
export class IdxCollection {
  static toArray<T>(coll:TIndexedCollection<T>, convert:(name: string, obj: any) => any) : any[] {
    const arr = [];
    for(const attr in coll){
      arr.push(convert(attr, coll));
    }

    return arr;
  }

  static map<T>(coll:TIndexedCollection<T>, apply:(name: string, obj: any) => any) : TIndexedCollection<T> {
    for(const attr in coll){
      coll[attr] = apply(attr, coll)
    }

    return coll;
  }
}

type TIndexedCollection<T> = {[ix: string]: T};

export const ConvertLocaleStringNumber = (strNum: string, decimalSep:string, groupSep:string) : number => {
  strNum = replaceAll(strNum, groupSep, "");
  strNum = replaceAll(strNum, decimalSep, ".");
  return +strNum;
};

export const replaceAll = (ttext:string, replace:string, twith:string) : string => {
  if(ttext.includes(replace)){
    return replaceAll(ttext.replace(replace, twith), replace, twith);
  }
  else return ttext;
};

export const updatePropertyByDotSeparatedString = (object: any, field: string, value: any) : any => {
  let fields = field.split(".");
  if(fields.length > 1){
    let next = fields.shift();
    object[next] = updatePropertyByDotSeparatedString(object[next], fields.join("."), value);
    return object;
  }
  else {
    object[field] = value;
    return object;
  }
}

export const replaceUmlauts = (text: string) : string => {
  text = replaceAll(text, "ä", "&auml");
  text = replaceAll(text, "ö", "&ouml");
  text = replaceAll(text, "ü", "&uuml");
  return text;
}

export const makeReadableDate = ():string => {
  const date = new Date();
  return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getMilliseconds()}`;
};

export const fixLenNum = (num:number, precision = 2) : number => {
  const  prec = Math.pow(10, precision);
  return Math.round((num + Number.EPSILON) * prec) / prec;
};

export const prettyPrintNum = (num:number, precision = 2) : string => {
  let fNum:string = fixLenNum(num, precision).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  fNum = fNum.replace(/\./g,"P");
  fNum = fNum.replace(/,/g,".");
  fNum = fNum.replace(/P/g,",");

  if(roundPrecise(num, precision) % 1 == 0){
    fNum = padRight(fNum + ',', "0", fNum.length + 3);
  }

  const checkLen = fNum.split(",");
  if(checkLen[1].length != 2){
    fNum = padRight(fNum, "0", fNum.length + 2-checkLen[1].length);
  }

  return fNum;
};

export const roundPrecise = (v: number, precision = 2) : number => {
  const mult = Number("1" + padRight("", "0", precision));
  return Math.round(v * mult) / mult;
};

export const addReplaceDecimalSeparator = (num:number, replace: string, add: string, zeroPadAmount = 0): string => {
  let ret:string = fixLenNum(num).toString();
  if(ret.split("").find(_ => _ === replace) == undefined){
    ret += add;
    ret = padRight(ret, "0", ret.length+zeroPadAmount);
  }
  else {
    ret = ret.replace(replace, add);
  }
  return ret;
};

export const pad = (dir: 'r' | 'l', that:string, swith: string, untilLen: number) : string => {
  let res = that;
  while(res.length < untilLen){
    res = dir === 'l' ? swith + res : res + swith;
  }

  return res;
};

export const padLeft = (that:string, swith: string, untilLen: number) : string  => {
  return pad('l', that, swith, untilLen);
};

export const padRight = (that:string, swith: string, untilLen: number) : string  => {
  return pad('r', that, swith, untilLen);
};

export const copyToClipboard = (val:string) : void => {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
};

const signList = {
  "\n" : "<CRLF>", "<CRLF>" : "\n",
  "\t" : "<TAB>", "<TAB>" : "\t",
  "ä" : "&auml", "ö": "&ouml", "ü": "&uuml"
};
export const unreadableSigns = (sign: string) : string => {
  return signList[sign] == undefined ? sign : signList[sign];
};

export const addToList = <T>(list: T[], elem: T): T[] => {
  if(!list)
    list = [elem];
  else
    list.push(elem);

  return list;
};

export const addListToList = <T>(list: T[], elems: T[]): T[] => {
  if(!list)
    list = [...elems];
  else
    list.push(...elems);

  return list;
};

export const removeFromList = <T>(list:T[], pos:number) : T[] => {
  list.splice(pos, 1);
  return list;
};

export const indexedCollectionToArray = <T>(some: any) : T[] => {
  const arr = [];
  for(const f in some){
    arr.push(some[f]);
  }
  return arr;
};

export const removeLeading = (char: string, from: string) : string  => {
  let pos = 0;
  while(from.charAt(pos) === char)
    pos++;

  return from.substring(pos-1, from.length);
};

export const deepCopy = <T>(source: T): T => {
  return Array.isArray(source)
    ? source.map(item => deepCopy(item))
    : source instanceof Date
      ? new Date(source.getTime())
      : source && typeof source === 'object'
        ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
          Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
          o[prop] = deepCopy(source[prop]);
          return o;
        }, Object.create(Object.getPrototypeOf(source)))
        : source;
};

export const navigateTo = (router:Router)  => {
  return (urlFragments: any[], queryParams:any = undefined) : void => {router.navigate(urlFragments, { queryParams: queryParams });};
};

export const utf8ToISO88591 = (utf8Str: string) : string => {
  return "\ufeff"+utf8Str;
};

export const stringToBytes = (text) : Uint8Array => {
  const length = text.length;
  const result = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    const code = text.charCodeAt(i);
    const byte = code > 255 ? 32 : code;
    result[i] = byte;
  }
  return result;
};

export const cssVarValue = (variable:string) : string => {
  return window.getComputedStyle(document.documentElement).getPropertyValue(variable);
}

export const cssWidth = (elem:any, additionalOffset = 0) => {
  const rect = (elem as HTMLSpanElement).getClientRects().item(0);
  return (rect.right - rect.left)+additionalOffset+'px';;
}

export const cssHeight = (elem:any, additionalOffset = 0) : string => {
  window.getComputedStyle(elem);
  const rect = cssRect(elem);
  return (rect.bottom - rect.top)+additionalOffset+'px';
}

export const cssRect = (elem:any) => {
  window.getComputedStyle(elem);
  const rect = (elem as HTMLSpanElement).getClientRects().item(0);
  return rect;
}

export const generateGuid = (): string => {
  let result: string, i: string, j: number;
  result = '';
  for(j=0; j<32; j++) {
    if( j == 8 || j == 12|| j == 16|| j == 20)
      result = result + '-';

      i = Math.floor(Math.random()*16).toString(16).toUpperCase();
    result = result + i;
  }
  return result
};

export const jumpToAnchor = (name: string, offset: number = 250) => {
  const htmlEntry = document.querySelector('#\\3'+name); // as first char is a number it needs to be escaped as unicode
  let headerOffset = offset;
  let elementPosition = htmlEntry.getBoundingClientRect().top;
  let offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
       top: offsetPosition,
       behavior: 'smooth'
  });

  htmlEntry.children.item(0).classList.add("blink");
  setTimeout(() => { htmlEntry.children.item(0).classList.remove("blink") }, 2000)
}

export const copyArray = <T>(arr:T[]) : T[] => {
  return arr.map(el => el);
}

export const isArrayEmpty = <T>(arr: T[]) : boolean => {
  return arr == null || arr == undefined || arr.length  <= 0;
}

export const isEmptyString = (v: string) : boolean => {
  return v == undefined || v == null || v === '' || v === "";
}

export const isNullUndef = (v:any) : boolean => {
  return v == undefined || v == null;
}

export const isType = (v:any, typee:string) => typeof v === typee;
