import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { env } from "process";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import HistoryLogEntry from "../_core/HistoryLogEntry";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class HistoryLogService {
  static BASE_URL = `${environment.protocol}://${environment.host}:${environment.serverPort}`;

  public __log: HistoryLogEntry[] = [];
  public onLogChange: EventEmitter<HistoryLogEntry[]> = new EventEmitter<HistoryLogEntry[]>();

  constructor(private router: Router, private http: HttpClient, private userService : UserService) {}

  createAuthHeader() {
    return new HttpParams()
    .append("auth", "Bearer " + this.userService.token)
  }

  get log() : HistoryLogEntry[] {
    return this.__log;
  }

  set log(log: HistoryLogEntry[]) {
    this.onLogChange.emit(log);
    this.__log = log;
  }

  clear() {
    this.__log = [];
  }

  add(historyLogEntry: HistoryLogEntry) {
    this.__log.unshift(historyLogEntry);
  }

  addFromResponse({ info, data, __sender__ }) {
    this.add(
      new HistoryLogEntry(
        data.list_id,
        new Date().getTime(),
        data.indices,
        data.entryId,
        info.__type,
        info.__method,
        __sender__,
        data
      )
    );
  }

  addAll(log) {
    log.logEntries.forEach((le) => this.add(le));
  }

  jumpToAnchor(route: ActivatedRoute, name: string) {
    const htmlEntry = document.querySelector("#\\3" + name); // as first char is a number it needs to be escaped as unicode
    htmlEntry.children.item(0).classList.add("blink");
    setTimeout(() => {
      htmlEntry.children.item(0).classList.remove("blink");
    }, 3000);

    this.router.navigate(["./"], { fragment: name, relativeTo: route });
  }

  async getHeaderInfo(listId: string) : Promise<HistoryLogEntry[]> {
    let params = this.createAuthHeader();
    return new Promise((resolve, reject) =>
      this.http
        .get(HistoryLogService.BASE_URL + `/list/${listId}/log/headers`, {params: params})
        .subscribe(resolve, reject)
    );
  }

  async getLogEntryAt(listId: string, timestamp: number) : Promise<string> {
    let params = this.createAuthHeader();
    return new Promise((resolve, reject) =>
      this.http
        .get(HistoryLogService.BASE_URL + `/list/${listId}/log/entry/byTimestamp/${timestamp}`, {params: params})
        .subscribe(resolve, reject)
    );
  }

  async getListByLogFromTimestamp(listId: string, timestamp: number) : Promise<string> {
    let params = this.createAuthHeader();
    return new Promise((resolve, reject) =>
      this.http
        .get(HistoryLogService.BASE_URL + `/list/${listId}/updated/fromTimestamp/${timestamp}`, {params: params})
        .subscribe(resolve, reject)
    );
  }

  filterBy(type: string, method: string, entryId: string) : HistoryLogEntry[] {
    const logs = this.__log.filter(entries => entries.method === method && entries.type === type && entries.entryId === entryId).sort((a, b) => b.timestamp - a.timestamp);
    const logMap = new Map();

    logs.forEach(el => {
      logMap.set(el.entryId + el.listId + el.timestamp, el);
    });

    const newLogs = [];
    for(let el of logMap.values()) {
      newLogs.push(el);
    }

    return newLogs;
  }
}
