import { createReducer, on } from "@ngrx/store";
import { EntryTemplate } from "src/app/_core/EntryTemplate";
import { EntryTemplateActions } from "./entry-template.actions";

export interface EntryTemplateState {
  entryTemplates: EntryTemplate[];
  status: "init" | "loading" | "success" | "error";
}

const initialState: EntryTemplateState = {
  entryTemplates: [],
  status: "init",
};

export const entryTemplateReducer = createReducer(
  initialState,
  on(EntryTemplateActions.getAll, (state) => ({ ...state, status: "loading" })),
  on(EntryTemplateActions.getAllSuccess, (state, { entryTemplates }) => ({
    ...state,
    entryTemplates: entryTemplates,
    status: "success",
  }))
);
