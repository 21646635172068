import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { WSPBCListType } from "../_core/AuthObjects";
import UserData, { MailNotificationConfig } from "../_core/UserData";
import { PbcService } from "../_services/pbc.service";
import { UserService } from "../_services/user.service";
import { WebSocketService, WSMethods } from "../_services/web-socket.service";
import { UserConfigService } from "../_services/user-config.service";
import { User } from "../_core/User";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  clients: string[];
  userData: UserData;
  notificationState: { [index: string]: boolean } = {};

  clientFilter = "";

  constructor(
    private pbcService: PbcService,
    private wss: WebSocketService,
    private userService: UserService,
    private userConfService: UserConfigService,
    private location: Location
  ) {
    this.wss.message((e) => {
      let message = JSON.parse(e.data);
      const { data, info, __sender__ } = message;

      if (
        info.__method == WSMethods.GET &&
        info.__type == WSPBCListType.CLIENTS_ALL
      ) {
        this.clients = data;
        this.initData();
      }
    });

    this.userData = UserData.empty(this.userService.getUserName());
    this.pbcService.getAllClients();
  }
  ngOnDestroy(): void {
    //this.subscription.unsubscribe();
  }

  ngOnInit() {}

  initData() {
    this.userConfService
      .getUserData(this.userService.getUserName(), this.userService.token)
      .subscribe((d) => {
        this.userData = d;

        if (this.userData.data.mailNotifications === undefined)
          this.userData.data.mailNotifications = {};

        this.clients.forEach((client) => {
          this.userData.data.mailNotifications[client] =
            this.userData.data.mailNotifications[client] === undefined
              ? new MailNotificationConfig()
              : this.userData.data.mailNotifications[client];
          this.notificationState[client] =
            this.userData.data.mailNotifications[client].files.active ||
            this.userData.data.mailNotifications[client].status.active;
        });
      });
  }

  onNotificationChange(clientName: string, checked: boolean) {
    this.notificationState[clientName] = checked;
    if (checked) {
      this.setForOwn(clientName, true, "files");
      this.setForOwn(clientName, true, "status");
      this.setForOwn(clientName, true, "newEntry");
    } else {
      this.setForNone(clientName, "files");
      this.setForNone(clientName, "status");
      this.setForNone(clientName, "newEntry");
    }
  }

  configChanged(clientName: string, field: string, value: boolean) {
    const fields = field.split(".");
    if (this.userData.data.mailNotifications[clientName] !== undefined) {
      this.userData.data.mailNotifications[clientName][fields[0]][fields[1]] =
        value;
    } else if (this.userData.data.mailNotifications[clientName] === undefined) {
      this.userData.data.mailNotifications[clientName] =
        new MailNotificationConfig();
      this.userData.data.mailNotifications[clientName][fields[0]][fields[1]] =
        value;
    }
  }

  setForNone(clientName: string, field: string) {
    this.userData.data.mailNotifications[clientName][field].active = false;
    this.userData.data.mailNotifications[clientName][field].forAll = false;
  }

  setForOwn(clientName: string, state: boolean, field: string) {
    this.userData.data.mailNotifications[clientName][field].active = state;
    this.userData.data.mailNotifications[clientName][field].forAll = false;
  }

  setForAll(clientName: string, state: boolean, field: string) {
    this.userData.data.mailNotifications[clientName][field].active = state;
    this.userData.data.mailNotifications[clientName][field].forAll = state;
  }

  setNone(value: boolean) {
    for (const client in this.userData.data.mailNotifications)
      for (const field in this.userData.data.mailNotifications[client])
        this.userData.data.mailNotifications[client][field] = Object.assign(
          {},
          { active: false, forAll: false }
        );
  }

  setResponsibleAll(value: boolean) {
    for (const client in this.userData.data.mailNotifications)
      for (const field in this.userData.data.mailNotifications[client])
        this.userData.data.mailNotifications[client][field] = Object.assign(
          {},
          { active: true, forAll: false }
        );
  }

  setAll(value: boolean) {
    for (const client in this.userData.data.mailNotifications)
      for (const field in this.userData.data.mailNotifications[client])
        this.userData.data.mailNotifications[client][field] = Object.assign(
          {},
          { active: true, forAll: true }
        );
  }

  back() {
    this.location.back();
  }

  saveAndBack() {
    this.userConfService
      .putUserData(
        this.userData,
        this.userService.getUserName(),
        this.userService.token
      )
      .subscribe((success) => {
        if (this.userData.name !== this.userData.data.mail) {
          this.userService.logout();
        }
        this.back();
      });
  }
}
