import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'boxed-text',
  templateUrl: './boxed-text.component.html',
  styleUrls: ['./boxed-text.component.scss']
})
export class BoxedTextComponent implements OnInit {
  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';
  @Input() size: 'sm' | 'lg' | 'base' = 'sm';

  constructor() { }

  ngOnInit() {
  }

}
