export class Item {
  constructor(
    public color:string,
    public textColor: string,
    public text:string | Promise<string>,
    public selected:boolean,
    public value: string,
    public clickable: (string) => boolean){

    }
}
