import { ReadKeyExpr } from "@angular/compiler";

export class LocalConfig {
  data: LocalConfigData;

  initData() {
    try {
      const initData = localStorage.getItem("localConfig");
      this.data = initData === undefined || initData === null ? this.empty() : JSON.parse(initData) as LocalConfigData;
    }
    catch(error) {
      console.error((error instanceof Error ? error.message : error), `localConfigData from LocalStorage could not be parsed to JSON`);
      this.data = undefined;
    }
    return this;
  }

  storeData() {
    localStorage.setItem("localConfig", JSON.stringify(this.data));
  }

  set(key: string, value: any) {
    this.data = LocalConfig.KeyExtractor.set(key, value, this.data);
  }

  static KeyExtractor = {
    set(key: string, value: any, target: any) {
      const splitKey = key.split('.');

      if(splitKey.length > 1){
        const start = splitKey.shift();
        const next = LocalConfig.KeyExtractor.getNexField(start);
        target[next] = this.set(splitKey.join('.'), value, target[next]);
        return target;
      }
      else return LocalConfig.KeyExtractor.setValue(key, value, target);
    },

    isIndex(key: string) : boolean {
      return /^\$\d\$$/i.test(key);
    },

    getIndex(key: string) : number {
      return Number(key.split("$")[1]);
    },

    getNexField(key: string) : string | number {
      if(LocalConfig.KeyExtractor.isIndex(key)) return LocalConfig.KeyExtractor.getIndex(key);
      else return key;
    },

    setValue(key: string, value: any, target: any) : typeof target {
      if(Array.isArray(target)) target = LocalConfig.KeyExtractor.setArray(key, value, target);
      else target[key] = value;

      return target;
    },

    setArray(key: string, value: any, target: any) {
      if(LocalConfig.KeyExtractor.isIndex(key)) target[LocalConfig.KeyExtractor.getIndex(key)] = value;
      else target.push(value);
      return target;
    }
  }

  empty: () => LocalConfigData = () => {
    return {
      listsShow: {
        searchItem: ""
      }
    }
  };
}

export type LocalConfigData = {
  listsShow: {
    searchItem: string;
  };
};
