import { Injectable } from "@angular/core";
import { UserService } from "./user.service";
import UserData from "../_core/UserData";
import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { User } from "../_core/User";
import { generateAuthHeader } from "../_helper/HttpHelper";
import { userInfo } from "os";

@Injectable({
  providedIn: "root",
})
export class UserConfigService {
  constructor(private http: HttpClient) {}

  putUser(user: User, merge: boolean = false, token: string) {
    if (merge)
      return this.http.put<User>(
        UserService.BASE_AUTH_URL + "/user/merge/pbc",
        user,
        { headers: generateAuthHeader(token) }
      );
    else
      return this.http.put<User>(
        UserService.BASE_AUTH_URL + "/user/change/pbc",
        user,
        { headers: generateAuthHeader(token) }
      );
  }

  deleteUser(userId: string, token: string): Observable<{ userId: string }> {
    return this.http.delete<any>(
      UserService.BASE_AUTH_URL + "/user/pbc/" + userId,
      { headers: generateAuthHeader(token) }
    );
  }

  getUserData(userName: string, token: string): Observable<UserData> {
    return this.http.get<any>(
      UserService.BASE_AUTH_URL + "/data/pbc/" + userName,
      { headers: generateAuthHeader(token) }
    );
  }

  putUserData(
    userData: UserData,
    userName: string,
    token: string
  ): Observable<UserData> {
    return this.http.put<UserData>(
      UserService.BASE_AUTH_URL + "/data/pbc/" + userName,
      userData,
      { headers: generateAuthHeader(token) }
    );
  }

  patchUserName(newUser: User, oldUserName: string, token: string) {
    return this.http.patch<User>(
      UserService.BASE_AUTH_URL + "/user/change/name/"+oldUserName+"/pbc",
      newUser,
      { headers: generateAuthHeader(token) }
    );
  }

  postUserData(
    userData: UserData,
    token: string
  ): Observable<HttpResponse<UserData>> {
    return this.http.post<UserData>(
      UserService.BASE_AUTH_URL + "/data/pbc/",
      userData,
      { headers: generateAuthHeader(token), observe: "response" }
    );
  }

  getTenantList(token: string): Observable<HttpResponse<any>> {
    return this.http.get(UserService.BASE_AUTH_URL + "/tenant/list/pbc", {
      headers: generateAuthHeader(token),
      observe: "response",
    });
  }

  generateInitPasswordLink(token: string, mailAddress: string) {
    return this.http.post(
      UserService.BASE_AUTH_URL + "/generate/new-pw-link/pbc",
      mailAddress,
      {
        headers: generateAuthHeader(token),
        observe: "response",
      }
    );
  }
}
