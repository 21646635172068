import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss']
})
export class IconInputComponent implements OnInit {
  @Input() type: string;
  @Input() fieldValue: string = "";
  @Input() placeholder: string = "";
  @Input() firstIcon:string;
  @Input() lastIcon: string;
  @Input() disabled: boolean = false;

  @Output() fieldValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() lastIconClicked:EventEmitter<string> = new EventEmitter<string>();
  @Output() firstIconClicked:EventEmitter<string> = new EventEmitter<string>();
  @Output() keyEnter: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
