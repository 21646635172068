import { createSelector } from "@ngrx/store";
import { create } from "domain";
import { ListTemplate } from "src/app/_core/ListTemplate";
import { AppState } from "../app.state";
import { ListTemplateState } from "./list-template.reducers";

const select = (state: AppState) => state.listTemplate;

const all = createSelector(
  select,
  (state: ListTemplateState) => state.listTemplates
);

const getById = createSelector(
  all,
  (listTemplates: ListTemplate[], props: { id: string }) =>
    listTemplates.filter((el) => el.id === props.id)[0]
);

export const ListTemplateSelector = {
  all: all,
  getById: getById,
};
