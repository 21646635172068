import { Component, OnInit } from "@angular/core";
import { Group } from "src/app/_core/Group";
import { GroupService } from "src/app/_services/group.service";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.scss"],
})
export class GroupsComponent implements OnInit {
  groups: Group[];
  newGroup: string;

  constructor(private groupService: GroupService) {
    groupService.getAll().subscribe((el) => {
      this.groups = el.filter(
        (el2) =>
          el2.parentName === undefined ||
          el2.parentName === "" ||
          el2.parentName === null
      );
      this.groups.sort((a, b) => ("" + a.name).localeCompare(b.name));
    });
  }

  ngOnInit() {}

  addNewGroup() {
    if (this.newGroup !== "") {
      this.groupService
        .addNewGroup(new Group(this.newGroup))
        .subscribe((res: Group) => {
          this.groups.push(res);
          this.newGroup = "";
        });
    }
  }

  deleteCompleteGroup(groupName: string) {
    this.groupService.deleteWithHierarchy(groupName).subscribe((success) => {
      this.groups = this.groups.filter((group) => group.name !== groupName);
    });
  }
}
