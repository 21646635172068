import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { EntryTemplate } from "src/app/_core/EntryTemplate";
import { EntryTemplateService } from "src/app/_services/entry-template.service";
import { EntryTemplateActions } from "./entry-template.actions";

@Injectable({
  providedIn: "root",
})
export class EntryTemplateEffects {
  constructor(
    private actions$: Actions,
    private entryTemplateService: EntryTemplateService
  ) {}

  getAllListTemplates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EntryTemplateActions.getAll),
        mergeMap(() =>
          this.entryTemplateService
            .getAll()
            .pipe(
              map((templates: EntryTemplate[]) =>
                EntryTemplateActions.getAllSuccess({ entryTemplates: templates })
              )
            )
        )
      ),
    { dispatch: true }
  );
}
