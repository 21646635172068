import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { LocalConfig } from "src/app/_core/LocalConfig";
import { LocalConfigActions } from "./local-config.actions";

@Injectable({
  providedIn: "root",
})
export class LocalConfigEffects {
  constructor(private actions$: Actions) {}

  getAllListTemplates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocalConfigActions.getAll),
        switchMap(async () => {
          const localConfig = new LocalConfig().initData();

          return LocalConfigActions.getAllSuccess({
            localConfig: localConfig,
          });
        })
      ),
    { dispatch: true }
  );

  setLocalConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocalConfigActions.setField),
        switchMap(async (data) => {
          const localConfig = new LocalConfig().initData();
          localConfig.set(data.key, data.value);
          localConfig.storeData();

          return LocalConfigActions.getAllSuccess({
            localConfig: localConfig,
          });
        })
      ),
    { dispatch: true }
  );
}
