import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customizations',
  templateUrl: './customizations.component.html',
  styleUrls: ['./customizations.component.scss']
})
export class CustomizationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
