import { Component, NgModule, OnInit } from '@angular/core';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@NgModule({

})
export class EntryCustomizationModule {

}
