import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  EntryComponent,
  EntryCustomizationModule,
} from "./entry/entry.component";
import { CustomizationsComponent } from './customizations.component';
import { SharedModule } from "../shared/Shared.module";

const routes: Routes = [{
  path: 'customizations',
  component: CustomizationsComponent
}];

@NgModule({
  declarations: [EntryComponent, CustomizationsComponent],
  imports: [CommonModule, RouterModule.forChild(routes), SharedModule ,EntryCustomizationModule],
  providers: [],
  bootstrap: [CustomizationsComponent],
  schemas: [],
  exports: [RouterModule]
})
export class CustomizationModule {}
