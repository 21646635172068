import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthorizationService } from "./authorization.service";
import { UserService } from "./user.service";
import { WebSocketService, WSMethods } from "./web-socket.service";

@Injectable({
  providedIn: "root",
})
export class ListLockService {
  isBeating: boolean = false;
  activeListId: string = "";
  event: Subject<TListLockEvent> = new Subject<TListLockEvent>();

  constructor(private socket: WebSocketService, private userService: UserService, private authService: AuthorizationService) {
    socket.message((event: MessageEvent) => {
      const eventData = JSON.parse(event.data);
      const { __method, __type, __status } = eventData.info;
      const data = eventData.data;

      if (
        __method === WSMethods.POST &&
        __type === WSListLockType.LOCK_EDIT_LIST
      ) {
        if (__status == 200) {
          this.event.next({ type: __type, status: 'OK', value: {} });
          setTimeout(() => this.sendHeartbeat(), 1500);
        } else if (__status === 423)
          this.event.next({
            type: __type,
            status: 'LOCKED',
            value: { ...data },
          });
      } else if (
        __method === WSMethods.DELETE &&
        __type === WSListLockType.UNLOCK_EDIT_LIST
      ) {
        if (__status == 200) {
          this.event.next({ type: __type, status: 'OK', value: {} });
          this.stopHeartbeat();
        } else if (__status === 423)
          this.event.next({
            type: __type,
            status: 'LOCKED',
            value: { ...data },
          });
      } else if (
        __method === WSMethods.PUT &&
        __type === WSListLockType.SIGNAL_EDITING_LIST
      ) {
        if (__status == 200)
          this.event.next({ type: __type, status: 'OK', value: {} });
        else if (__status === 423)
          this.event.next({
            type: __type,
            status: 'LOCKED',
            value: { ...data },
          });
      }
    });
  }

  startHeartbeat(listId: string) {
    this.activeListId = listId;
    this.isBeating = true;
    this.socket.send(WebSocketService.setup(this.data(), WSMethods.POST, WSListLockType.LOCK_EDIT_LIST, this.userService.token));
  }

  stopHeartbeat() {
    this.isBeating = false;
    this.socket.send(WebSocketService.setup(this.data(), WSMethods.DELETE, WSListLockType.UNLOCK_EDIT_LIST, this.userService.token));
  }

  private sendHeartbeat() {
    if(this.isBeating) {
      this.socket.send(WebSocketService.setup(this.data(), WSMethods.PUT, WSListLockType.SIGNAL_EDITING_LIST, this.userService.token));

      setTimeout(() => this.sendHeartbeat(), 15000);
    }
  }

  private data() {
    return { list_id: this.activeListId, user_name: this.userService.getUserName() };
  }
}

export enum WSListLockType {
  LOCK_EDIT_LIST = "post-edit-list", // start to edit a list (ask if not locked)
  SIGNAL_EDITING_LIST = "put-edit-list", // heartbeat
  UNLOCK_EDIT_LIST = "delete-edit-list", // stop to edit a list
}

export type TListLockEvent = {
  type: WSListLockType;
  status: "LOCKED" | "OK";
  value: any;
};
