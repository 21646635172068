export default class HistoryLogEntry {
  constructor(
    public listId: string,
    public timestamp: number,
    public entryIndices: number[],
    public entryId: string,
    public type: string,
    public method: string,
    public username: string,
    public data: any
    ){

    }
}
