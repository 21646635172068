import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TEntryStatus } from "../_core/Entry";
import { formData2HttpParams } from "../_helper/HttpHelper";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http:HttpClient, private userService:UserService){

  }

  downloadFile(formData:FormData){
    let params = new HttpParams()
    .append("file", formData.get("file").toString())
    .append("auth", "Bearer " + this.userService.token)
    .append("list_id", formData.get("list_id").toString());

    return this.http.get(environment.protocol + "://" + environment.host + ":" + environment.serverPort + "/file", {params: params, responseType: 'blob'});
  }

  downloadFilesAsZip(formData:FormData) : Observable<any> {
    let params = new HttpParams()
    .append("auth", "Bearer " + this.userService.token)
    .append("list_id", formData.get("list_id").toString());

    let path = "/files/zip";

    if(formData.has("entry_indices")) {
      path = `/entry/${formData.get("list_id").toString()}/files/zip`;
      params = params.append("indices", formData.getAll("entry_indices").toString());
    }
    else if(formData.has("category_indices")) {
      path = `/category/${formData.get("list_id").toString()}/files/zip`;
      params = params.append("indices", formData.getAll("category_indices").toString());
    }
    else if(formData.has("entry_id_list")) {
      path = `/entries/${formData.get("list_id").toString()}/files/zip`;
      params = params.append("entry_id_list", formData.getAll("entry_id_list").toString());
    }

    return this.http.get(environment.protocol+"://" + environment.host + ":" + environment.serverPort + path, {params: params, responseType: 'blob'});
  }

  downloadFilesAsZipByState(formData: FormData, filterState:TEntryStatus, targetState: TEntryStatus){
    let params = new HttpParams()
    .append("auth", "Bearer " + this.userService.token)
    .append("filterState", filterState)
    .append("targetState", targetState);
    formData.forEach((v, k) => params = params.append(k, v.toString()));

    let path = "/files/zip/by/state";
    return this.http.get(environment.protocol+"://" + environment.host + ":" + environment.serverPort + path, {params: params, responseType: 'blob'});
  }

  uploadWithProgress(formData: FormData): Observable<any> {
    formData.append("auth", "Bearer " + this.userService.token);
    return this.http.post(environment.protocol+"://" + environment.host + ":" + environment.serverPort + "/file", formData, { observe: 'response',  reportProgress: true, responseType: 'json' });
  }

  deleteFile(formData: FormData) {
    let params = formData2HttpParams(formData)
      .append("auth", "Bearer " + this.userService.token);

    return this.http.delete(environment.protocol+"://" + environment.host + ":" + environment.serverPort + "/file", {params: params});
  }
}
