import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WSEntryType } from "../../_core/Entry";
import HistoryLogEntry from "../../_core/HistoryLogEntry";
import { HistoryLogService } from "../../_services/history-log.service";
import { WSMethods } from "../../_services/web-socket.service";
import { PbcService } from "src/app/_services/pbc.service";
import { PBCList } from "src/app/_core/PBCList";
import { log } from "console";
import { cssRect } from "src/app/_helper/Helpers";

@Component({
  selector: "history-log",
  templateUrl: "./history-log.component.html",
  styleUrls: ["./history-log.component.scss"],
})
export class HistoryLogComponent {
  LOG_TYPES = WSEntryType;
  LOG_METHODS = WSMethods;

  @Input() list: PBCList;
  isOpen: boolean = true;

  @Input() logHeight: number;

  screenHeight: number = 0;
  log: HistoryLogEntry[] = [];

  constructor(
    public hsls: HistoryLogService,
    private route: ActivatedRoute,
    private pbcService: PbcService
  ) {
    this.log = this.hsls.log;
    this.hsls.onLogChange.subscribe((log: HistoryLogEntry[]) => {
      this.log = log;
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenHeight = window.innerHeight;
    this.setHeight();
  }

  setHeight() {
    const elem = document.getElementById("logHeight");
    if (elem) {
      const rect = cssRect(elem);

      if (rect != undefined || rect != null)
        elem.style.height = this.screenHeight - 70 - rect.top + "px";
    }
  }

  ngAfterViewInit() {
    this.screenHeight = window.innerHeight;
  }

  scrollToEntry(logEntry: HistoryLogEntry) {
    const entryIndices = this.list.findEntryPosition(logEntry.entryId);

    this.hsls.jumpToAnchor(this.route, entryIndices.join("-") + "e");
  }

  convertInternalFilename(fn: string) : string {
    return fn.split("__").slice(1).join('__');
  }
}
