import { Component, Input, OnInit } from '@angular/core';
import { NotifierIcons, NotifierService, NotifierType } from './notifier-service';

@Component({
  selector: 'notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {
  TYPES = NotifierType;
  tick = true;
  @Input() socketMessage: boolean = true;

  constructor(public notifService:NotifierService) {
  }

  ngOnInit() {
    this.notifService.socketMessage = this.socketMessage;
    setTimeout(() => {
      this.notifService.removeAll()
    }, 5000);
  }

  returnClassByMsgType(type: NotifierType): string {
    switch(type) {
      case NotifierType.INFO:
        return "message-info";
      case NotifierType.ERROR:
        return "message-error";
      case NotifierType.SUCCESS:
        return "message-success";
      case NotifierType.WARN:
        return "message-warn";
      default:
        return "";
    }
  }

}
