import { createAttribute } from "@angular/compiler/src/core";
import { createAction, props } from "@ngrx/store";
import { create } from "domain";
import { LocalConfig } from "src/app/_core/LocalConfig";

export const LocalConfigActions = {
  getAll: createAction(
    "[Load LocalStorage Config] Load Config from LocalStorage"
  ),

  getAllSuccess: createAction(
    "[LocalStorage Config loaded] Load Config from LocalStorage (success)",
    props<{ localConfig: LocalConfig }>()
  ),
  setField: createAction(
    "[LocalStorage Config addField] Add field to LocalStorage",
    props<{ key: string, value: any }>()
  )
};
