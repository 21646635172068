import { Input, Output, QueryList, ViewChildren } from "@angular/core";
import { Component, EventEmitter, OnInit } from "@angular/core";
import { Category } from "../../_core/Category";
import { cssVarValue } from "../../_helper/Helpers";

@Component({
  selector: 'toc-entry',
  templateUrl: './toc-entry.component.html'
})
export class TocEntryComponent implements OnInit {
  @Input() category:Category;
  @Input() showListNumbers: boolean;
  @Output() onCategoryClicked:EventEmitter<number[]> = new EventEmitter();
  @ViewChildren(TocEntryComponent) children: QueryList<TocEntryComponent>;

  isSelected:boolean = false;
  isCollapsed: boolean = true;

  ngOnInit(): void {
  }

  categoryClicked(){
    this.isSelected = true;
    this.children.forEach(sub => sub.deselect());

    this.onCategoryClicked.emit([]);
  }

  onChildCategoryClicked(clickedCategoryIndices:number[] = [], clickedChild:number){
    this.isSelected = true;
    this.children.forEach((sub, i) => {
      if(i !== clickedChild) sub.deselect();
    });

    clickedCategoryIndices.push(clickedChild);
    this.onCategoryClicked.emit(clickedCategoryIndices);
  }

  deselect(){
    this.isSelected = false;
    this.children.forEach(sub => sub.deselect());
  }

  statusColor() {
    return cssVarValue('--color-' + this.category.childEntriesStatus());
  }

}
