import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupsComponent } from './administration/group/groups/groups.component';
import { RightsOverviewComponent } from './administration/rights-overview/rights-overview.component';
import { UserEditComponent } from './administration/user-edit/user-edit.component';
import { ListShowComponent } from './list-show/list-show.component';
import { ChangePasswordComponent } from './login-screen/change-password/change-password.component';
import { ForgotPasswordComponent } from './login-screen/forgot-password/forgot-password.component';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { OverviewComponent } from './overview/overview.component';
import { SharedModule } from './shared/Shared.module';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserService } from './_services/user.service';

export const ROUTE_CONFIG: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: OverviewComponent,
    canActivate: [UserService]
  },
  {
    path: 'login',
    component: LoginScreenComponent
  },
  {
    path: 'login/forgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'login/changePassword/:token',
    component: ChangePasswordComponent
  },
  {
    path: 'list/show/:id',
    component: ListShowComponent,
    canActivate: [UserService]
  },
  {
    path: 'list/show/:id/:categoryIndex',
    component: ListShowComponent,
    canActivate: [UserService]
  },
  {
    path: 'user/settings',
    component: UserSettingsComponent,
    canActivate: [UserService]
  },
  {
    path: 'user/rights',
    component: UserEditComponent,
    canActivate: [UserService]
  },
  {
    path: 'user/rights/overview',
    component: RightsOverviewComponent,
    canActivate: [UserService]
  }
];

@NgModule({
    declarations: [],
    imports: [SharedModule, RouterModule.forRoot(ROUTE_CONFIG)],
    exports: [RouterModule],
  })
  export class AppRoutingModule { }
