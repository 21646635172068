import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "toggle-button",
  templateUrl: "./toggle-button.component.html",
  styleUrls: ["./toggle-button.component.scss"],
})
export class ToggleButtonComponent implements OnInit {
  @Input() text: string;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();
  @Input() toggle = false;
  @Input() isPermOn = false;
  constructor() {}

  ngOnInit() {}

  onToggle() {
    if (!this.isPermOn) {
      this.toggle = !this.toggle;
      this.toggleChange.emit(this.toggle);
    }
  }
}
