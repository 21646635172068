import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ListTemplate } from "../_core/ListTemplate";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class ListTemplateService {
  constructor(private userService: UserService, private http: HttpClient) {}

  public getAll(): Observable<ListTemplate[]> {
    const params = new HttpParams().append(
      "auth",
      "Bearer " + this.userService.token
    );
    return this.http.get<ListTemplate[]>(
      environment.protocol +
        "://" +
        environment.host +
        ":" +
        environment.serverPort +
        "/listTemplates",
      { params: params }
    );
  }
}
