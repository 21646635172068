import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import "leader-line";
import { Group, TRootedGroup } from "src/app/_core/Group";
import { DefaultingMap } from "src/app/_helper/DefaultingMap";
import { GroupService } from "src/app/_services/group.service";
import { ActionDialogComponent } from "src/app/_uicomponents/action-dialog/action-dialog.component";
declare let LeaderLine: any;

@Component({
  selector: "group-node",
  template: `
    <div class="flex flex-col w-full">
    	<div class="flex  w-full">
    		<div *ngFor="let item of nodes; let i = index" class="flex flex-row w-full justify-center" [ngClass]="{            'mb-10': item.children.length < 5,            'mb-20': item.children.length >= 5          }"          #vnodes        >
    			<span [id]="item.group.name" class="border-2 border-gray-500 p-3 justify-center">
    				<span *ngIf="!isEditMode.getOrElse(item.group.name, false)">{{              item.group.name            }}</span>
    				<input type="text" class="border border-gray-300 outline-none rounded-lg p-2" [ngModel]="item.group.name" (ngModelChange)="onChangeGroupName($event, i)" *ngIf="isEditMode.getOrElse(item.group.name, false)" (keydown.enter)="                isEditMode.set(item.group.name, false); save(item, i)              " />
    				<span class="flex flex-row justify-center">
    					<mat-icon class="flex w-4/12 cursor-pointer" *ngIf="!isEditMode.getOrElse(item.group.name, false)" (click)="isEditMode.set(item.group.name, true)">edit</mat-icon              >
    					<mat-icon class="flex w-4/12 cursor-pointer" *ngIf="isEditMode.getOrElse(item.group.name, false)" (click)="isEditMode.set(item.group.name, false); save(item, i)">save</mat-icon              >
    					<mat-icon class="flex cursor-pointer w-4/12" (click)="openAddDialog(item)">add</mat-icon              >
    					<mat-icon class="flex w-4/12 cursor-pointer" (click)="deleteDialog(item, i)">delete</mat-icon              >
    				</span>
    			</span>
    		</div>
    	</div>
    	<div class="flex flex-row">
    		<div *ngFor="let item of nodes; let i = index" class="flex w-full">
    			<div *ngIf="item.children.length === 0" #vplaceholder>&nbsp;</div>
    			<group-node class="w-full" [clients]="clients" (onDelete)="onDelete.emit()" (onStartRedraw)="onStartRedraw.emit()" [redraw]="redraw" [parentName]="item.group.name" [nodes]="item.children"            #vgroups          ></group-node>
    		</div>
    	</div>
    </div>
    <div      #addNewGroupDialog class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-50 z-20" [ngClass]="{ hidden: isDialogAddHidden }" (click)="closeAddDialog()">
    	<div class="menu-offset"></div>
    	<div class="menu-offset"></div>
    	<div class="menu-offset"></div>
    	<div class="flex flex-row">
    		<div class="w-4/12">&nbsp;</div>
    		<div class="flex justify-center align-middle flex-col w-4/12" (click)="$event.stopPropagation()">
    			<div class="flex flex-row  bg-gray-100 p-5 rounded-sm" [ngClass]="{ hidden: isDialogInputHidden }">
    				<input type="text" class="border border-r-0 border-gray-500 w-8/12 outline-none p-3 rounded-sm rounded-r-none" [(ngModel)]="newGroupName" />
    				<button class="w-4/12 border border-gray-500 border-l-0 bg-white" (click)="addNewGroup()" [disabled]="newGroupName == undefined || newGroupName.lenght == 0">Hinzufügen</button>
    			</div>
    			<div class="flex flex-col bg-white  h-64 overflow-auto" *ngIf="newGroupName != undefined && newGroupName.length != 0">
    				<span *ngFor="let item of clients | searchInArray: {attribute:'', value: newGroupName};" class="w-full p-2 bg-white cursor-pointer hover:bg-gray-400" (click)="newGroupName = item;">{{ item }}</span>
    			</div>
    		</div>
    	</div>
    </div>`,
})
export class GroupNodeComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() parentName: string;
  @Input() nodes: TRootedGroup[];
  @Input() redraw: EventEmitter<void>;
  @Input() clients: any[];
  @Output() onStartRedraw: EventEmitter<void> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren("vnodes") viewNodes: QueryList<ElementRef>;
  @ViewChildren("vgroups") viewGroups: QueryList<ElementRef>;
  @ViewChildren("vplaceholder") viewPlaceholder: QueryList<ElementRef>;
  @ViewChild("addNewGroupDialog", { static: true }) addNewGroupDialog;

  widthPerNode: string = "";
  leaderLines: any[] = [];
  isDialogAddHidden = true;
  isDialogInputHidden = true;
  parentForNewGroup: TRootedGroup = undefined;
  newGroupName: string = undefined;
  isEditMode: DefaultingMap<string, boolean> = new DefaultingMap();
  window: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private groupService: GroupService,
    private dialog: MatDialog
  ) {
    this.window = document.defaultView;
  }

  ngOnInit(): void {
    this.redraw.subscribe((_) =>
      setTimeout(() => {
        this.connectNodes(this.parentName);
      }, 1000)
    );
  }

  ngAfterViewInit(): void {
    const width = `${100 / this.nodes.length}%`;
    this.viewNodes.forEach(
      (nodes) => (nodes.nativeElement.style.width = width)
    );
    this.viewGroups.forEach((groups) => {
      if (groups.nativeElement !== null && groups.nativeElement !== undefined)
        groups.nativeElement.style.width = width;
    });
    this.viewPlaceholder.forEach(
      (placeholder) => (placeholder.nativeElement.style.width = width)
    );

    this.connectNodes(this.parentName);
  }

  connectNodes(parent?: string) {
    this.clearLines();

    if (parent != undefined) {
      const parentElement = this.document.getElementById(parent);
      this.nodes.forEach((child) => {
        console.log(`Called here ${child.group.name}`);
        const childElement = this.document.getElementById(child.group.name);
        if (parentElement != undefined && childElement != undefined)
          this.leaderLines.push(
            new LeaderLine(parentElement, childElement, {
              color: "#9e0c1655",
              size: 2,
              startPlug: "square",
              endPlug: "square",
              path: "straight",
              showEffect: { animOptions: { duration: 300, timing: "linear" } },
            })
          );
      });
    }
  }

  openAddDialog(clickedGroup: TRootedGroup) {
    this.parentForNewGroup = clickedGroup;
    this.isDialogAddHidden = false;
    this.isDialogInputHidden = false;
    setTimeout(() => {});
  }

  addNewGroup() {
    this.isDialogInputHidden = true;
    const newGroup = new Group(
      this.newGroupName,
      this.parentForNewGroup.group.name
    );

    this.groupService.addNewGroup(newGroup).subscribe(
      (success) => {
        this.closeAddDialog();
        this.parentForNewGroup.children.push({ group: newGroup, children: [] });
        this.onStartRedraw.emit();
      },
      (error) => {
        if (error.status === 409) {
          this.closeAddDialog();
          alert(
            "Eine Gruppe darf nicht sich selbst zugeordnet werden. Gruppenbezeichner dürfen nur einmal vergeben werden."
          );
          this.onStartRedraw.emit();
        }
      }
    );
  }

  closeAddDialog() {
    this.parentForNewGroup = undefined;
    this.newGroupName = undefined;
    this.isDialogAddHidden = true;
    this.isDialogInputHidden = true;
  }

  onChangeGroupName(newVal: string, pos: number) {
    this.isEditMode.set(newVal, true);
    this.nodes[pos].group.name = newVal;
  }

  save(item: TRootedGroup, pos: number) {
    return this.groupService.update(item.group).subscribe((success) => {
      this.nodes[pos].group.name = success.name;
      this.onStartRedraw.emit();
    });
  }

  deleteDialog(group: TRootedGroup, pos: number) {
    this.dialog.open(ActionDialogComponent, {
      data: {
        actions: [
          {
            id: "btn2",
            text: "Aus hierarchie entfernen (Gruppe und Untergruppen werden lediglich aus der Gesamthierarchie entfert)",
            callback: () => this.deleteFromHierarchy(group),
          },
          {
            id: "btn1",
            text: "Vollständig entfernen (Untergruppen bleiben erhalten)",
            callback: () => this.delete(group),
          },
          {
            id: "btn3",
            text: "Vollständig entfernen (Untergruppen werden auch entfernt)",
            callback: () => this.deleteWithHierachy(group),
          },
          {
            id: "btn4",
            text: "Abbrechen",
            callback: () => {},
          },
        ],
        title: "Gruppe entfernen",
        text: `Wie wollen Sie die Gruppe ${group.group.name} entfernen?\nBitte wählen Sie eine Option aus.`,
        buttonDirection: "col",
      },
    });
  }

  delete(group: TRootedGroup) {
    this.groupService.delete(group.group.name).subscribe((success) => {
      this.nodes = this.nodes.filter(
        (node) => group.group.name !== node.group.name
      );
      this.onStartRedraw.emit();
      this.onDelete.emit();
    });
  }

  deleteFromHierarchy(group: TRootedGroup) {
    this.groupService.deleteFromHierarchy(group.group).subscribe((success) => {
      this.onDelete.emit();
    });
  }

  deleteWithHierachy(group: TRootedGroup) {
    this.groupService
      .deleteWithHierarchy(group.group.name)
      .subscribe((_) => this.onDelete.emit());
  }

  ngOnDestroy(): void {
    this.clearLines();
  }

  clearLines() {
    this.leaderLines.forEach((line) => {
      try {
        if (line != undefined && line.remove != undefined) line.remove();
      } catch (e) {}
    });
  }
}
