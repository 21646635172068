import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'save-loading-animation',
  templateUrl: './save-loading-animation.component.html',
  styleUrls: ['./save-loading-animation.component.scss']
})
export class SaveLoadingAnimationComponent implements OnInit {
  @Input() show: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
