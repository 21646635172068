import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {
  title: string;
  text: string;
  buttons: TDialogActions[];
  buttonDirection: 'row' | 'col';

  constructor(public dialogRef: MatDialogRef<ActionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any & { buttonDirection: 'row' | 'dir', text: string, title: string, actions: TDialogActions[] }) { }

  ngOnInit() {
    this.buttons = this.data.actions;
    this.title = this.data.title;
    this.text = this.data.text;
    this.buttonDirection = this.data.buttonDirection;

    this.buttons = this.buttons.map(button => {
      button.style = button.style === undefined ? "" : button.style;
      return button;
    })
  }

}

type TDialogActions = {id: string, text: string, callback: (id: string) => void, style?: string};
