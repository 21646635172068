import { registerLocaleData } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from './_services/authorization.service';
import { DesktopNotificationService } from './_services/desktop-notification.service';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { LanguageSwitcherService } from './_uicomponents/language-switcher/language-switcher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export default class AppComponent {
  constructor(private authService:AuthorizationService, private desktopNotifService:DesktopNotificationService, private translate: TranslateService){
    //desktopNotifService.ask();

    registerLocaleData(localeDe, 'de', localeDeExtra);
    registerLocaleData(localeEn, 'en', localeEnExtra);
    registerLocaleData(localeEs, 'es', localeEsExtra);

    translate.addLangs(['en', 'de', 'es']);
    translate.use(LanguageSwitcherService.get().lang);
  }
}
