import { createSelector } from "@ngrx/store";
import { EntryTemplate } from "src/app/_core/EntryTemplate";
import { AppState } from "../app.state";
import { EntryTemplateState } from "./entry-template.reducer";

const select = (state: AppState) => state.entryTemplate;

const all = createSelector(
  select,
  (state: EntryTemplateState) => state.entryTemplates
);

const getByType = createSelector(
  all,
  (entryTemplates: EntryTemplate[], props: { type: string }) : EntryTemplate =>
    entryTemplates.filter((el) => el.type === props.type)[0]
);

export const EntryTemplateSelector = {
  all: all,
  getByType: getByType,
};
