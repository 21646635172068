import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import HistoryLogEntry from '../_core/HistoryLogEntry';

@Component({
  selector: 'app-history-log-show',
  templateUrl: './history-log-show.component.html',
  styleUrls: ['./history-log-show.component.scss']
})
export class HistoryLogShowComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {revisions: HistoryLogEntry[], selected: number}, public dialogRef: MatDialogRef<HistoryLogShowComponent>) { }

  ngOnInit() {
  }

  formatDate(timestamp) {
    return new Intl.DateTimeFormat('de', {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit', second: '2-digit'}).format(new Date(timestamp));
  }

  close(revisionNumber: number, recoveryType: TRecoveryType) {
    const ret: THistoryLogDialogClose = { revisionNumber: revisionNumber, recoveryType: recoveryType }
    this.dialogRef.close(ret);
  }

  abort() {
    this.dialogRef.close();
  }
}

export type THistoryLogDialogClose = {revisionNumber: number, recoveryType: TRecoveryType};
export type TRecoveryType = 'normal' | 'complete';
