import { createAction, props } from "@ngrx/store";
import { EntryTemplate } from "src/app/_core/EntryTemplate";

export const EntryTemplateActions = {
  getAll: createAction(
    "[GetReq EntryTemplates] Get all entry templates (start)"
  ),

  getAllSuccess: createAction(
    "[GetRes EntryTemplates] Get all entry templates (success)",
    props<{ entryTemplates: EntryTemplate[] }>()
  ),
};
