import { Injectable } from '@angular/core';
import { WebSocketService, WSMethods } from './web-socket.service';
import { UserService } from './user.service';
import { PBCList, TListRight } from '../_core/PBCList';
import { WSPBCListType, WSUserType } from '../_core/AuthObjects';
import { TEntryRight } from '../_core/Entry';
import { HttpClient } from '@angular/common/http';
import { env, send } from 'process';
import { environment } from 'src/environments/environment';
import { generateAuthHeader } from '../_helper/HttpHelper';

@Injectable({
  providedIn: 'root'
})
export class PbcService {

  constructor(private socket: WebSocketService, private userService:UserService, private http: HttpClient) {
    //socket.activateLog();
  }

  static BASE_URL = `${environment.protocol}://${environment.host}:${environment.serverPort}`;

  LOG = true;
  log(str: string) {
    if(this.LOG) console.log(`%c${str}`, "background-color: orange;")
  }

  public get() {
    let object = WebSocketService.setup({}, WSMethods.GET, WSPBCListType.ALL, this.userService.token);
    this.socket.send(object);
  }

  public post(obj:PBCList) {
    let newObj: any = obj;
    newObj["list-id"] = obj.id;
    let object = WebSocketService.setup(newObj, WSMethods.POST,  WSPBCListType.ONE, this.userService.token);
    this.socket.send(object);
  }

  public put(obj: PBCList){
    let newObj: any = obj;
    newObj["list-id"] = obj.id;
    let object = WebSocketService.setup(newObj, WSMethods.PUT,  WSPBCListType.ONE, this.userService.token);
    this.socket.send(object);
  }

  public getById(obj:any){
    let object = WebSocketService.setup(obj, WSMethods.GET, WSPBCListType.ONE, this.userService.token);
    this.socket.send(object);
  }

  public deleteById(obj:any){
    let object = WebSocketService.setup(obj,WSMethods.DELETE, WSPBCListType.ONE, this.userService.token);
    this.socket.send(object);
  }

  public copyById(id: string) {
    let object = WebSocketService.setup(id,WSMethods.POST, WSPBCListType.COPY, this.userService.token);
    this.socket.send(object);
  }

  public getAllUsers(){
    let object = WebSocketService.setup({}, WSMethods.GET, WSUserType.USERS_ALL, this.userService.token);
    this.socket.send(object);
  }

  public getAllClients() {
    let object = WebSocketService.setup({}, WSMethods.GET, WSPBCListType.CLIENTS_ALL, this.userService.token);
    this.socket.send(object);
  }

  public getResponsiblesForClient(client: string) {
    let object = WebSocketService.setup(client, WSMethods.GET, WSPBCListType.RESPONSIBLES, this.userService.token);
    this.socket.send(object);
  }

  public getEntryRights(listId: string) {
    let object = WebSocketService.setup({"list_id": listId}, WSMethods.GET, WSPBCListType.ENTRY_RIGHTS, this.userService.token);
    this.socket.send(object);
  }

  /*
  public putEntryRights(listId: string, permissions: TEntryRight[]){
    let object = WebSocketService.setup({"list-id": listId, "entry-rights": permissions}, WSMethods.PUT, WSPBCListType.ENTRY_RIGHTS, this.userService.token);
    this.socket.send(object);
  }*/

  public getListRights(listId: string) {
    let object = WebSocketService.setup({"list_id": listId}, WSMethods.GET, WSPBCListType.LIST_RIGHTS, this.userService.token);
    this.socket.send(object);
  }

  public putListRights(listId: string, permissions: TListRight[]){
    let object = WebSocketService.setup({"list-id": listId, "list-rights": permissions}, WSMethods.PUT, WSPBCListType.LIST_RIGHTS, this.userService.token);
    this.socket.send(object);
  }

  public archive(listId:string) {
    let object = WebSocketService.setup(listId, WSMethods.PUT, WSPBCListType.ARCHIVE, this.userService.token);
    this.socket.send(object);
  }

  public unarchive(listId:string) {
    let object = WebSocketService.setup(listId, WSMethods.PUT, WSPBCListType.UNARCHIVE, this.userService.token);
    this.socket.send(object);
  }

  public getAmountClients() {
    return this.http.get<any>(
      PbcService.BASE_URL + "/list/clients/amount",
      { headers: generateAuthHeader(this.userService.token) }
    );
  }
}
