export const parseJWT = (jwt: string) : any => {
  const jwts = jwt.split(".");
  const header = jwts[0];
  const payload = jwts[1];
  return {
    header: JSON.parse(atob(header)),
    payload: JSON.parse(atob(payload))
  };
};

export const extractFieldFromJWT = <T>(jwt: string, field: string) : T => {
  return parseJWT(jwt).payload[field];
};
